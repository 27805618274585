import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_VOUCHER_PACKAGE_REQUEST,
	CREATE_VOUCHER_PACKAGE_SUCCESS,
	CREATE_VOUCHER_PACKAGE_FAILURE,
	UPDATE_VOUCHER_PACKAGE_SUCCESS,
	UPDATE_VOUCHER_PACKAGE_REQUEST,
	UPDATE_VOUCHER_PACKAGE_FAILURE,
	REMOVE_VOUCHER_PACKAGE_FAILURE,
	REMOVE_VOUCHER_PACKAGE_SUCCESS,
	REMOVE_VOUCHER_PACKAGE_REQUEST,
	TOGGLE_VOUCHER_PACKAGE_SUCCESS,
	TOGGLE_VOUCHER_PACKAGE_REQUEST,
	TOGGLE_VOUCHER_PACKAGE_FAILURE,
} from "./actionTypes";

import { create, update, remove, toggle } from "./services";

function* createVoucherCodePackage({ payload }) {
	try {
		const response = yield call(create, payload);
		if (response.status === 200) {
			yield put({
				type: CREATE_VOUCHER_PACKAGE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_VOUCHER_PACKAGE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_VOUCHER_PACKAGE_FAILURE,
			payload: response.data,
		});
	}
}

function* updateVoucherCodePackage({ payload }) {
	try {
		const response = yield call(update, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_VOUCHER_PACKAGE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_VOUCHER_PACKAGE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_VOUCHER_PACKAGE_FAILURE,
			payload: {},
		});
	}
}

function* toggleVoucherCodePackage({ payload }) {
	try {
		const response = yield call(toggle, payload);
		if (response.status === 200) {
			yield put({
				type: TOGGLE_VOUCHER_PACKAGE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: TOGGLE_VOUCHER_PACKAGE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: TOGGLE_VOUCHER_PACKAGE_FAILURE,
			payload: response.data,
		});
	}
}

function* removeVoucherCodePackage({ payload }) {
	try {
		const response = yield call(remove, payload);
		if (response.status === 200) {
			yield put({
				type: REMOVE_VOUCHER_PACKAGE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_VOUCHER_PACKAGE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_VOUCHER_PACKAGE_FAILURE,
			payload: response.data,
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_VOUCHER_PACKAGE_REQUEST, createVoucherCodePackage);
	yield takeEvery(UPDATE_VOUCHER_PACKAGE_REQUEST, updateVoucherCodePackage);
	yield takeEvery(REMOVE_VOUCHER_PACKAGE_REQUEST, removeVoucherCodePackage);
	yield takeEvery(TOGGLE_VOUCHER_PACKAGE_REQUEST, toggleVoucherCodePackage);
}

export default saga;
