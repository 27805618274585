import {
	ERRORS,
	CLEAR_RESPONSE,
	UPDATE_META_REQUEST,
	UPDATE_META_SUCCESS,
	UPDATE_META_FAILURE,
	GET_META_REQUEST,
	GET_META_SUCCESS,
	GET_META_FAILURE,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const getMeta = (data) => {
	return {
		type: GET_META_REQUEST,
		payload: data,
	};
};

export const update = (data) => {
	return {
		type: UPDATE_META_REQUEST,
		payload: data,
	};
};
