export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const GET_SHIPPING_SETTING_SUCCESS = "GET_SHIPPING_SETTING_SUCCESS";
export const GET_SHIPPING_SETTING_REQUEST = "GET_SHIPPING_SETTING_REQUEST";
export const GET_SHIPPING_SETTING_FAILURE = "GET_SHIPPING_SETTING_FAILURE";
export const UPDATE_SHIPPING_SETTING_SUCCESS =
	"UPDATE_SHIPPING_SETTING_SUCCESS";
export const UPDATE_SHIPPING_SETTING_REQUEST =
	"UPDATE_SHIPPING_SETTING_REQUEST";
export const UPDATE_SHIPPING_SETTING_FAILURE =
	"UPDATE_SHIPPING_SETTING_FAILURE";

export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_REQUEST = "GET_COUNTRY_REQUEST";
export const GET_COUNTRY_FAILURE = "GET_COUNTRY_FAILURE";

export const GET_SHIPPING_METHOD_SUCCESS = "GET_SHIPPING_METHOD_SUCCESS";
export const GET_SHIPPING_METHOD_REQUEST = "GET_SHIPPING_METHOD_REQUEST";
export const GET_SHIPPING_METHOD_FAILURE = "GET_SHIPPING_METHOD_FAILURE";

export const GET_SHIPPING_WEIGHT_SUCCESS = "GET_SHIPPING_WEIGHT_SUCCESS";
export const GET_SHIPPING_WEIGHT_REQUEST = "GET_SHIPPING_WEIGHT_REQUEST";
export const GET_SHIPPING_WEIGHT_FAILURE = "GET_SHIPPING_WEIGHT_FAILURE";

export const GET_COUNTRYWISE_SHIPPING_FEE_SUCCESS =
	"GET_COUNTRYWISE_SHIPPING_FEE_SUCCESS";
export const GET_COUNTRYWISE_SHIPPING_FEE_REQUEST =
	"GET_COUNTRYWISE_SHIPPING_FEE_REQUEST";
export const GET_COUNTRYWISE_SHIPPING_FEE_FAILURE =
	"GET_COUNTRYWISE_SHIPPING_FEE_FAILURE";

export const UPDATE_SHIPPING_FEE_SUCCESS = "UPDATE_SHIPPING_FEE_SUCCESS";
export const UPDATE_SHIPPING_FEE_REQUEST = "UPDATE_SHIPPING_FEE_REQUEST";
export const UPDATE_SHIPPING_FEE_FAILURE = "UPDATE_SHIPPING_FEE_FAILURE";
