import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_CUSTOMER_REQUEST,
	UPDATE_CUSTOMER_REQUEST,
	REMOVE_CUSTOMER_REQUEST,
	TOGGLE_CUSTOMER_REQUEST,
	GET_CUSTOMER_REQUEST,
	GET_CUSTOMER_SUCCESS,
	GET_CUSTOMER_FAILURE,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const getCustomers = (data) => {
	return {
		type: GET_CUSTOMER_REQUEST,
		payload: data,
	};
};

export const create = (data) => {
	return {
		type: CREATE_CUSTOMER_REQUEST,
		payload: data,
	};
};

export const update = (data) => {
	return {
		type: UPDATE_CUSTOMER_REQUEST,
		payload: data,
	};
};

export const toggle = (data) => {
	return {
		type: TOGGLE_CUSTOMER_REQUEST,
		payload: data,
	};
};

export const remove = (data) => {
	return {
		type: REMOVE_CUSTOMER_REQUEST,
		payload: data,
	};
};
