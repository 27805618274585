import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_CLOUD_USAGE_SUCCESS,
	CREATE_CLOUD_USAGE_FAILURE,
	UPDATE_CLOUD_USAGE_SUCCESS,
	UPDATE_CLOUD_USAGE_FAILURE,
	REMOVE_CLOUD_USAGE_FAILURE,
	REMOVE_CLOUD_USAGE_SUCCESS,
	TOGGLE_CLOUD_USAGE_SUCCESS,
	TOGGLE_CLOUD_USAGE_FAILURE,
	GET_CLOUD_USAGE_SUCCESS,
	GET_CLOUD_USAGE_REQUEST,
	GET_CLOUD_USAGE_FAILURE,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	cloudUsageDetails: {},
	isLoading: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				isLoading: false,
			};
		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};
		case CREATE_CLOUD_USAGE_SUCCESS:
		case CREATE_CLOUD_USAGE_FAILURE:
		case UPDATE_CLOUD_USAGE_SUCCESS:
		case UPDATE_CLOUD_USAGE_FAILURE:
		case REMOVE_CLOUD_USAGE_FAILURE:
		case REMOVE_CLOUD_USAGE_SUCCESS:
		case TOGGLE_CLOUD_USAGE_SUCCESS:
		case TOGGLE_CLOUD_USAGE_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: {},
			};
		case GET_CLOUD_USAGE_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case GET_CLOUD_USAGE_SUCCESS:
			return {
				...state,
				isLoading: false,
				cloudUsageDetails: action.payload?.data,
			};
		case GET_CLOUD_USAGE_FAILURE:
			return {
				...state,
				isLoading: false,
				response: action.payload,
				cloudUsageDetails: {},
				errors: {},
			};
		default:
			return state;
	}
};

export default reducer;
