export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_PARTNER_REQUEST = "CREATE_PARTNER_REQUEST";
export const CREATE_PARTNER_SUCCESS = "CREATE_PARTNER_SUCCESS";
export const CREATE_PARTNER_FAILURE = "CREATE_PARTNER_FAILURE";

export const UPDATE_PARTNER_REQUEST = "UPDATE_PARTNER_REQUEST";
export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS";
export const UPDATE_PARTNER_FAILURE = "UPDATE_PARTNER_FAILURE";

export const REMOVE_PARTNER_REQUEST = "REMOVE_PARTNER_REQUEST";
export const REMOVE_PARTNER_SUCCESS = "REMOVE_PARTNER_SUCCESS";
export const REMOVE_PARTNER_FAILURE = "REMOVE_PARTNER_FAILURE";

export const TOGGLE_PARTNER_SUCCESS = "TOGGLE_PARTNER_SUCCESS";
export const TOGGLE_PARTNER_REQUEST = "TOGGLE_PARTNER_REQUEST";
export const TOGGLE_PARTNER_FAILURE = "TOGGLE_PARTNER_FAILURE";
