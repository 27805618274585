export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_CHAIN_SETTING_REQUEST = "CREATE_CHAIN_SETTING_REQUEST";
export const CREATE_CHAIN_SETTING_SUCCESS = "CREATE_CHAIN_SETTING_SUCCESS";
export const CREATE_CHAIN_SETTING_FAILURE = "CREATE_CHAIN_SETTING_FAILURE";

export const UPDATE_CHAIN_SETTING_REQUEST = "UPDATE_CHAIN_SETTING_REQUEST";
export const UPDATE_CHAIN_SETTING_SUCCESS = "UPDATE_CHAIN_SETTING_SUCCESS";
export const UPDATE_CHAIN_SETTING_FAILURE = "UPDATE_CHAIN_SETTING_FAILURE";

export const REMOVE_CHAIN_SETTING_REQUEST = "REMOVE_CHAIN_SETTING_REQUEST";
export const REMOVE_CHAIN_SETTING_SUCCESS = "REMOVE_CHAIN_SETTING_SUCCESS";
export const REMOVE_CHAIN_SETTING_FAILURE = "REMOVE_CHAIN_SETTING_FAILURE";

export const TOGGLE_CHAIN_SETTING_SUCCESS = "TOGGLE_CHAIN_SETTING_SUCCESS";
export const TOGGLE_CHAIN_SETTING_REQUEST = "TOGGLE_CHAIN_SETTING_REQUEST";
export const TOGGLE_CHAIN_SETTING_FAILURE = "TOGGLE_CHAIN_SETTING_FAILURE";

export const GET_CHAIN_SETTING_SUCCESS = "GET_CHAIN_SETTING_SUCCESS";
export const GET_CHAIN_SETTING_REQUEST = "GET_CHAIN_SETTING_REQUEST";
export const GET_CHAIN_SETTING_FAILURE = "GET_CHAIN_SETTING_FAILURE";
