import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_LENDING_POOL_REQUEST,
	CREATE_LENDING_POOL_SUCCESS,
	CREATE_LENDING_POOL_FAILURE,
	UPDATE_LENDING_POOL_SUCCESS,
	UPDATE_LENDING_POOL_REQUEST,
	UPDATE_LENDING_POOL_FAILURE,
	REMOVE_LENDING_POOL_FAILURE,
	REMOVE_LENDING_POOL_SUCCESS,
	REMOVE_LENDING_POOL_REQUEST,
	APPROVE_LENDING_POOL_SUCCESS,
	APPROVE_LENDING_POOL_REQUEST,
	APPROVE_LENDING_POOL_FAILURE,
	GET_ALL_LENDING_POOL_SUCCESS,
	GET_ALL_LENDING_POOL_REQUEST,
	GET_ALL_LENDING_POOL_FAILURE,
	CANCEL_USER_LENDING_POOL_SUCCESS,
	CANCEL_USER_LENDING_POOL_REQUEST,
	CANCEL_USER_LENDING_POOL_FAILURE,
	GET_LENDING_SETTING_SUCCESS,
	GET_LENDING_SETTING_REQUEST,
	GET_LENDING_SETTING_FAILURE,
	UPDATE_LENDING_SETTING_SUCCESS,
	UPDATE_LENDING_SETTING_REQUEST,
	UPDATE_LENDING_SETTING_FAILURE,
} from "./actionTypes";

import {
	create,
	update,
	remove,
	approve,
	getAll,
	deleteUserLending,
	getSetting,
	updateSetting
} from "./services";

function* createUserLendingPool({ payload }) {
	try {
		const response = yield call(create, payload);
		if (response.status === 200) {
			yield put({
				type: CREATE_LENDING_POOL_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_LENDING_POOL_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_LENDING_POOL_FAILURE,
			payload: response.data,
		});
	}
}

function* updateUserLendingPool({ payload }) {
	try {
		const response = yield call(update, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_LENDING_POOL_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_LENDING_POOL_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_LENDING_POOL_FAILURE,
			payload: {},
		});
	}
}

function* approveUserLendingPool({ payload }) {
	try {
		const response = yield call(approve, payload);
		if (response.status === 200) {
			yield put({
				type: APPROVE_LENDING_POOL_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: APPROVE_LENDING_POOL_FAILURE,
				payload: response?.response?.data
					? response.response.data
					: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: APPROVE_LENDING_POOL_FAILURE,
			payload: {},
		});
	}
}

function* removeUserLendingPool({ payload }) {
	try {
		const response = yield call(remove, payload);
		if (response.status === 200) {
			yield put({
				type: REMOVE_LENDING_POOL_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_LENDING_POOL_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_LENDING_POOL_FAILURE,
			payload: response.data,
		});
	}
}

function* getLendingPool({ payload }) {
	try {
		const response = yield call(getAll, payload);
		if (response.status === 200) {
			yield put({
				type: GET_ALL_LENDING_POOL_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_ALL_LENDING_POOL_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_ALL_LENDING_POOL_FAILURE,
			payload: response.data,
		});
	}
}

function* cancelUserLendingPool({ payload }) {
	try {
		const response = yield call(deleteUserLending, payload);
		if (response.status === 200) {
			yield put({
				type: CANCEL_USER_LENDING_POOL_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CANCEL_USER_LENDING_POOL_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CANCEL_USER_LENDING_POOL_FAILURE,
			payload: {},
		});
	}
}

function* getLendingSetting({ payload }) {
	try {
		const response = yield call(getSetting, payload);
		if (response.status === 200) {
			yield put({
				type: GET_LENDING_SETTING_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_LENDING_SETTING_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_LENDING_SETTING_FAILURE,
			payload: response.data,
		});
	}
}

function* updateLendingSetting({ payload }) {
	try {
		const response = yield call(updateSetting, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_LENDING_SETTING_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_LENDING_SETTING_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_LENDING_SETTING_FAILURE,
			payload: response.data,
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_LENDING_POOL_REQUEST, createUserLendingPool);
	yield takeEvery(UPDATE_LENDING_POOL_REQUEST, updateUserLendingPool);
	yield takeEvery(REMOVE_LENDING_POOL_REQUEST, removeUserLendingPool);
	yield takeEvery(APPROVE_LENDING_POOL_REQUEST, approveUserLendingPool);
	yield takeEvery(GET_ALL_LENDING_POOL_REQUEST, getLendingPool);
	yield takeEvery(CANCEL_USER_LENDING_POOL_REQUEST, cancelUserLendingPool);
	yield takeEvery(GET_LENDING_SETTING_REQUEST, getLendingSetting);

	yield takeEvery(UPDATE_LENDING_SETTING_REQUEST, updateLendingSetting);
}

export default saga;
