import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_STAKING_POOL_REQUEST,
	CREATE_STAKING_POOL_SUCCESS,
	CREATE_STAKING_POOL_FAILURE,
	UPDATE_STAKING_POOL_SUCCESS,
	UPDATE_STAKING_POOL_REQUEST,
	UPDATE_STAKING_POOL_FAILURE,
	REMOVE_STAKING_POOL_FAILURE,
	REMOVE_STAKING_POOL_SUCCESS,
	REMOVE_STAKING_POOL_REQUEST,
	TOGGLE_STAKING_POOL_SUCCESS,
	TOGGLE_STAKING_POOL_REQUEST,
	TOGGLE_STAKING_POOL_FAILURE,
	APPROVE_STAKING_POOL_SUCCESS,
	APPROVE_STAKING_POOL_REQUEST,
	APPROVE_STAKING_POOL_FAILURE,
	GET_ALL_STAKING_POOL_SUCCESS,
	GET_ALL_STAKING_POOL_REQUEST,
	GET_ALL_STAKING_POOL_FAILURE,
	CANCEL_USER_STAKING_POOL_SUCCESS,
	CANCEL_USER_STAKING_POOL_REQUEST,
	CANCEL_USER_STAKING_POOL_FAILURE,
} from "./actionTypes";

import {
	create,
	update,
	remove,
	toggle,
	approve,
	getAll,
	deleteUserStaking,
} from "./services";

function* createPartner({ payload }) {
	try {
		const response = yield call(create, payload);
		if (response.status === 200) {
			yield put({
				type: CREATE_STAKING_POOL_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_STAKING_POOL_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_STAKING_POOL_FAILURE,
			payload: response.data,
		});
	}
}

function* updatePartner({ payload }) {
	try {
		const response = yield call(update, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_STAKING_POOL_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_STAKING_POOL_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_STAKING_POOL_FAILURE,
			payload: {},
		});
	}
}

function* togglePartner({ payload }) {
	try {
		const response = yield call(toggle, payload);
		if (response.status === 200) {
			yield put({
				type: TOGGLE_STAKING_POOL_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: TOGGLE_STAKING_POOL_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: TOGGLE_STAKING_POOL_FAILURE,
			payload: response.data,
		});
	}
}

function* removePartner({ payload }) {
	try {
		const response = yield call(remove, payload);
		if (response.status === 200) {
			yield put({
				type: REMOVE_STAKING_POOL_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_STAKING_POOL_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_STAKING_POOL_FAILURE,
			payload: response.data,
		});
	}
}

function* approveUserStakingPool({ payload }) {
	try {
		const response = yield call(approve, payload);
		if (response.status === 200) {
			yield put({
				type: APPROVE_STAKING_POOL_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: APPROVE_STAKING_POOL_FAILURE,
				payload: response?.response?.data
					? response.response.data
					: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: APPROVE_STAKING_POOL_FAILURE,
			payload: {},
		});
	}
}

function* removeUserStakingPool({ payload }) {
	try {
		const response = yield call(remove, payload);
		if (response.status === 200) {
			yield put({
				type: REMOVE_STAKING_POOL_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_STAKING_POOL_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_STAKING_POOL_FAILURE,
			payload: response.data,
		});
	}
}

function* getStakingPool({ payload }) {
	try {
		const response = yield call(getAll, payload);
		if (response.status === 200) {
			yield put({
				type: GET_ALL_STAKING_POOL_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_ALL_STAKING_POOL_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_ALL_STAKING_POOL_FAILURE,
			payload: response.data,
		});
	}
}

function* cancelUserStakingPool({ payload }) {
	try {
		const response = yield call(deleteUserStaking, payload);
		if (response.status === 200) {
			yield put({
				type: CANCEL_USER_STAKING_POOL_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CANCEL_USER_STAKING_POOL_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CANCEL_USER_STAKING_POOL_FAILURE,
			payload: {},
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_STAKING_POOL_REQUEST, createPartner);
	yield takeEvery(UPDATE_STAKING_POOL_REQUEST, updatePartner);
	yield takeEvery(REMOVE_STAKING_POOL_REQUEST, removePartner);
	yield takeEvery(TOGGLE_STAKING_POOL_REQUEST, togglePartner);

	yield takeEvery(APPROVE_STAKING_POOL_REQUEST, approveUserStakingPool);
	yield takeEvery(GET_ALL_STAKING_POOL_REQUEST, getStakingPool);
	yield takeEvery(CANCEL_USER_STAKING_POOL_REQUEST, cancelUserStakingPool);
}

export default saga;
