import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_PRODUCT_SUCCESS,
	CREATE_PRODUCT_FAILURE,
	UPDATE_PRODUCT_SUCCESS,
	UPDATE_PRODUCT_FAILURE,
	REMOVE_PRODUCT_FAILURE,
	REMOVE_PRODUCT_SUCCESS,
	TOGGLE_PRODUCT_SUCCESS,
	TOGGLE_PRODUCT_FAILURE,
	GET_PRODUCT_REQUEST,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAILURE,
	GET_PRODUCT_CATEGORY_SUCCESS,
	GET_PRODUCT_CATEGORY_REQUEST,
	GET_PRODUCT_CATEGORY_FAILURE,
	GET_PRODUCT_OPTION_SUCCESS,
	GET_PRODUCT_OPTION_REQUEST,
	GET_PRODUCT_OPTION_FAILURE,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	isLoading: false,
	products: [],
	categories: [],
	options: [],
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				isLoading: false,
			};
		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};
		case CREATE_PRODUCT_SUCCESS:
		case CREATE_PRODUCT_FAILURE:
		case UPDATE_PRODUCT_SUCCESS:
		case UPDATE_PRODUCT_FAILURE:
		case REMOVE_PRODUCT_FAILURE:
		case REMOVE_PRODUCT_SUCCESS:
		case TOGGLE_PRODUCT_SUCCESS:
		case TOGGLE_PRODUCT_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: {},
			};

		case GET_PRODUCT_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case GET_PRODUCT_SUCCESS:
			return {
				...state,
				isLoading: false,
				products: action.payload?.data?.products,
			};
		case GET_PRODUCT_FAILURE:
			return {
				...state,
				isLoading: false,
				errors: {},
				products: [],
			};

		case GET_PRODUCT_CATEGORY_SUCCESS:
			return {
				...state,
				categories: action.payload?.data,
			};
		case GET_PRODUCT_CATEGORY_FAILURE:
			return {
				...state,
				categories: [],
				errors: action.payload,
			};

		case GET_PRODUCT_OPTION_SUCCESS:
			return {
				...state,
				options: action.payload?.data,
			};
		case GET_PRODUCT_OPTION_FAILURE:
			return {
				...state,
				options: [],
				errors: action.payload,
			};

		default:
			return state;
	}
};

export default reducer;
