export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_CUSTOMER_REQUEST = "CREATE_CUSTOMER_REQUEST";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAILURE = "CREATE_CUSTOMER_FAILURE";

export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";

export const REMOVE_CUSTOMER_REQUEST = "REMOVE_CUSTOMER_REQUEST";
export const REMOVE_CUSTOMER_SUCCESS = "REMOVE_CUSTOMER_SUCCESS";
export const REMOVE_CUSTOMER_FAILURE = "REMOVE_CUSTOMER_FAILURE";

export const TOGGLE_CUSTOMER_SUCCESS = "TOGGLE_CUSTOMER_SUCCESS";
export const TOGGLE_CUSTOMER_REQUEST = "TOGGLE_CUSTOMER_REQUEST";
export const TOGGLE_CUSTOMER_FAILURE = "TOGGLE_CUSTOMER_FAILURE";

export const GET_CUSTOMER_REQUEST = "GET_CUSTOMER_REQUEST";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAILURE = "GET_CUSTOMER_FAILURE";
