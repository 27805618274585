export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_NFT_SVG_REQUEST = "CREATE_NFT_SVG_REQUEST";
export const CREATE_NFT_SVG_SUCCESS = "CREATE_NFT_SVG_SUCCESS";
export const CREATE_NFT_SVG_FAILURE = "CREATE_NFT_SVG_FAILURE";

export const UPDATE_NFT_SVG_REQUEST = "UPDATE_NFT_SVG_REQUEST";
export const UPDATE_NFT_SVG_SUCCESS = "UPDATE_NFT_SVG_SUCCESS";
export const UPDATE_NFT_SVG_FAILURE = "UPDATE_NFT_SVG_FAILURE";

export const REMOVE_NFT_SVG_REQUEST = "REMOVE_NFT_SVG_REQUEST";
export const REMOVE_NFT_SVG_SUCCESS = "REMOVE_NFT_SVG_SUCCESS";
export const REMOVE_NFT_SVG_FAILURE = "REMOVE_NFT_SVG_FAILURE";

export const TOGGLE_NFT_SVG_SUCCESS = "TOGGLE_NFT_SVG_SUCCESS";
export const TOGGLE_NFT_SVG_REQUEST = "TOGGLE_NFT_SVG_REQUEST";
export const TOGGLE_NFT_SVG_FAILURE = "TOGGLE_NFT_SVG_FAILURE";
