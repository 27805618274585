import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback } from "react";
//Import Icons
import FeatherIcon from "feather-icons-react";
// //Import Scrollbar
import SimpleBar from "simplebar-react";
// MetisMenu

import HasAnyPermission from "../../common/Permission";

import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
	const ref = useRef();
	const activateParentDropdown = useCallback((item) => {
		item.classList.add("active");
		const parent = item.parentElement;
		const parent2El = parent.childNodes[1];
		if (parent2El && parent2El.id !== "side-menu") {
			parent2El.classList.add("mm-show");
		}
		if (parent) {
			parent.classList.add("mm-active");
			const parent2 = parent.parentElement;
			if (parent2) {
				parent2.classList.add("mm-show"); // ul tag
				const parent3 = parent2.parentElement; // li tag
				if (parent3) {
					parent3.classList.add("mm-active"); // li
					parent3.childNodes[0].classList.add("mm-active"); //a
					const parent4 = parent3.parentElement; // ul
					if (parent4) {
						parent4.classList.add("mm-show"); // ul
						const parent5 = parent4.parentElement;
						if (parent5) {
							parent5.classList.add("mm-show"); // li
							parent5.childNodes[0].classList.add("mm-active"); // a tag
						}
					}
				}
			}
			scrollElement(item);
			return false;
		}
		scrollElement(item);
		return false;
	}, []);

	useEffect(() => {
		const pathName = props.location.pathname;

		const initMenu = () => {
			new MetisMenu("#side-menu");
			let matchingMenuItem = null;
			const ul = document.getElementById("side-menu");
			const items = ul.getElementsByTagName("a");
			for (let i = 0; i < items.length; ++i) {
				if (pathName === items[i].pathname) {
					matchingMenuItem = items[i];
					break;
				}
			}
			if (matchingMenuItem) {
				activateParentDropdown(matchingMenuItem);
			}
		};
		initMenu();
	}, [props.location.pathname, activateParentDropdown]);

	useEffect(() => {
		ref.current.recalculate();
	});

	function scrollElement(item) {
		if (item) {
			const currentPosition = item.offsetTop;
			if (currentPosition > window.innerHeight) {
				ref.current.getScrollElement().scrollTop =
					currentPosition - 300;
			}
		}
	}

	return (
		<React.Fragment>
			<SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
				<div id="sidebar-menu">
					<ul className="metismenu list-unstyled" id="side-menu">
						<li className="menu-title">{props.t("Menu")} </li>
						<li>
							<Link to="/dashboard" className="">
								<i className="fas fa-home"></i>
								<span>{props.t("Dashboard")}</span>
							</Link>
						</li>

						<li>
							<HasAnyPermission
								permission={[
									"admin add",
									"admin list",
									"role add",
									"role list",
								]}
							>
								<Link to="/users" className="">
									<i className="fas fa-user"></i>
									<span>{props.t("Users")}</span>
								</Link>
							</HasAnyPermission>
						</li>

						<li>
							<HasAnyPermission
								permission={[
									"admin add",
									"admin list",
									"role add",
									"role list",
								]}
							>
								<Link to="/mac-address" className="">
									<i className="fas fa-desktop"></i>
									<span>{props.t("MAC Address")}</span>
								</Link>
							</HasAnyPermission>
						</li>

						<li>
							<HasAnyPermission
								permission={[
									"admin add",
									"admin list",
									"role add",
									"role list",
									"LFI setting view",
									"LFI setting update",
									"NFT svg list",
									"NFT svg view",
									"partner list",
									"partner view",
								]}
							>
								<Link
									to="/#"
									className="has-arrow"
									aria-expanded={false}
								>
									<i className="fas fa-shopping-basket"></i>
									<span>{props.t("Shop")}</span>
								</Link>
								<ul className="sub-menu">
									{/* <li>
										<HasAnyPermission
											permission={[
												"chain list",
												"chain view",
											]}
										>
											<Link
												to="/shop/voucher-code-package"
												className=""
											>
												<span>
													{props.t(
														"Voucher Code Package"
													)}
												</span>
											</Link>
										</HasAnyPermission>
									</li> */}

									<li>
										<HasAnyPermission
											permission={[
												"chain list",
												"chain view",
											]}
										>
											<Link
												to="/shop/customers"
												className=""
											>
												<span>
													{props.t("Customer")}
												</span>
											</Link>
										</HasAnyPermission>
									</li>

									{/* <li>
										<HasAnyPermission
											permission={[
												"chain list",
												"chain view",
											]}
										>
											<Link
												to="/shop/product-option"
												className=""
											>
												<span>
													{props.t("Product Option")}
												</span>
											</Link>
										</HasAnyPermission>
									</li>

									<li>
										<HasAnyPermission
											permission={[
												"chain list",
												"chain view",
											]}
										>
											<Link
												to="/shop/products"
												className=""
											>
												<span>
													{props.t("Product")}
												</span>
											</Link>
										</HasAnyPermission>
									</li> */}

									<li>
										<HasAnyPermission
											permission={[
												"chain list",
												"chain view",
											]}
										>
											<Link
												to="/shop/orders"
												className=""
											>
												<span>{props.t("Orders")}</span>
											</Link>
										</HasAnyPermission>
									</li>

									<li>
										<HasAnyPermission
											permission={[
												"admin add",
												"admin list",
											]}
										>
											<Link
												to="/#"
												className="has-arrow"
												aria-expanded={false}
											>
												<span>{props.t("Tax")}</span>
											</Link>
											<ul className="sub-menu">
												<li>
													<HasAnyPermission
														permission={[
															"admin add",
															"admin list",
														]}
													>
														<Link
															to="/shop/tax"
															className=""
														>
															<span>
																{props.t("Tax")}
															</span>
														</Link>
													</HasAnyPermission>
												</li>
												<li>
													<HasAnyPermission
														permission={[
															"admin add",
															"admin list",
														]}
													>
														<Link
															to="/shop/tax/country"
															className=""
														>
															<span>
																{props.t(
																	"Country tax"
																)}
															</span>
														</Link>
													</HasAnyPermission>
												</li>
											</ul>
										</HasAnyPermission>
									</li>

									<li>
										<HasAnyPermission
											permission={[
												"admin add",
												"admin list",
											]}
										>
											<Link
												to="/#"
												className="has-arrow"
												aria-expanded={false}
											>
												<span>
													{props.t(
														"Shipping Settings"
													)}
												</span>
											</Link>
											<ul className="sub-menu">
												<li>
													<HasAnyPermission
														permission={[
															"admin add",
															"admin list",
														]}
													>
														<Link
															to="/shipping/setting"
															className=""
														>
															<span>
																{props.t(
																	"Shipping Setting"
																)}
															</span>
														</Link>
													</HasAnyPermission>
												</li>
												<li>
													<HasAnyPermission
														permission={[
															"admin add",
															"admin list",
														]}
													>
														<Link
															to="/shipping/fee"
															className=""
														>
															<span>
																{props.t(
																	"Countrywise shipping Fee"
																)}
															</span>
														</Link>
													</HasAnyPermission>
												</li>
											</ul>
										</HasAnyPermission>
									</li>
								</ul>
							</HasAnyPermission>
						</li>

						<li>
							<HasAnyPermission
								permission={[
									"admin add",
									"admin list",
									"role add",
									"role list",
									"LFI setting view",
									"LFI setting update",
									"NFT svg list",
									"NFT svg view",
									"partner list",
									"partner view",
								]}
							>
								<Link
									to="/#"
									className="has-arrow"
									aria-expanded={false}
								>
									<i className="fas fa-hand-holding-usd"></i>
									<span>{props.t("Lending & Staking")}</span>
								</Link>
								<ul className="sub-menu">
									<li>
										<HasAnyPermission
											permission={[
												"chain list",
												"chain view",
											]}
										>
											<Link
												to="/staking/pool"
												className=""
											>
												<span>
													{props.t("Staking")}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"chain list",
												"chain view",
											]}
										>
											<Link
												to="/staking/user/pool"
												className=""
											>
												<span>
													{props.t("User stake")}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"chain list",
												"chain view",
											]}
										>
											<Link
												to="/lending/pool"
												className=""
											>
												<span>
													{props.t("Lending pool")}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"chain list",
												"chain view",
											]}
										>
											<Link
												to="/lending/user/pool"
												className=""
											>
												<span>
													{props.t(
														"User Lending pool"
													)}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
								</ul>
							</HasAnyPermission>
						</li>
						<li>
							<HasAnyPermission
								permission={[
									"admin add",
									"admin list",
									"role add",
									"role list",
									"LFI setting view",
									"LFI setting update",
									"NFT svg list",
									"NFT svg view",
									"partner list",
									"partner view",
								]}
							>
								<Link
									to="/#"
									className="has-arrow"
									aria-expanded={false}
								>
									<i className="fas fa-cog"></i>
									<span>{props.t("Setting")}</span>
								</Link>
								<ul className="sub-menu">
									<li>
										<HasAnyPermission
											permission={[
												"chain list",
												"chain view",
											]}
										>
											<Link
												to="/wallet-setting"
												className=""
											>
												<span>
													{props.t("Wallet Setting")}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"chain list",
												"chain view",
											]}
										>
											<Link
												to="/lending-staking-setting"
												className=""
											>
												<span>
													{props.t(
														"Lending Staking Setting"
													)}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"chain list",
												"chain view",
											]}
										>
											<Link
												to="/chain-setting"
												className=""
											>
												<span>
													{props.t("Chain Setting")}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"partner list",
												"partner view",
											]}
										>
											<Link
												to="/setting/partner"
												className=""
											>
												<span>
													{props.t("Partner")}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"software package list",
												"software package view",
											]}
										>
											<Link
												to="/software-package"
												className=""
											>
												<span>
													{props.t(
														"Software Package"
													)}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"NFT svg list",
												"NFT svg view",
											]}
										>
											<Link
												to="/setting/nft-svg"
												className=""
											>
												<span>
													{props.t("NFT SVG")}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"LFI setting view",
												"LFI setting update",
											]}
										>
											<Link
												to="/setting/lfi"
												className=""
											>
												<span>
													{props.t(
														"LFI Reward Setting"
													)}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"LFI setting view",
												"LFI setting update",
											]}
										>
											<Link
												to="/setting/cloud-usage"
												className=""
											>
												<span>
													{props.t(
														"CloudUsage Statistics Setting"
													)}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"LFI setting view",
												"LFI setting update",
											]}
										>
											<Link
												to="/setting/nft-mint"
												className=""
											>
												<span>
													{props.t(
														"NFT Mint Burn Setting"
													)}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"admin add",
												"admin list",
											]}
										>
											<Link
												to="/#"
												className="has-arrow"
												aria-expanded={false}
											>
												<span>
													{props.t("Admin & Role")}
												</span>
											</Link>
											<ul className="sub-menu">
												<li>
													<HasAnyPermission
														permission={[
															"admin add",
															"admin list",
														]}
													>
														<Link
															to="/admins"
															className=""
														>
															<span>
																{props.t(
																	"Admin"
																)}
															</span>
														</Link>
													</HasAnyPermission>
												</li>
												<li>
													<HasAnyPermission
														permission={[
															"role add",
															"role list",
														]}
													>
														<Link
															to="/roles"
															className=""
															aria-expanded={
																false
															}
														>
															<span>
																{props.t(
																	"Roles"
																)}
															</span>
														</Link>
													</HasAnyPermission>
												</li>
											</ul>
										</HasAnyPermission>
									</li>
								</ul>
							</HasAnyPermission>
						</li>
					</ul>
				</div>
			</SimpleBar>
		</React.Fragment>
	);
};

SidebarContent.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
