import {
	ERRORS,
	CLEAR_RESPONSE,
	GET_SHIPPING_SETTING_SUCCESS,
	GET_SHIPPING_SETTING_REQUEST,
	GET_SHIPPING_SETTING_FAILURE,
	UPDATE_SHIPPING_SETTING_SUCCESS,
	UPDATE_SHIPPING_SETTING_REQUEST,
	UPDATE_SHIPPING_SETTING_FAILURE,
	GET_COUNTRY_SUCCESS,
	GET_COUNTRY_REQUEST,
	GET_COUNTRY_FAILURE,
	GET_SHIPPING_METHOD_SUCCESS,
	GET_SHIPPING_METHOD_REQUEST,
	GET_SHIPPING_METHOD_FAILURE,
	GET_SHIPPING_WEIGHT_SUCCESS,
	GET_SHIPPING_WEIGHT_REQUEST,
	GET_SHIPPING_WEIGHT_FAILURE,
	GET_COUNTRYWISE_SHIPPING_FEE_SUCCESS,
	GET_COUNTRYWISE_SHIPPING_FEE_REQUEST,
	GET_COUNTRYWISE_SHIPPING_FEE_FAILURE,
	UPDATE_SHIPPING_FEE_REQUEST,
	UPDATE_SHIPPING_FEE_SUCCESS,
	UPDATE_SHIPPING_FEE_FAILURE,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	setting: {},
	isLoading: false,
	lendingPools: [],
	countries: [],
	shippingFees: {},
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				isLoading: false,
			};
		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};
		case UPDATE_SHIPPING_SETTING_FAILURE:
		case UPDATE_SHIPPING_SETTING_SUCCESS:

		case UPDATE_SHIPPING_FEE_SUCCESS:
		case UPDATE_SHIPPING_FEE_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: {},
				isLoading: false,
			};
		case UPDATE_SHIPPING_SETTING_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case GET_SHIPPING_SETTING_SUCCESS:
			return {
				...state,
				setting: action.payload?.data,
			};
		case GET_SHIPPING_SETTING_FAILURE:
			return {
				...state,
				setting: {},
				errors: action.payload,
			};
		case GET_COUNTRY_SUCCESS:
			return {
				...state,
				countries: action.payload?.data,
			};
		case GET_COUNTRY_FAILURE:
			return {
				...state,
				countries: [],
				errors: action.payload,
			};

		case GET_SHIPPING_METHOD_SUCCESS:
			return {
				...state,
				shippingMethods: action.payload?.data,
			};
		case GET_SHIPPING_METHOD_FAILURE:
			return {
				...state,
				shippingMethods: [],
				errors: action.payload,
			};

		case GET_SHIPPING_WEIGHT_SUCCESS:
			return {
				...state,
				shippingWeights: action.payload?.data,
			};
		case GET_SHIPPING_WEIGHT_FAILURE:
			return {
				...state,
				shippingWeights: [],
				errors: action.payload,
			};

		case GET_COUNTRYWISE_SHIPPING_FEE_SUCCESS:
			return {
				...state,
				shippingFees: action.payload?.data,
			};
		case GET_COUNTRYWISE_SHIPPING_FEE_FAILURE:
			return {
				...state,
				shippingFees: {},
				errors: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
