export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_TAX_REQUEST = "CREATE_TAX_REQUEST";
export const CREATE_TAX_SUCCESS = "CREATE_TAX_SUCCESS";
export const CREATE_TAX_FAILURE = "CREATE_TAX_FAILURE";

export const UPDATE_TAX_REQUEST = "UPDATE_TAX_REQUEST";
export const UPDATE_TAX_SUCCESS = "UPDATE_TAX_SUCCESS";
export const UPDATE_TAX_FAILURE = "UPDATE_TAX_FAILURE";

export const REMOVE_TAX_REQUEST = "REMOVE_TAX_REQUEST";
export const REMOVE_TAX_SUCCESS = "REMOVE_TAX_SUCCESS";
export const REMOVE_TAX_FAILURE = "REMOVE_TAX_FAILURE";

export const TOGGLE_TAX_SUCCESS = "TOGGLE_TAX_SUCCESS";
export const TOGGLE_TAX_REQUEST = "TOGGLE_TAX_REQUEST";
export const TOGGLE_TAX_FAILURE = "TOGGLE_TAX_FAILURE";

export const GET_TAX_SUCCESS = "GET_TAX_SUCCESS";
export const GET_TAX_REQUEST = "GET_TAX_REQUEST";
export const GET_TAX_FAILURE = "GET_TAX_FAILURE";
export const GET_COUNTRY_TAX_SUCCESS = "GET_COUNTRY_TAX_SUCCESS";
export const GET_COUNTRY_TAX_REQUEST = "GET_COUNTRY_TAX_REQUEST";
export const GET_COUNTRY_TAX_FAILURE = "GET_COUNTRY_TAX_FAILURE";
export const UPDATE_COUNTRY_TAX_SUCCESS = "UPDATE_COUNTRY_TAX_SUCCESS";
export const UPDATE_COUNTRY_TAX_REQUEST = "UPDATE_COUNTRY_TAX_REQUEST";
export const UPDATE_COUNTRY_TAX_FAILURE = "UPDATE_COUNTRY_TAX_FAILURE";

export const DELETE_COUNTRY_TAX_SUCCESS = "DELETE_COUNTRY_TAX_SUCCESS";
export const DELETE_COUNTRY_TAX_REQUEST = "DELETE_COUNTRY_TAX_REQUEST";
export const DELETE_COUNTRY_TAX_FAILURE = "DELETE_COUNTRY_TAX_FAILURE";
