import React from "react";
import {
	Col,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormFeedback,
	Label,
} from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const UserStakingApproveModal = ({
	approveDetails,
	handleApproveDetails,
	validation,
	getStakingPoolOptions,
	isLoading,
}) => {
	const animatedComponents = makeAnimated();
	return (
		<React.Fragment>
			<Modal
				isOpen={approveDetails.isOpenModal}
				toggle={() => handleApproveDetails({})}
				backdrop={"static"}
				size="md"
				centered={true}
			>
				<ModalHeader toggle={() => handleApproveDetails({})} tag="h4">
					{"Approve"}
				</ModalHeader>
				<ModalBody>
					<Form
						onSubmit={(e) => {
							e.preventDefault();
							validation.handleSubmit();
							return false;
						}}
					>
						<Row form={"true"}>
							<Col xs={12}>
								<div className="mb-3">
									<Label className="form-label">
										Select Staking Pool
									</Label>
									<Select
										name="_id"
										value={validation.values._id || ""}
										onChange={(value, action) => {
											validation.setFieldValue(
												"_id",
												value
											);
										}}
										isMulti={false}
										options={getStakingPoolOptions}
										classNamePrefix="select2-selection"
										onSelectResetsInput={true}
										closeMenuOnSelect={true}
										components={animatedComponents}
									/>
									{validation.touched._id &&
									validation.errors._id ? (
										<FormFeedback type="invalid">
											{validation.errors._id}
										</FormFeedback>
									) : null}
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="text-end mt-3">
									<button
										type="submit"
										className="btn btn-success save-user"
									>
										{isLoading ? (
											<React.Fragment>
												<i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
												Loading
											</React.Fragment>
										) : (
											"Approve"
										)}
									</button>
								</div>
							</Col>
						</Row>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	);
};

export default UserStakingApproveModal;
