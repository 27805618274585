import axios from "axios";
import { apiUrl } from "../../config";

export const create = (data) =>
	axios
		.post(`${apiUrl}/admin/lending/pool`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data, _id) =>
	axios
		.put(`${apiUrl}/admin/lending/pool/edit/${data._id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const approve = (data) =>
	axios
		.put(`${apiUrl}/admin/lending/lend/approve/${data._id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const remove = (data) =>
	axios
		.delete(`${apiUrl}/admin/lending/pool/delete/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getAll = () =>
	axios
		.get(`${apiUrl}/admin/lending/pool`)
		.then((response) => response)
		.catch((err) => err.response);

export const deleteUserLending = (data) =>
	axios
		.delete(`${apiUrl}/admin/lending/lend/delete/${data._id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const getSetting = () =>
	axios
		.get(`${apiUrl}/admin/store/shipping/setting`)
		.then((response) => response)
		.catch((err) => err.response);

export const getCountry = () =>
	axios
		.get(`${apiUrl}/admin/store/shipping/country`)
		.then((response) => response)
		.catch((err) => err.response);

export const getWeight = () =>
	axios
		.get(`${apiUrl}/admin/store/shipping/weights`)
		.then((response) => response)
		.catch((err) => err.response);

export const getMethod = () =>
	axios
		.get(`${apiUrl}/admin/store/shipping/methods`)
		.then((response) => response)
		.catch((err) => err.response);

export const updateSetting = (data) =>
	axios
		.put(`${apiUrl}/admin/store/shipping/setting`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const getFees = () =>
	axios
		.get(`${apiUrl}/admin/store/shipping/fee`)
		.then((response) => response)
		.catch((err) => err.response);

export const updateFee = (data) =>
	axios
		.put(`${apiUrl}/admin/store/shipping/fee`, data)
		.then((response) => response)
		.catch((err) => err.response);
