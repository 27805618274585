import axios from "axios";
import { apiUrl } from "../../config";

export const create = (data) =>
	axios
		.post(`${apiUrl}/admin/store/customers`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data) =>
	axios
		.put(`${apiUrl}/admin/store/customers/${data.id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const toggle = (data) =>
	axios
		.patch(`${apiUrl}/admin/partner/toggle-status/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const remove = (data) =>
	axios
		.delete(`${apiUrl}/admin/partner/delete/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getOrder = (data) =>
	axios
		.get(`${apiUrl}/admin/store/orders`)
		.then((response) => response)
		.catch((err) => err.response);

export const getMacAddressService = (data) =>
	axios
		.get(`${apiUrl}/admin/store/orders/mac-address`)
		.then((response) => response)
		.catch((err) => err.response);

export const getOrderUsingId = (data) =>
	axios
		.get(`${apiUrl}/admin/store/orders/${data.id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getInvoiceDetails = (data) =>
	axios
		.get(`${apiUrl}/admin/store/orders/get-order-invoice/${data.id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getUpgradeProductService = (data) =>
	axios
		.get(`${apiUrl}/admin/store/orders/product/get-upgrade-product`)
		.then((response) => response)
		.catch((err) => err.response);

export const upgradeOrderProductService = (data) =>
	axios
		.post(`${apiUrl}/admin/store/orders/product/upgrade`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const getExportOrderCSVService = (data) =>
	axios
		.get(`${apiUrl}/admin/store/orders/export`, {
			params: data,
		})
		.then((response) => response)
		.catch((err) => err.response);

export const getOrderMerchantInvoiceService = (data) =>
	axios
		.get(`${apiUrl}/admin/store/orders/get-invoice/${data.orderId}`, {
			params: data,
		})
		.then((response) => response)
		.catch((err) => err.response);
