export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_STAKING_POOL_REQUEST = "CREATE_STAKING_POOL_REQUEST";
export const CREATE_STAKING_POOL_SUCCESS = "CREATE_STAKING_POOL_SUCCESS";
export const CREATE_STAKING_POOL_FAILURE = "CREATE_STAKING_POOL_FAILURE";
export const UPDATE_STAKING_POOL_REQUEST = "UPDATE_STAKING_POOL_REQUEST";
export const UPDATE_STAKING_POOL_SUCCESS = "UPDATE_STAKING_POOL_SUCCESS";
export const UPDATE_STAKING_POOL_FAILURE = "UPDATE_STAKING_POOL_FAILURE";
export const REMOVE_STAKING_POOL_REQUEST = "REMOVE_STAKING_POOL_REQUEST";
export const REMOVE_STAKING_POOL_SUCCESS = "REMOVE_STAKING_POOL_SUCCESS";
export const REMOVE_STAKING_POOL_FAILURE = "REMOVE_STAKING_POOL_FAILURE";
export const TOGGLE_STAKING_POOL_SUCCESS = "TOGGLE_STAKING_POOL_SUCCESS";
export const TOGGLE_STAKING_POOL_REQUEST = "TOGGLE_STAKING_POOL_REQUEST";
export const TOGGLE_STAKING_POOL_FAILURE = "TOGGLE_STAKING_POOL_FAILURE";

export const APPROVE_STAKING_POOL_SUCCESS = "APPROVE_STAKING_POOL_SUCCESS";
export const APPROVE_STAKING_POOL_REQUEST = "APPROVE_STAKING_POOL_REQUEST";
export const APPROVE_STAKING_POOL_FAILURE = "APPROVE_STAKING_POOL_FAILURE";

export const GET_ALL_STAKING_POOL_SUCCESS = "GET_ALL_STAKING_POOL_SUCCESS";
export const GET_ALL_STAKING_POOL_REQUEST = "GET_ALL_STAKING_POOL_REQUEST";
export const GET_ALL_STAKING_POOL_FAILURE = "GET_ALL_STAKING_POOL_FAILURE";

export const CANCEL_USER_STAKING_POOL_SUCCESS =
	"CANCEL_USER_STAKING_POOL_SUCCESS";
export const CANCEL_USER_STAKING_POOL_REQUEST =
	"CANCEL_USER_STAKING_POOL_REQUEST";
export const CANCEL_USER_STAKING_POOL_FAILURE =
	"CANCEL_USER_STAKING_POOL_FAILURE";
