import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_CHAIN_SETTING_SUCCESS,
	CREATE_CHAIN_SETTING_FAILURE,
	UPDATE_CHAIN_SETTING_SUCCESS,
	UPDATE_CHAIN_SETTING_FAILURE,
	REMOVE_CHAIN_SETTING_FAILURE,
	REMOVE_CHAIN_SETTING_SUCCESS,
	TOGGLE_CHAIN_SETTING_SUCCESS,
	TOGGLE_CHAIN_SETTING_FAILURE,
	GET_CHAIN_SETTING_SUCCESS,
	GET_CHAIN_SETTING_REQUEST,
	GET_CHAIN_SETTING_FAILURE,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	chainList: [],
	isLoading: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				isLoading: false,
			};
		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};
		case CREATE_CHAIN_SETTING_SUCCESS:
		case CREATE_CHAIN_SETTING_FAILURE:
		case UPDATE_CHAIN_SETTING_SUCCESS:
		case UPDATE_CHAIN_SETTING_FAILURE:
		case REMOVE_CHAIN_SETTING_FAILURE:
		case REMOVE_CHAIN_SETTING_SUCCESS:
		case TOGGLE_CHAIN_SETTING_SUCCESS:
		case TOGGLE_CHAIN_SETTING_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: {},
			};
		case GET_CHAIN_SETTING_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case GET_CHAIN_SETTING_SUCCESS:
			return {
				...state,
				isLoading: false,
				chainList: action.payload?.data?.data?.data,
			};
		case GET_CHAIN_SETTING_FAILURE:
			return {
				...state,
				isLoading: false,
				response: action.payload,
				chainList: [],
				errors: {},
			};
		default:
			return state;
	}
};

export default reducer;
