import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_TAX_REQUEST,
	CREATE_TAX_SUCCESS,
	CREATE_TAX_FAILURE,
	UPDATE_TAX_SUCCESS,
	UPDATE_TAX_REQUEST,
	UPDATE_TAX_FAILURE,
	REMOVE_TAX_FAILURE,
	REMOVE_TAX_SUCCESS,
	REMOVE_TAX_REQUEST,
	TOGGLE_TAX_SUCCESS,
	TOGGLE_TAX_REQUEST,
	TOGGLE_TAX_FAILURE,
	GET_TAX_SUCCESS,
	GET_TAX_REQUEST,
	GET_TAX_FAILURE,
	GET_COUNTRY_TAX_SUCCESS,
	GET_COUNTRY_TAX_REQUEST,
	GET_COUNTRY_TAX_FAILURE,
	UPDATE_COUNTRY_TAX_SUCCESS,
	UPDATE_COUNTRY_TAX_REQUEST,
	UPDATE_COUNTRY_TAX_FAILURE,
	DELETE_COUNTRY_TAX_SUCCESS,
	DELETE_COUNTRY_TAX_REQUEST,
	DELETE_COUNTRY_TAX_FAILURE,
} from "./actionTypes";

import {
	create,
	update,
	remove,
	toggle,
	getTax,
	getCountryTax,
	updateCountryTaxService,
	deleteCountryTaxService,
} from "./services";
function* createTAX({ payload }) {
	try {
		const response = yield call(create, payload);
		if (response.status === 200) {
			yield put({
				type: CREATE_TAX_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_TAX_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_TAX_FAILURE,
			payload: response.data,
		});
	}
}

function* updateTAX({ payload }) {
	try {
		const response = yield call(update, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_TAX_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_TAX_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_TAX_FAILURE,
			payload: {},
		});
	}
}

function* toggleTAX({ payload }) {
	try {
		const response = yield call(toggle, payload);
		if (response.status === 200) {
			yield put({
				type: TOGGLE_TAX_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: TOGGLE_TAX_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: TOGGLE_TAX_FAILURE,
			payload: response.data,
		});
	}
}

function* removeTAX({ payload }) {
	try {
		const response = yield call(remove, payload);
		if (response.status === 200) {
			yield put({
				type: REMOVE_TAX_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_TAX_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_TAX_FAILURE,
			payload: response.data,
		});
	}
}

function* getAllTax({ payload }) {
	try {
		const response = yield call(getTax, payload);
		if (response.status === 200) {
			yield put({
				type: GET_TAX_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_TAX_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_TAX_FAILURE,
			payload: {},
		});
	}
}

function* getAllCountryTax({ payload }) {
	try {
		const response = yield call(getCountryTax, payload);
		if (response.status === 200) {
			yield put({
				type: GET_COUNTRY_TAX_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_COUNTRY_TAX_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_COUNTRY_TAX_FAILURE,
			payload: {},
		});
	}
}
function* updateCountryTax({ payload }) {
	try {
		const response = yield call(updateCountryTaxService, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_COUNTRY_TAX_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_COUNTRY_TAX_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_COUNTRY_TAX_FAILURE,
			payload: {},
		});
	}
}
function* deleteCountryTax({ payload }) {
	try {
		const response = yield call(deleteCountryTaxService, payload);
		if (response.status === 200) {
			yield put({
				type: DELETE_COUNTRY_TAX_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: DELETE_COUNTRY_TAX_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: DELETE_COUNTRY_TAX_FAILURE,
			payload: {},
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_TAX_REQUEST, createTAX);
	yield takeEvery(UPDATE_TAX_REQUEST, updateTAX);
	yield takeEvery(REMOVE_TAX_REQUEST, removeTAX);
	yield takeEvery(TOGGLE_TAX_REQUEST, toggleTAX);
	yield takeEvery(GET_TAX_REQUEST, getAllTax);
	yield takeEvery(GET_COUNTRY_TAX_REQUEST, getAllCountryTax);
	yield takeEvery(UPDATE_COUNTRY_TAX_REQUEST, updateCountryTax);
	yield takeEvery(DELETE_COUNTRY_TAX_REQUEST, deleteCountryTax);
}

export default saga;
