export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_PRODUCT_OPTION_REQUEST = "CREATE_PRODUCT_OPTION_REQUEST";
export const CREATE_PRODUCT_OPTION_SUCCESS = "CREATE_PRODUCT_OPTION_SUCCESS";
export const CREATE_PRODUCT_OPTION_FAILURE = "CREATE_PRODUCT_OPTION_FAILURE";

export const UPDATE_PRODUCT_OPTION_REQUEST = "UPDATE_PRODUCT_OPTION_REQUEST";
export const UPDATE_PRODUCT_OPTION_SUCCESS = "UPDATE_PRODUCT_OPTION_SUCCESS";
export const UPDATE_PRODUCT_OPTION_FAILURE = "UPDATE_PRODUCT_OPTION_FAILURE";

export const REMOVE_PRODUCT_OPTION_REQUEST = "REMOVE_PRODUCT_OPTION_REQUEST";
export const REMOVE_PRODUCT_OPTION_SUCCESS = "REMOVE_PRODUCT_OPTION_SUCCESS";
export const REMOVE_PRODUCT_OPTION_FAILURE = "REMOVE_PRODUCT_OPTION_FAILURE";

export const TOGGLE_PRODUCT_OPTION_SUCCESS = "TOGGLE_PRODUCT_OPTION_SUCCESS";
export const TOGGLE_PRODUCT_OPTION_REQUEST = "TOGGLE_PRODUCT_OPTION_REQUEST";
export const TOGGLE_PRODUCT_OPTION_FAILURE = "TOGGLE_PRODUCT_OPTION_FAILURE";
