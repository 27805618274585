import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import {
	create,
	update,
	toggle,
	remove,
	getChain,
	clearResponse,
} from "../../store/chainSetting/actions";
import MUIDataTable from "mui-datatables";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import moment from "moment";
import isEmpty from "../../utils/isEmpty";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const ChainSetting = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { response, auth, chainList, isLoading } = useSelector((state) => ({
		response: state.chainSetting.response,
		chainList: state.chainSetting.chainList,
		isLoading: state.chainSetting.isLoading,
		auth: state.auth,
	}));

	const noMatch = isLoading ? (
		<CircularProgress size={30} style={{ color: "#3f51b5" }} />
	) : (
		"Sorry, no matching records found."
	);

	useEffect(() => {
		dispatch(getChain());
	}, []);

	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [details, handleformData] = useState({});

	useEffect(() => {
		if (response && response.code === "200") {
			dispatch(getChain());
			Swal.close();
			if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		} else if (response && response?.msg) {
			Swal.close();
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const removeItem = (data) => {
		if (!isEmpty(data?._id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to delete?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					dispatch(remove(data));
				}
			});
		}
	};

	const handleTooltipOpen = () => {
		toast.success("Copied to clipboard.");
	};

	const handleToggle = (data) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to update?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				Swal.fire({
					title: "Updating",
					html: "Please wait...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				dispatch(toggle(data));
			}
		});
	};

	let validationSchema = {
		chainId: Yup.string().required("Please enter chain Id."),
		chainName: Yup.string().required("Please enter chain name."),
		nodeUrl: Yup.string().required("Please enter node url."),
		scannerLink: Yup.string().required("Please enter scanner link."),
		nftContractAddress: Yup.string().required("Please enter nft address."),
		mintWalletAddress: Yup.string().required(
			"Please enter mintWalletAddress."
		),
	};

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			chainId: details && details.chainId ? details.chainId : "",
			chainName: details && details.chainName ? details.chainName : "",
			nodeUrl: details && details.nodeUrl ? details.nodeUrl : "",
			scannerLink:
				details && details.scannerLink ? details.scannerLink : "",
			mintWalletAddress:
				details && details.mintWalletAddress
					? details.mintWalletAddress
					: "",
			nftContractAddress:
				details && details.nftContractAddress
					? details.nftContractAddress
					: "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => {
			if (details._id) {
				dispatch(update({ ...values, _id: details._id }));
			} else {
				dispatch(create({ ...values, walletBalance: 0 }));
			}
		},
	};

	const validation = useFormik(useFormikOptions);

	const handleAddEditModal = (data = null) => {
		if (!isEmpty(data) && data?._id) {
			handleformData(data);
		} else {
			handleformData({
				chainId: "",
				chainName: "",
				nftContractAddress: "",
				scannerLink: "",
				mintWalletAddress: "",
				nodeUrl: "",
			});
			validation.resetForm();
		}
		toggleAddEditModal(!isOpenAddEditModal);
	};

	const resultFormatter = (result) => {
		return result.map((item) => {
			return {
				...item,
				action: item,
			};
		});
	};

	const columns = () => [
		{
			label: "Chain Id",
			name: "chainId",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Chain Name",
			name: "chainName",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Node URL",
			name: "nodeUrl",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (nodeUrl) => {
					const lastNineChara = nodeUrl?.substr(nodeUrl.length - 9);
					return (
						<div className="text-nowrap">
							<CopyToClipboard
								data-tooltip-content={nodeUrl}
								id={lastNineChara}
								text={nodeUrl}
								style={{
									cursor: "pointer",
								}}
								onCopy={() => handleTooltipOpen()}
							>
								<span
									style={{
										cursor: "pointer",
									}}
								>
									{`${nodeUrl?.substring(0, 10)}...`}
									<i className="mdi mdi-content-copy"></i>
								</span>
							</CopyToClipboard>

							<ReactTooltip
								anchorId={lastNineChara}
								variant="info"
								place="bottom"
								style={{
									zIndex: 2147483647,
								}}
							/>
						</div>
					);
				},
			},
		},

		{
			label: "Scanner Link",
			name: "scannerLink",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (scannerLink) => {
					const lastNineChara = scannerLink?.substr(
						scannerLink.length - 9
					);
					return (
						<div className="text-nowrap">
							<CopyToClipboard
								id={lastNineChara}
								data-tooltip-content={scannerLink}
								text={scannerLink}
								style={{
									cursor: "pointer",
								}}
								onCopy={() => handleTooltipOpen()}
							>
								<span
									style={{
										cursor: "pointer",
									}}
								>
									{`${scannerLink?.substring(0, 10)}...`}
									<i className="mdi mdi-content-copy"></i>
								</span>
							</CopyToClipboard>

							<ReactTooltip
								anchorId={lastNineChara}
								variant="info"
								place="bottom"
								style={{
									zIndex: 2147483647,
								}}
							/>
						</div>
					);
				},
			},
		},
		{
			label: "Nft Contract Address",
			name: "nftContractAddress",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (nftContractAddress) => {
					return (
						<div className="text-nowrap">
							<CopyToClipboard
								id={nftContractAddress}
								data-tooltip-content={nftContractAddress}
								text={nftContractAddress}
								style={{
									cursor: "pointer",
								}}
								onCopy={() => handleTooltipOpen()}
							>
								<span
									style={{
										cursor: "pointer",
									}}
								>
									{`${nftContractAddress?.substring(
										0,
										10
									)}...`}
									<i className="mdi mdi-content-copy"></i>
								</span>
							</CopyToClipboard>

							<ReactTooltip
								anchorId={nftContractAddress}
								variant="info"
								place="bottom"
								style={{
									zIndex: 2147483647,
								}}
							/>
						</div>
					);
				},
			},
		},

		{
			label: "Mint Wallet Address",
			name: "mintWalletAddress",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (mintWalletAddress) => {
					return (
						<div className="text-nowrap">
							<CopyToClipboard
								id={mintWalletAddress}
								data-tooltip-content={mintWalletAddress}
								text={mintWalletAddress}
								style={{
									cursor: "pointer",
								}}
								onCopy={() => handleTooltipOpen()}
							>
								<span
									style={{
										cursor: "pointer",
									}}
								>
									{`${mintWalletAddress?.substring(
										0,
										10
									)}...`}
									<i className="mdi mdi-content-copy"></i>
								</span>
							</CopyToClipboard>

							<ReactTooltip
								anchorId={mintWalletAddress}
								variant="info"
								place="bottom"
								style={{
									zIndex: 2147483647,
								}}
							/>
						</div>
					);
				},
			},
		},
		{
			label: "Balance",
			name: "walletBalance",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			name: "active",
			label: "Status",
			options: {
				filter: false,
				sort: false,
				download: false,
				customBodyRender: (status) => {
					return status === true ? (
						<div className="badge badge-soft-primary font-size-12">
							Active
						</div>
					) : (
						<div className="badge badge-soft-danger font-size-12">
							Inactive
						</div>
					);
				},
			},
		},

		{
			label: "Created At",
			name: "created_at",
			options: {
				filter: false,
				sort: false,
				download: false,
				customBodyRender: (createdAt) =>
					moment(createdAt).format("lll"),
			},
		},
		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: hasPermission(
					["chain update", "chain delete", "chain view"],
					auth.currentUserRolePermissions
				),
				viewColumns: hasPermission(
					["chain update", "chain delete", "chain view"],
					auth.currentUserRolePermissions
				),
				customBodyRender: (data) => {
					return (
						<div className="text-center">
							<HasAnyPermission permission={["chain update"]}>
								<button
									onClick={(e) => handleAddEditModal(data)}
									type="button"
									className="btn btn-soft-primary waves-effect waves-light m-1"
								>
									<i className="bx bx-edit-alt font-size-16 align-middle"></i>
								</button>
							</HasAnyPermission>
							{!data.active && (
								<HasAnyPermission permission={["chain update"]}>
									<button
										onClick={(e) => handleToggle(data)}
										type="button"
										className="btn btn-soft-success waves-effect waves-light m-1"
									>
										Active this chain
									</button>
								</HasAnyPermission>
							)}
						</div>
					);
				},
			},
		},
	];

	const couldHaveAddUpdatePermission = () => {
		const isUpdatePermission = hasPermission(
			["chain update"],
			auth.currentUserRolePermissions
		);
		const isAddPermission = hasPermission(
			["chain add"],
			auth.currentUserRolePermissions
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details._id)) return true;
		else if (isAddPermission && isEmpty(details._id)) return true;
		else return false;
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					{/* Render Breadcrumb */}
					<Breadcrumbs
						title="Chain Setting"
						breadcrumbItem="Setting"
					/>
					<div className="container-fluid">
						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										<Row>
											<Col xl="12">
												<div className="table-rep-plugin">
													<div className="table-responsive">
														<MUIDataTable
															title={
																<div className="row">
																	<div className="col-auto h4">
																		Chain
																		Setting
																		&nbsp;
																		<HasAnyPermission
																			permission={[
																				"chain add",
																			]}
																		>
																			<button
																				onClick={() => {
																					handleAddEditModal();
																				}}
																				type="button"
																				className="btn btn-primary waves-effect waves-light"
																			>
																				<i className="bx bx-plus-medical font-size-16 align-middle"></i>
																			</button>
																		</HasAnyPermission>
																	</div>
																</div>
															}
															data={resultFormatter(
																chainList ?? []
															)}
															columns={columns()}
															options={{
																filter: false,
																print: false,
																download: true,
																responsive:
																	"standard",
																viewColumns: false,
																selectableRows:
																	"none",
																textLabels: {
																	body: {
																		noMatch:
																			noMatch,
																	},
																},
																expandableRowsOnClick: false,
																setTableProps:
																	() => ({
																		className:
																			"mb-0 table-bordered responsiveTable",
																		// className: "align-middle table table-bordered table-bordered mb-0",
																	}),
															}}
														/>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>

					<Modal
						isOpen={isOpenAddEditModal}
						toggle={handleAddEditModal}
						size="lg"
						centered={true}
					>
						<ModalHeader toggle={handleAddEditModal} tag="h4">
							{details?._id ? "Edit" : "Add"}
						</ModalHeader>
						<ModalBody>
							<fieldset
								disabled={!couldHaveAddUpdatePermission()}
							>
								<Form
									onSubmit={(e) => {
										e.preventDefault();
										validation.handleSubmit();
										return false;
									}}
								>
									<Row form={"true"}>
										<Col xs={12}>
											<div className="mb-3">
												<Label className="form-label">
													Chain ID
												</Label>
												<Input
													name="chainId"
													type="text"
													onChange={
														validation.handleChange
													}
													onBlur={
														validation.handleBlur
													}
													value={
														validation.values
															.chainId || ""
													}
													invalid={
														validation.touched
															.chainId &&
														validation.errors
															.chainId
															? true
															: false
													}
												/>
												{validation.touched.chainId &&
												validation.errors.chainId ? (
													<FormFeedback type="invalid">
														{
															validation.errors
																.chainId
														}
													</FormFeedback>
												) : null}
											</div>
										</Col>
									</Row>

									<Row form={"true"}>
										<Col xs={12}>
											<div className="mb-3">
												<Label className="form-label">
													Chain Name
												</Label>
												<Input
													name="chainName"
													type="text"
													onChange={
														validation.handleChange
													}
													onBlur={
														validation.handleBlur
													}
													value={
														validation.values
															.chainName || ""
													}
													invalid={
														validation.touched
															.chainName &&
														validation.errors
															.chainName
															? true
															: false
													}
												/>
												{validation.touched.chainName &&
												validation.errors.chainName ? (
													<FormFeedback type="invalid">
														{
															validation.errors
																.chainName
														}
													</FormFeedback>
												) : null}
											</div>
										</Col>
									</Row>

									<Row form={"true"}>
										<Col xs={12}>
											<div className="mb-3">
												<Label className="form-label">
													NFT Contract Address
												</Label>
												<Input
													name="nftContractAddress"
													type="text"
													onChange={
														validation.handleChange
													}
													onBlur={
														validation.handleBlur
													}
													value={
														validation.values
															.nftContractAddress ||
														""
													}
													invalid={
														validation.touched
															.nftContractAddress &&
														validation.errors
															.nftContractAddress
															? true
															: false
													}
												/>
												{validation.touched
													.nftContractAddress &&
												validation.errors
													.nftContractAddress ? (
													<FormFeedback type="invalid">
														{
															validation.errors
																.nftContractAddress
														}
													</FormFeedback>
												) : null}
											</div>
										</Col>
									</Row>

									<Row form={"true"}>
										<Col xs={12}>
											<div className="mb-3">
												<Label className="form-label">
													Scanner Link
												</Label>
												<Input
													name="scannerLink"
													type="text"
													onChange={
														validation.handleChange
													}
													onBlur={
														validation.handleBlur
													}
													value={
														validation.values
															.scannerLink || ""
													}
													invalid={
														validation.touched
															.scannerLink &&
														validation.errors
															.scannerLink
															? true
															: false
													}
												/>
												{validation.touched
													.scannerLink &&
												validation.errors
													.scannerLink ? (
													<FormFeedback type="invalid">
														{
															validation.errors
																.scannerLink
														}
													</FormFeedback>
												) : null}
											</div>
										</Col>
									</Row>

									<Row form={"true"}>
										<Col xs={12}>
											<div className="mb-3">
												<Label className="form-label">
													Node Url
												</Label>
												<Input
													name="nodeUrl"
													type="text"
													onChange={
														validation.handleChange
													}
													onBlur={
														validation.handleBlur
													}
													value={
														validation.values
															.nodeUrl || ""
													}
													invalid={
														validation.touched
															.nodeUrl &&
														validation.errors
															.nodeUrl
															? true
															: false
													}
												/>
												{validation.touched.nodeUrl &&
												validation.errors.nodeUrl ? (
													<FormFeedback type="invalid">
														{
															validation.errors
																.nodeUrl
														}
													</FormFeedback>
												) : null}
											</div>
										</Col>
									</Row>

									<Row form={"true"}>
										<Col xs={12}>
											<div className="mb-3">
												<Label className="form-label">
													Mint Wallet Address
												</Label>
												<Input
													name="mintWalletAddress"
													type="text"
													onChange={
														validation.handleChange
													}
													onBlur={
														validation.handleBlur
													}
													value={
														validation.values
															.mintWalletAddress ||
														""
													}
													invalid={
														validation.touched
															.mintWalletAddress &&
														validation.errors
															.mintWalletAddress
															? true
															: false
													}
												/>
												{validation.touched
													.mintWalletAddress &&
												validation.errors
													.mintWalletAddress ? (
													<FormFeedback type="invalid">
														{
															validation.errors
																.mintWalletAddress
														}
													</FormFeedback>
												) : null}
											</div>
										</Col>
									</Row>

									<Row>
										<Col>
											<div className="text-end mt-3">
												<button
													type="submit"
													className="btn btn-success save-user"
												>
													Save
												</button>
											</div>
										</Col>
									</Row>
								</Form>
							</fieldset>
						</ModalBody>
					</Modal>
				</Container>
			</div>
		</React.Fragment>
	);
};
export default ChainSetting;
