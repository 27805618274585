import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_PRODUCT_REQUEST,
	CREATE_PRODUCT_SUCCESS,
	CREATE_PRODUCT_FAILURE,
	UPDATE_PRODUCT_SUCCESS,
	UPDATE_PRODUCT_REQUEST,
	UPDATE_PRODUCT_FAILURE,
	REMOVE_PRODUCT_FAILURE,
	REMOVE_PRODUCT_SUCCESS,
	REMOVE_PRODUCT_REQUEST,
	TOGGLE_PRODUCT_SUCCESS,
	TOGGLE_PRODUCT_REQUEST,
	TOGGLE_PRODUCT_FAILURE,
	GET_PRODUCT_REQUEST,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAILURE,
	GET_PRODUCT_CATEGORY_SUCCESS,
	GET_PRODUCT_CATEGORY_REQUEST,
	GET_PRODUCT_CATEGORY_FAILURE,
	GET_PRODUCT_OPTION_SUCCESS,
	GET_PRODUCT_OPTION_REQUEST,
	GET_PRODUCT_OPTION_FAILURE,
} from "./actionTypes";

import {
	create,
	update,
	remove,
	toggle,
	getProduct,
	getCategories,
	getOptions,
} from "./services";

function* createProduct({ payload }) {
	try {
		const formData = new FormData();
		Object.entries(payload).forEach(([key, value]) => {
			if (key === "images") {
				value?.map((file) => {
					formData.append("images", file);
				});
			} else {
				formData.append(
					key,
					Array.isArray(value) || typeof value === "object"
						? JSON.stringify(value)
						: value
				);
			}
		});
		const response = yield call(create, { formData: formData });
		if (response.status === 200) {
			yield put({
				type: CREATE_PRODUCT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_PRODUCT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_PRODUCT_FAILURE,
			payload: response.data,
		});
	}
}

function* getProducts({ payload }) {
	try {
		const response = yield call(getProduct, payload);
		if (response.status === 200) {
			yield put({
				type: GET_PRODUCT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_PRODUCT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_PRODUCT_FAILURE,
			payload: response.data,
		});
	}
}

function* updateProduct({ payload }) {
	try {
		const formData = new FormData();
		Object.entries(payload).forEach(([key, value]) => {
			if (key === "images") {
				value?.map((file) => {
					formData.append("images", file);
				});
			} else {
				formData.append(
					key,
					Array.isArray(value) || typeof value === "object"
						? JSON.stringify(value)
						: value
				);
			}
		});
		const response = yield call(update, {
			formData: formData,
			id: payload.id,
		});
		if (response.status === 200) {
			yield put({
				type: UPDATE_PRODUCT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_PRODUCT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_PRODUCT_FAILURE,
			payload: {},
		});
	}
}

function* toggleProduct({ payload }) {
	try {
		const response = yield call(toggle, payload);
		if (response.status === 200) {
			yield put({
				type: TOGGLE_PRODUCT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: TOGGLE_PRODUCT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: TOGGLE_PRODUCT_FAILURE,
			payload: response.data,
		});
	}
}

function* removeProduct({ payload }) {
	try {
		const response = yield call(remove, payload);
		if (response.status === 200) {
			yield put({
				type: REMOVE_PRODUCT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_PRODUCT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_PRODUCT_FAILURE,
			payload: response.data,
		});
	}
}

function* getProductCategories({ payload }) {
	try {
		const response = yield call(getCategories, payload);
		if (response.status === 200) {
			yield put({
				type: GET_PRODUCT_CATEGORY_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_PRODUCT_CATEGORY_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_PRODUCT_CATEGORY_FAILURE,
			payload: response.data,
		});
	}
}

function* getProductOptions({ payload }) {
	try {
		const response = yield call(getOptions, payload);
		if (response.status === 200) {
			yield put({
				type: GET_PRODUCT_OPTION_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_PRODUCT_OPTION_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_PRODUCT_OPTION_FAILURE,
			payload: response.data,
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_PRODUCT_REQUEST, createProduct);
	yield takeEvery(UPDATE_PRODUCT_REQUEST, updateProduct);
	yield takeEvery(REMOVE_PRODUCT_REQUEST, removeProduct);
	yield takeEvery(TOGGLE_PRODUCT_REQUEST, toggleProduct);
	yield takeEvery(GET_PRODUCT_REQUEST, getProducts);
	yield takeEvery(GET_PRODUCT_CATEGORY_REQUEST, getProductCategories);
	yield takeEvery(GET_PRODUCT_OPTION_REQUEST, getProductOptions);
}

export default saga;
