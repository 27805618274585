import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_CUSTOMER_REQUEST,
	CREATE_CUSTOMER_SUCCESS,
	CREATE_CUSTOMER_FAILURE,
	UPDATE_CUSTOMER_SUCCESS,
	UPDATE_CUSTOMER_REQUEST,
	UPDATE_CUSTOMER_FAILURE,
	REMOVE_CUSTOMER_FAILURE,
	REMOVE_CUSTOMER_SUCCESS,
	REMOVE_CUSTOMER_REQUEST,
	TOGGLE_CUSTOMER_SUCCESS,
	TOGGLE_CUSTOMER_REQUEST,
	TOGGLE_CUSTOMER_FAILURE,
	GET_CUSTOMER_REQUEST,
	GET_CUSTOMER_SUCCESS,
	GET_CUSTOMER_FAILURE,
} from "./actionTypes";

import { create, update, remove, toggle, getCustomer } from "./services";

function* createCustomer({ payload }) {
	try {
		const response = yield call(create, payload);
		if (response.status === 200) {
			yield put({
				type: CREATE_CUSTOMER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_CUSTOMER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_CUSTOMER_FAILURE,
			payload: response.data,
		});
	}
}

function* getCustomers({ payload }) {
	try {
		const response = yield call(getCustomer, payload);
		if (response.status === 200) {
			yield put({
				type: GET_CUSTOMER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_CUSTOMER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_CUSTOMER_FAILURE,
			payload: response.data,
		});
	}
}

function* updateCustomer({ payload }) {
	try {
		const response = yield call(update, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_CUSTOMER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_CUSTOMER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_CUSTOMER_FAILURE,
			payload: {},
		});
	}
}

function* toggleCustomer({ payload }) {
	try {
		const response = yield call(toggle, payload);
		if (response.status === 200) {
			yield put({
				type: TOGGLE_CUSTOMER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: TOGGLE_CUSTOMER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: TOGGLE_CUSTOMER_FAILURE,
			payload: response.data,
		});
	}
}

function* removeCustomer({ payload }) {
	try {
		const response = yield call(remove, payload);
		if (response.status === 200) {
			yield put({
				type: REMOVE_CUSTOMER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_CUSTOMER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_CUSTOMER_FAILURE,
			payload: response.data,
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_CUSTOMER_REQUEST, createCustomer);
	yield takeEvery(UPDATE_CUSTOMER_REQUEST, updateCustomer);
	yield takeEvery(REMOVE_CUSTOMER_REQUEST, removeCustomer);
	yield takeEvery(TOGGLE_CUSTOMER_REQUEST, toggleCustomer);
	yield takeEvery(GET_CUSTOMER_REQUEST, getCustomers);
}

export default saga;
