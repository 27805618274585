export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_CLOUD_USAGE_REQUEST = "CREATE_CLOUD_USAGE_REQUEST";
export const CREATE_CLOUD_USAGE_SUCCESS = "CREATE_CLOUD_USAGE_SUCCESS";
export const CREATE_CLOUD_USAGE_FAILURE = "CREATE_CLOUD_USAGE_FAILURE";

export const UPDATE_CLOUD_USAGE_REQUEST = "UPDATE_CLOUD_USAGE_REQUEST";
export const UPDATE_CLOUD_USAGE_SUCCESS = "UPDATE_CLOUD_USAGE_SUCCESS";
export const UPDATE_CLOUD_USAGE_FAILURE = "UPDATE_CLOUD_USAGE_FAILURE";

export const REMOVE_CLOUD_USAGE_REQUEST = "REMOVE_CLOUD_USAGE_REQUEST";
export const REMOVE_CLOUD_USAGE_SUCCESS = "REMOVE_CLOUD_USAGE_SUCCESS";
export const REMOVE_CLOUD_USAGE_FAILURE = "REMOVE_CLOUD_USAGE_FAILURE";

export const TOGGLE_CLOUD_USAGE_SUCCESS = "TOGGLE_CLOUD_USAGE_SUCCESS";
export const TOGGLE_CLOUD_USAGE_REQUEST = "TOGGLE_CLOUD_USAGE_REQUEST";
export const TOGGLE_CLOUD_USAGE_FAILURE = "TOGGLE_CLOUD_USAGE_FAILURE";

export const GET_CLOUD_USAGE_SUCCESS = "GET_CLOUD_USAGE_SUCCESS";
export const GET_CLOUD_USAGE_REQUEST = "GET_CLOUD_USAGE_REQUEST";
export const GET_CLOUD_USAGE_FAILURE = "GET_CLOUD_USAGE_FAILURE";
