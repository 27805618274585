import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Row,
	Container,
	CardHeader,
	CardTitle,
	Table,
	Input,
	Label,
	Form,
	FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import isEmpty from "../../utils/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import {
	getLendingSetting,
	updateLendingSetting,
	clearResponse,
} from "../../store/lendingPool/actions";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const Setting = (props) => {
	const dispatch = useDispatch();
	const animatedComponents = makeAnimated();

	const { lendingPool, auth } = useSelector((state) => ({
		lendingPool: state.lendingPool,
		auth: state.auth,
	}));

	const { response } = lendingPool;

	useEffect(() => {
		dispatch(getLendingSetting());
	}, []);

	useEffect(() => {
		if (response && response.code === "200") {
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
					dispatch(getLendingSetting());
				},
			});
		} else if (response && response?.msg) {
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const validationSchema = Yup.object()
		.shape({
			type: Yup.object().required(),
		})
		.when((values, schema) => {
			let validationData = {};
			if (values.type?.value === "schedule") {
				validationData.timeInMinutes = Yup.number().required();
			}
			return schema.shape(validationData);
		});

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			type: lendingPool.setting?.type
				? {
						label:
							lendingPool.setting.type.charAt(0).toUpperCase() +
							lendingPool.setting.type.slice(1),
						value: lendingPool.setting.type,
				  }
				: "",
			timeInMinutes: lendingPool.setting?.timeInMinutes ?? 0,
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				Swal.fire({
					title: "Are you sure?",
					text: "Do you really want to update?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Confirm",
				}).then((result) => {
					if (result.value) {
						dispatch(
							updateLendingSetting({
								...values,
								type: values.type?.value,
							})
						);
					}
				});
			}
		},
	});

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Lending & staking SETTING | LFI</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs
						title="Reward setting                                              "
						breadcrumbItem="Setting"
					/>

					<Row>
						<Card className="border border-default">
							<CardHeader className="align-items-center d-flex bg-transparent border-default">
								<CardTitle className="mb-0 flex-grow-1">
									<h5 className="my-0 text-primary">
										<i className="mdi mdi-bullseye-arrow me-3"></i>
										Lending and staking setting
									</h5>
								</CardTitle>
							</CardHeader>
							<CardBody>
								<HasAnyPermission
									permission={[
										"LFI setting update",
										"LFI setting view",
									]}
								>
									<Row className="mt-3">
										<Col sm={3}>
											<h5>
												Lending approve request setting
											</h5>
										</Col>
										<Col>
											<div className="mb-1">
												<fieldset
													disabled={
														!hasPermission(
															[
																"LFI setting update",
															],
															auth.currentUserRolePermissions
														)
													}
												>
													<Form
														onSubmit={(e) => {
															e.preventDefault();
															validation.handleSubmit();
															return false;
														}}
														className="row gx-3 gy-2 align-items-center"
													>
														<Row>
															<Col sm={6}>
																<Label className="form-label m-1">
																	Approve Type
																</Label>
																<Select
																	className="m-1"
																	placeholder="Select reward approve type"
																	name="type"
																	value={
																		validation
																			.values
																			.type ||
																		""
																	}
																	onChange={(
																		value,
																		action
																	) => {
																		validation.setFieldValue(
																			"type",
																			value
																		);
																	}}
																	isMulti={
																		false
																	}
																	options={[
																		{
																			label: "Immediate",
																			value: "immediate",
																		},
																		{
																			label: "Schedule",
																			value: "schedule",
																		},
																	]}
																	classNamePrefix="select2-selection"
																	onSelectResetsInput={
																		true
																	}
																	closeMenuOnSelect={
																		true
																	}
																	components={
																		animatedComponents
																	}
																/>
																{validation
																	.touched
																	.type &&
																validation
																	.errors
																	.type ? (
																	<FormFeedback
																		className="error mr-1"
																		type="invalid"
																	>
																		{
																			validation
																				.errors
																				.type
																		}
																	</FormFeedback>
																) : null}
															</Col>
														</Row>

														{validation.values.type
															?.value ===
															"schedule" && (
															<Row>
																<Col sm={6}>
																	<Label className="form-label m-1">
																		Time in
																		minutes
																	</Label>
																	<Input
																		type="text"
																		name="timeInMinutes"
																		placeholder="Time in minutes"
																		className="form-control m-1"
																		onChange={
																			validation.handleChange
																		}
																		onBlur={
																			validation.handleBlur
																		}
																		value={
																			validation
																				.values
																				.timeInMinutes ||
																			""
																		}
																	/>
																	{validation
																		.touched
																		.timeInMinutes &&
																	validation
																		.errors
																		.timeInMinutes ? (
																		<FormFeedback
																			className="error ml-1"
																			type="invalid"
																		>
																			{
																				validation
																					.errors
																					.timeInMinutes
																			}
																		</FormFeedback>
																	) : null}
																</Col>
															</Row>
														)}
														<div className="col-auto">
															<button
																type="submit"
																className="btn btn-primary"
															>
																Update
															</button>
														</div>
													</Form>
												</fieldset>
											</div>
										</Col>
									</Row>
								</HasAnyPermission>
							</CardBody>
						</Card>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Setting;
