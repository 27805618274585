import React, {
	useEffect,
	useState,
	useCallback,
	useRef,
	useMemo,
} from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import CountUp from "react-countup";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import {
	getTotalOrder as dispatchedTotalOrder,
	getProduct,
} from "../../store/dashboard/actions";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "../../utils/isEmpty";

const TotalBTC = ({
	formatValue,
	getStringToDateRange,
	getTotalYear,
	setDateFilter,
	toggleResetFilter,
}) => {
	const dispatch = useDispatch();
	const { dashboard } = useSelector((state) => ({
		dashboard: state.dashboard,
	}));
	const { totalOrder, products } = dashboard;
	const [details, setDetails] = useState({
		filters: {
			date: [null, null],
			time: {
				years: "",
				months: "",
				days: "",
				hours: "",
				today: "",
				last: "",
			},
		},
		search: "",
		sort: "",
		sortColumn: "",
	});

	useEffect(() => {
		dispatch(getProduct());
	}, []);

	const getTotalOrder = () => {
		const filters = JSON.stringify(setDateFilter(details.filters));
		dispatch(
			dispatchedTotalOrder({
				...details,
				filters: filters,
			})
		);
	};

	useEffect(() => {
		getTotalOrder();
	}, [details, setDetails]);

	const onChangeFilter = (event) => {
		const filters = { ...details.filters };
		filters[event.target.name] = event.target.value;
		setDetails({
			...details,
			filters: filters,
		});
	};

	const onChangeDateFilter = (date) => {
		const filters = { ...details.filters };
		setDetails({
			...details,
			filters: {
				...toggleResetFilter(filters, "date"),
				date: date,
			},
		});
	};

	const selectTime = (event) => {
		const filters = { ...details.filters };
		setDetails({
			...details,
			filters: {
				...toggleResetFilter(filters, "time"),
				time: {
					years: "",
					months: "",
					days: "",
					hours: "",
					today: "",
					last:
						event.target.name === filters["time"]["last"]
							? ""
							: event.target.name,
					[event.target.name.includes("years")
						? "years"
						: event.target.name]:
						event.target.name !== filters["time"]["last"]
							? event.target.value
							: "",
				},
			},
		});
	};

	return (
		<React.Fragment>
			<Col xl={4}>
				<Card className="card-h-100">
					<CardBody>
						<Row className="">
							<h5 className="card-title me-2">
								Total Orders (Filters)
							</h5>
						</Row>
						<Row className="m-0 mt-4">
							<Col xl="12">
								<div className="">
									<ul
										role="tablist"
										className="nav-tabs-custom rounded card-header-tabs nav"
									>
										<li className="nav-item">
											<div className="ms-auto ms-auto m-1">
												<Flatpickr
													className="form-control form-control-sm d-block"
													placeholder="Date Range"
													options={{
														mode: "range",
														dateFormat: "m/d/Y",
														maxDate: new Date(),
													}}
													maxdate={new Date()}
													name="date"
													value={details.filters.date}
													onChange={
														onChangeDateFilter
													}
												/>
											</div>
										</li>

										<li className="nav-item">
											<div className="ms-auto ms-auto m-1">
												<button
													type="button"
													name="today"
													value={"today"}
													className={`btn ${
														details.filters.time
															.today
															? "btn-soft-primary"
															: "btn-soft-secondary"
													} btn-sm`}
													onClick={selectTime}
												>
													TODAY
												</button>
											</div>
										</li>

										<li className="nav-item">
											<div className="ms-auto ms-auto m-1">
												<button
													type="button"
													name="hours"
													value={24}
													className={`btn ${
														details.filters.time
															.hours == 24
															? "btn-soft-primary"
															: "btn-soft-secondary"
													} btn-sm`}
													onClick={selectTime}
												>
													LAST 24H
												</button>
											</div>
										</li>
										<li className="nav-item">
											<div className="ms-auto m-1">
												<button
													name="months"
													value="1"
													type="button"
													className={`btn ${
														details.filters.time
															.months
															? "btn-soft-primary"
															: "btn-soft-secondary"
													} btn-sm`}
													onClick={selectTime}
												>
													1M
												</button>
											</div>
										</li>

										{getTotalYear().map((year) => (
											<li key={year} className="nav-item">
												<div className="ms-auto m-1">
													<button
														key={year}
														name={`years-${year}`}
														value={year}
														type="button"
														className={`btn ${
															!isEmpty(
																details.filters
																	.time.years
															)
																? parseInt(
																		details
																			.filters
																			.time
																			.years
																  ) === year
																	? "btn-soft-primary"
																	: "btn-soft-secondary"
																: "btn-soft-secondary"
														} btn-sm`}
														onClick={selectTime}
													>
														{`${year}`}
													</button>
												</div>
											</li>
										))}

										<li className="nav-item">
											<div className="ms-auto m-1">
												<select
													name="status"
													className="form-select form-select-sm"
													value={
														details.filters.status
													}
													onChange={onChangeFilter}
												>
													<option
														key="select_status"
														value=""
													>
														Status
													</option>
													{[
														"pending",
														"processing",
													].map((item) => (
														<option
															key={item}
															value={item}
														>
															{item}
														</option>
													))}
												</select>
											</div>
										</li>
										<li className="nav-item">
											<div className="ms-auto m-1">
												<select
													name="payment_method"
													className="form-select form-select-sm"
													value={
														details.filters
															.payment_method
													}
													onChange={onChangeFilter}
												>
													<option
														key="select_payment_method"
														value=""
													>
														Payment Method
													</option>
													{[
														{
															name: "LYOMERCHANT (Crypto)",
															value: "LYOMERCHANT",
														},
														{
															name: "Stripe",
															value: "stripe",
														},
													].map((item) => (
														<option
															key={item.value}
															value={item.value}
														>
															{item.name}
														</option>
													))}
												</select>
											</div>
										</li>
										<li className="nav-item">
											<div className="ms-auto m-1">
												<select
													name="productId"
													className="form-select form-select-sm"
													value={
														details.filters
															.productId
													}
													onChange={onChangeFilter}
												>
													<option
														key="select_product"
														value=""
													>
														Select Product
													</option>
													{products.map((item) => (
														<option
															key={item.id}
															value={item.id}
														>
															{item.name}
														</option>
													))}
												</select>
											</div>
										</li>
									</ul>
								</div>
							</Col>
						</Row>

						<Row className="mt-5">
							<Col xl="12">
								<div className="flex-shrink-0">
									<h4 className="">
										{totalOrder?.isLoading ? (
											<i className="bx bx-loader bx-spin font-size-20 align-middle me-2"></i>
										) : (
											<CountUp
												className="account-balance"
												start={0}
												end={formatValue(
													totalOrder.details
												)}
												duration={0.75}
												separator=""
												suffix={` `}
												decimals={0}
											></CountUp>
										)}
									</h4>
								</div>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Col>
		</React.Fragment>
	);
};

export default TotalBTC;
