import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_STAKING_POOL_REQUEST,
	UPDATE_STAKING_POOL_REQUEST,
	REMOVE_STAKING_POOL_REQUEST,
	TOGGLE_STAKING_POOL_REQUEST,
	APPROVE_STAKING_POOL_REQUEST,
	GET_ALL_STAKING_POOL_SUCCESS,
	GET_ALL_STAKING_POOL_REQUEST,
	GET_ALL_STAKING_POOL_FAILURE,
	CANCEL_USER_STAKING_POOL_SUCCESS,
	CANCEL_USER_STAKING_POOL_REQUEST,
	CANCEL_USER_STAKING_POOL_FAILURE,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const create = (data) => {
	return {
		type: CREATE_STAKING_POOL_REQUEST,
		payload: data,
	};
};

export const update = (data) => {
	return {
		type: UPDATE_STAKING_POOL_REQUEST,
		payload: data,
	};
};

export const toggle = (data) => {
	return {
		type: TOGGLE_STAKING_POOL_REQUEST,
		payload: data,
	};
};

export const remove = (data) => {
	return {
		type: REMOVE_STAKING_POOL_REQUEST,
		payload: data,
	};
};

export const approve = (data) => {
	return {
		type: APPROVE_STAKING_POOL_REQUEST,
		payload: data,
	};
};

export const getAll = (data) => {
	return {
		type: GET_ALL_STAKING_POOL_REQUEST,
		payload: data,
	};
};

export const cancelUserStaking = (data) => {
	return {
		type: CANCEL_USER_STAKING_POOL_REQUEST,
		payload: data,
	};
};
