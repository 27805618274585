import {
	ERRORS,
	CLEAR_RESPONSE,
	CLEAR_NOTIFICATION,
	CREATE_ORDER_REQUEST,
	UPDATE_ORDER_REQUEST,
	REMOVE_ORDER_REQUEST,
	TOGGLE_ORDER_REQUEST,
	GET_ORDER_REQUEST,
	GET_ORDER_SUCCESS,
	GET_ORDER_FAILURE,
	GET_ORDER_WITH_ID_REQUEST,
	GET_ORDER_WITH_ID_SUCCESS,
	GET_ORDER_WITH_ID_FAILURE,
	GET_MAC_ADDRESS_REQUEST,
	GET_MAC_ADDRESS_SUCCESS,
	GET_MAC_ADDRESS_FAILURE,
	GET_INVOICE_REQUEST,
	GET_INVOICE_SUCCESS,
	GET_INVOICE_FAILURE,
	CLEAR_INVOICE_RESPONSE,
	GET_UPGRADE_PRODUCT_REQUEST,
	GET_UPGRADE_PRODUCT_SUCCESS,
	GET_UPGRADE_PRODUCT_FAILURE,
	GET_UPGRADE_ORDER_PRODUCT_REQUEST,
	GET_UPGRADE_ORDER_PRODUCT_SUCCESS,
	GET_UPGRADE_ORDER_PRODUCT_FAILURE,
	EXPORT_ORDER_REQUEST,
	EXPORT_ORDER_SUCCESS,
	EXPORT_ORDER_FAILURE,
	GET_ORDER_MERCHANT_INVOICE_REQUEST,
	GET_ORDER_MERCHANT_INVOICE_SUCCESS,
	GET_ORDER_MERCHANT_INVOICE_FAILURE,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};
export const clearNotification = () => {
	return {
		type: CLEAR_NOTIFICATION,
		payload: {},
	};
};

export const clearInvoice = () => {
	return {
		type: CLEAR_INVOICE_RESPONSE,
		payload: {},
	};
};

export const getOrders = (data) => {
	return {
		type: GET_ORDER_REQUEST,
		payload: data,
	};
};

export const getInvoice = (data) => {
	return {
		type: GET_INVOICE_REQUEST,
		payload: data,
	};
};

export const getOrder = (data) => {
	return {
		type: GET_ORDER_WITH_ID_REQUEST,
		payload: data,
	};
};

export const getUpgradeProduct = (data) => {
	return {
		type: GET_UPGRADE_PRODUCT_REQUEST,
		payload: data,
	};
};

export const upgradeOrderProduct = (data) => {
	return {
		type: GET_UPGRADE_ORDER_PRODUCT_REQUEST,
		payload: data,
	};
};

export const getExportOrderCSV = (data) => {
	return {
		type: EXPORT_ORDER_REQUEST,
		payload: data,
	};
};

export const getOrderMerchantInvoice = (data) => {
	return {
		type: GET_ORDER_MERCHANT_INVOICE_REQUEST,
		payload: data,
	};
};

export const create = (data) => {
	return {
		type: CREATE_ORDER_REQUEST,
		payload: data,
	};
};

export const update = (data) => {
	return {
		type: UPDATE_ORDER_REQUEST,
		payload: data,
	};
};

export const toggle = (data) => {
	return {
		type: TOGGLE_ORDER_REQUEST,
		payload: data,
	};
};

export const remove = (data) => {
	return {
		type: REMOVE_ORDER_REQUEST,
		payload: data,
	};
};

export const getMacAddress = (data) => {
	return {
		type: GET_MAC_ADDRESS_REQUEST,
		payload: data,
	};
};
