import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Row,
	Container,
	CardHeader,
	CardTitle,
	Table,
	Input,
	Label,
	Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import isEmpty from "../../utils/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import { getMeta, update, clearResponse } from "../../store/meta/actions";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";

const Setting = (props) => {
	const dispatch = useDispatch();
	const { metaDetails, auth } = useSelector((state) => ({
		metaDetails: state.meta,
		auth: state.auth,
	}));

	const { response } = metaDetails;

	useEffect(() => {
		dispatch(getMeta());
	}, []);

	useEffect(() => {
		if (response && response.code === "200") {
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
					dispatch(getMeta());
				},
			});
		} else if (response && response?.msg) {
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const validateMaxBurn = useFormik({
		enableReinitialize: true,
		initialValues: {
			maxMintBurn: metaDetails?.details?.maxMintBurn ?? 0,
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				Swal.fire({
					title: "Are you sure?",
					text: "Do you really want to update?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Confirm",
				}).then((result) => {
					if (result.value) {
						dispatch(
							update({
								name: Object.keys(values)?.[0],
								value: Object.values(values)?.[0],
							})
						);
					}
				});
			}
		},
	});
	const validateMinMint = useFormik({
		enableReinitialize: true,
		initialValues: {
			minMintBurn: metaDetails?.details?.minMintBurn ?? 0,
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				Swal.fire({
					title: "Are you sure?",
					text: "Do you really want to update?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Confirm",
				}).then((result) => {
					if (result.value) {
						dispatch(
							update({
								name: Object.keys(values)?.[0],
								value: Object.values(values)?.[0],
							})
						);
					}
				});
			}
		},
	});

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>NFT MINT SETTING | LFI</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Meta Data" breadcrumbItem="Setting" />

					<Row>
						<Card className="border border-default">
							<CardHeader className="align-items-center d-flex bg-transparent border-default">
								<CardTitle className="mb-0 flex-grow-1">
									<h5 className="my-0 text-primary">
										<i className="mdi mdi-bullseye-arrow me-3"></i>
										MINT AND BURN SETTING
									</h5>
								</CardTitle>
								{/* <div className="flex-shrink-0">
									<div className="d-flex flex-wrap gap-2 mb-0 my-n1">
										<button
											type="button"
											className="btn btn-primary waves-effect waves-light"
											// onClick={() =>
											// 	this.openModal(
											// 		"isTransferModalOpen",
											// 		"deposit"
											// 	)
											// }
										>
											Transfer Balance
										</button>
									</div>
								</div> */}
							</CardHeader>
							<CardBody>
								<HasAnyPermission
									permission={[
										"LFI setting update",
										"LFI setting view",
									]}
								>
									<Row className="mt-3">
										<Col sm={3}>
											<h5>Maximum Mint Burn</h5>
										</Col>
										<Col>
											<div className="mb-1">
												<fieldset
													disabled={
														!hasPermission(
															[
																"LFI setting update",
															],
															auth.currentUserRolePermissions
														)
													}
												>
													<Form
														onSubmit={(e) => {
															e.preventDefault();
															validateMaxBurn.handleSubmit();
															return false;
														}}
														className="row gx-3 gy-2 align-items-center"
													>
														<Col sm={3}>
															<Input
																type="text"
																name="maxMintBurn"
																className="form-control"
																onChange={
																	validateMaxBurn.handleChange
																}
																onBlur={
																	validateMaxBurn.handleBlur
																}
																value={
																	validateMaxBurn
																		.values
																		.maxMintBurn ||
																	""
																}
															/>
														</Col>
														<div className="col-auto">
															<button
																type="submit"
																className="btn btn-primary"
															>
																Update
															</button>
														</div>
													</Form>
												</fieldset>
											</div>
										</Col>
									</Row>

									<Row className="mt-3">
										<Col sm={3}>
											<h5>Minimum Mint Burn</h5>
										</Col>
										<Col>
											<div className="mb-1">
												<fieldset
													disabled={
														!hasPermission(
															[
																"LFI setting update",
															],
															auth.currentUserRolePermissions
														)
													}
												>
													<Form
														onSubmit={(e) => {
															e.preventDefault();
															validateMinMint.handleSubmit();
															return false;
														}}
														className="row gx-3 gy-2 align-items-center"
													>
														<Col sm={3}>
															<Input
																type="text"
																name="minMintBurn"
																className="form-control"
																onChange={
																	validateMinMint.handleChange
																}
																onBlur={
																	validateMinMint.handleBlur
																}
																value={
																	validateMinMint
																		.values
																		.minMintBurn ||
																	""
																}
															/>
														</Col>
														<div className="col-auto">
															<button
																type="submit"
																className="btn btn-primary"
															>
																Update
															</button>
														</div>
													</Form>
												</fieldset>
											</div>
										</Col>
									</Row>
								</HasAnyPermission>
							</CardBody>
						</Card>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Setting;
