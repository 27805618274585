import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Dropzone from "react-dropzone";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import {
	create,
	update,
	remove,
	toggle,
	clearResponse,
} from "../../store/nft/actions";
import Swal from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

const SUPPORTED_FORMATS = ["image/svg+xml"];
let editor;

const NFTSvg = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { response, auth } = useSelector((state) => ({
		response: state.nft.response,
		auth: state.auth,
	}));

	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [details, handleformData] = useState({
		users: {
			filters: {
				country: "",
				type: "",
				date: [null, null],
				time: {
					years: "",
					months: "",
					days: "",
					hours: "",
					last: "",
				},
			},
			search: "",
			sort: "",
			sortColumn: "",
		},
	});

	useEffect(() => {
		if (response && response.code === "200") {
			refreshTableData.current();
			if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		} else if (response && response?.msg) {
			refreshTableData.current();
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const selectedFile = (selectedFiles) => {
		if (isEmpty(selectedFiles)) {
			validation.setFieldValue("image", null);
			return false;
		} else {
			let droppedFile = {};
			selectedFiles.map((file) => {
				droppedFile = file;
				return true;
			});
			validation.setFieldValue("image", droppedFile);
			handleformData({ ...details, image: droppedFile });
		}
	};

	const onEditorChange = (data) => {
		validation.setFieldValue("details", data);
	};

	const removeItem = (id) => {
		if (!isEmpty(id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to delete?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					dispatch(remove(id));
				}
			});
		}
	};

	const toggleItem = (id) => {
		if (!isEmpty(id)) {
			dispatch(toggle(id));
		}
	};

	let validationSchema = {
		type: Yup.string().required("Please enter type."),
		details: Yup.string().required("Please enter details."),
		// information: Yup.string().required("Please enter Information."),
		image: Yup.mixed()
			.required("SVG File is required")
			.test(
				"fileFormat",
				"Unsupported Format",
				(value) => value && SUPPORTED_FORMATS.includes(value.type)
			),
	};

	if (details._id) {
		delete validationSchema.image;
	}

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			type: details && details.type ? details.type : "",
			details: details && details.details ? details.details : "",
			information:
				details && details.information ? details.information : "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => {
			const { file, ...restValue } = values;
			if (details._id) {
				dispatch(update({ ...restValue, _id: details._id }));
			} else {
				dispatch(create(values));
			}
		},
	};

	const validation = useFormik(useFormikOptions);
	const handleAddEditModal = (data = null) => {
		if (!isEmpty(data) && data?._id) {
			handleformData(data);
		} else {
			handleformData({
				type: "",
				details: "",
				information: "",
			});
			validation.resetForm();
		}
		toggleAddEditModal(!isOpenAddEditModal);
	};

	const columns = () => [
		{
			label: "Image",
			name: "file",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData.link ? (
						<img
							alt=""
							style={{ height: 35, width: 35 }}
							src={rowData?.link}
						/>
					) : (
						"SVG Not Available"
					);
				},
			},
		},

		{
			label: "Type",
			name: "type",
			options: {
				filter: true,
				sort: false,
			},
		},
		{
			label: "Description",
			name: "details",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (rowData) => (
					<div dangerouslySetInnerHTML={{ __html: rowData }} />
				),
			},
		},

		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: hasPermission(
					["NFT svg update", "NFT svg delete", "NFT svg view"],
					auth.currentUserRolePermissions
				),
				viewColumns: hasPermission(
					["NFT svg update", "NFT svg delete", "NFT svg view"],
					auth.currentUserRolePermissions
				),
				customBodyRender: (data) => {
					return (
						<div className="text-center">
							<HasAnyPermission
								permission={["NFT svg update", "NFT svg view"]}
							>
								<button
									onClick={(e) => handleAddEditModal(data)}
									type="button"
									className="btn btn-soft-primary waves-effect waves-light"
								>
									<i className="bx bx-edit-alt font-size-16 align-middle"></i>
								</button>
							</HasAnyPermission>
							&nbsp;
							<HasAnyPermission permission={["NFT svg delete"]}>
								<button
									onClick={(e) => removeItem(data._id)}
									type="button"
									className="btn btn-soft-danger waves-effect waves-light"
								>
									<i className="bx bx-trash font-size-16 align-middle"></i>
								</button>
							</HasAnyPermission>
						</div>
					);
				},
			},
		},
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				action: item,
			};
		});
	};

	const couldHaveAddUpdatePermission = () => {
		const isUpdatePermission = hasPermission(
			["NFT svg update"],
			auth.currentUserRolePermissions
		);
		const isAddPermission = hasPermission(
			["NFT svg add"],
			auth.currentUserRolePermissions
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details._id)) return true;
		else if (isAddPermission && isEmpty(details._id)) return true;
		else return false;
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>NFT SVG | LFI</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="NFT SVG" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"NFT svg list",
														]}
													>
														<ReactDataTable
															url={`${apiUrl}/admin/nft-details/pagination`}
															columns={columns()}
															resultFormatter={
																resultFormatter
															}
															setRefresh={
																refreshTableData
															}
															disableFilterIcon={
																false
															}
															disableSearchIcon={
																false
															}
															origin={
																<div className="row">
																	<div className="col-auto h4">
																		NFT SVG
																		&nbsp;
																		<HasAnyPermission
																			permission={[
																				"NFT svg add",
																			]}
																		>
																			<button
																				onClick={() => {
																					handleAddEditModal();
																				}}
																				type="button"
																				className="btn btn-primary waves-effect waves-light"
																			>
																				<i className="bx bx-plus-medical font-size-16 align-middle"></i>
																			</button>
																		</HasAnyPermission>
																	</div>
																</div>
															}
															rowsPerPage={10}
														/>
													</HasAnyPermission>

													<Modal
														isOpen={
															isOpenAddEditModal
														}
														toggle={
															handleAddEditModal
														}
														size="lg"
														centered={true}
													>
														<ModalHeader
															toggle={
																handleAddEditModal
															}
															tag="h4"
														>
															{details?._id
																? "Edit NFTSvg"
																: "Add NFTSvg"}
														</ModalHeader>
														<ModalBody>
															<fieldset
																disabled={
																	!couldHaveAddUpdatePermission()
																}
															>
																<Form
																	onSubmit={(
																		e
																	) => {
																		e.preventDefault();
																		validation.handleSubmit();
																		return false;
																	}}
																>
																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Type
																				</Label>
																				<Input
																					name="type"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.type ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.type &&
																						validation
																							.errors
																							.type
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.type &&
																				validation
																					.errors
																					.type ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.type
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>

																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<label
																					htmlFor="basicpill-firstname-input"
																					className="form-label"
																				>
																					Details
																				</label>
																				<CKEditor
																					editor={
																						DecoupledEditor
																					}
																					config={{
																						ckfinder:
																							{
																								uploadUrl: `${apiUrl}/launchpad/save_image`,
																								link: {
																									decorators:
																										{
																											addTargetToExternalLinks:
																												{
																													mode: "automatic",
																													callback:
																														(
																															url
																														) =>
																															/^(https?:)?\/\//.test(
																																url
																															),
																													attributes:
																														{
																															target: "_blank",
																															rel: "noopener noreferrer",
																														},
																												},
																										},
																								},
																							},
																						// plugins: [ Link ],
																					}}
																					data={
																						validation
																							.values
																							.details
																					}
																					onReady={(
																						editor
																					) => {
																						editor.ui
																							.getEditableElement()
																							.parentElement.insertBefore(
																								editor
																									.ui
																									.view
																									.toolbar
																									.element,
																								editor.ui.getEditableElement()
																							);
																						editor =
																							editor;
																					}}
																					onChange={(
																						event,
																						editor
																					) => {
																						const data =
																							editor.getData();
																						onEditorChange(
																							data
																						);
																					}}
																				/>
																			</div>
																		</Col>
																		<Col
																			xs={
																				12
																			}
																		>
																			<Dropzone
																				onDrop={(
																					acceptedFiles
																				) => {
																					selectedFile(
																						acceptedFiles
																					);
																				}}
																				// validator={
																				// 	nameLengthValidator
																				// }
																				accept="image/svg+xml"
																				maxFiles={
																					1
																				}
																			>
																				{({
																					getRootProps,
																					getInputProps,
																				}) => (
																					<div className="dropzone">
																						{
																							<div
																								className="dz-message needsclick mt-2"
																								{...getRootProps()}
																							>
																								<input
																									name="image"
																									{...getInputProps()}
																								/>
																								<div className="mb-3">
																									<i className="display-4 text-muted bx bxs-cloud-upload" />
																								</div>

																								{validation
																									.errors
																									.image &&
																								isEmpty(
																									details.file
																								) ? (
																									<h4>
																										{
																											validation
																												.errors
																												.image
																										}
																									</h4>
																								) : details
																										.image
																										?.path ? (
																									<div className="dropzon-input">
																										<div className="imgBox">
																											<img
																												width="200px"
																												src={
																													details?.image
																														? URL.createObjectURL(
																																details.image
																														  )
																														: details
																																?.file
																																.path
																												}
																												alt={
																													details
																														.image
																														?.path
																												}
																											/>
																										</div>
																										{
																											details
																												.image
																												?.path
																										}
																									</div>
																								) : details
																										.file
																										?.link ? (
																									<div className="dropzon-input">
																										<div className="imgBox">
																											<img
																												width="200px"
																												src={
																													details
																														.file
																														.link
																												}
																												alt={
																													details
																														.file
																														.link
																												}
																											/>
																										</div>
																										{details.file.link.slice(
																											details.file.link.lastIndexOf(
																												"/"
																											) +
																												1
																										)}
																									</div>
																								) : (
																									<h4>
																										Drop
																										files
																										here
																										or
																										click
																										to
																										upload.
																									</h4>
																								)}
																							</div>
																						}
																					</div>
																				)}
																			</Dropzone>
																		</Col>
																	</Row>
																	<Row>
																		<Col>
																			<div className="text-end mt-3">
																				<button
																					type="submit"
																					className="btn btn-success save-user"
																				>
																					Save
																				</button>
																			</div>
																		</Col>
																	</Row>
																</Form>
															</fieldset>
														</ModalBody>
													</Modal>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default NFTSvg;
