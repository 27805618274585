export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_SOFTWARE_PACKAGE_REQUEST =
	"CREATE_SOFTWARE_PACKAGE_REQUEST";
export const CREATE_SOFTWARE_PACKAGE_SUCCESS =
	"CREATE_SOFTWARE_PACKAGE_SUCCESS";
export const CREATE_SOFTWARE_PACKAGE_FAILURE =
	"CREATE_SOFTWARE_PACKAGE_FAILURE";

export const UPDATE_SOFTWARE_PACKAGE_REQUEST =
	"UPDATE_SOFTWARE_PACKAGE_REQUEST";
export const UPDATE_SOFTWARE_PACKAGE_SUCCESS =
	"UPDATE_SOFTWARE_PACKAGE_SUCCESS";
export const UPDATE_SOFTWARE_PACKAGE_FAILURE =
	"UPDATE_SOFTWARE_PACKAGE_FAILURE";

export const REMOVE_SOFTWARE_PACKAGE_REQUEST =
	"REMOVE_SOFTWARE_PACKAGE_REQUEST";
export const REMOVE_SOFTWARE_PACKAGE_SUCCESS =
	"REMOVE_SOFTWARE_PACKAGE_SUCCESS";
export const REMOVE_SOFTWARE_PACKAGE_FAILURE =
	"REMOVE_SOFTWARE_PACKAGE_FAILURE";

export const TOGGLE_SOFTWARE_PACKAGE_SUCCESS =
	"TOGGLE_SOFTWARE_PACKAGE_SUCCESS";
export const TOGGLE_SOFTWARE_PACKAGE_REQUEST =
	"TOGGLE_SOFTWARE_PACKAGE_REQUEST";
export const TOGGLE_SOFTWARE_PACKAGE_FAILURE =
	"TOGGLE_SOFTWARE_PACKAGE_FAILURE";
