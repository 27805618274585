import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_CHAIN_SETTING_REQUEST,
	UPDATE_CHAIN_SETTING_REQUEST,
	REMOVE_CHAIN_SETTING_REQUEST,
	TOGGLE_CHAIN_SETTING_REQUEST,
	GET_CHAIN_SETTING_REQUEST,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const create = (data) => {
	return {
		type: CREATE_CHAIN_SETTING_REQUEST,
		payload: data,
	};
};

export const getChain = (data) => {
	return {
		type: GET_CHAIN_SETTING_REQUEST,
		payload: data,
	};
};

export const update = (data) => {
	return {
		type: UPDATE_CHAIN_SETTING_REQUEST,
		payload: data,
	};
};

export const toggle = (data) => {
	return {
		type: TOGGLE_CHAIN_SETTING_REQUEST,
		payload: data,
	};
};

export const remove = (data) => {
	return {
		type: REMOVE_CHAIN_SETTING_REQUEST,
		payload: data,
	};
};
