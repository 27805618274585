import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_TAX_SUCCESS,
	CREATE_TAX_FAILURE,
	UPDATE_TAX_SUCCESS,
	UPDATE_TAX_FAILURE,
	REMOVE_TAX_FAILURE,
	REMOVE_TAX_SUCCESS,
	TOGGLE_TAX_SUCCESS,
	TOGGLE_TAX_FAILURE,
	GET_TAX_SUCCESS,
	GET_TAX_REQUEST,
	GET_TAX_FAILURE,
	GET_COUNTRY_TAX_SUCCESS,
	GET_COUNTRY_TAX_REQUEST,
	GET_COUNTRY_TAX_FAILURE,
	UPDATE_COUNTRY_TAX_REQUEST,
	UPDATE_COUNTRY_TAX_SUCCESS,
	UPDATE_COUNTRY_TAX_FAILURE,
	DELETE_COUNTRY_TAX_SUCCESS,
	DELETE_COUNTRY_TAX_REQUEST,
	DELETE_COUNTRY_TAX_FAILURE,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	countryTaxes: [],
	taxes: [],
	loader: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				loading: false,
			};
		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};
		case CREATE_TAX_SUCCESS:
		case CREATE_TAX_FAILURE:
		case UPDATE_TAX_SUCCESS:
		case UPDATE_TAX_FAILURE:
		case REMOVE_TAX_FAILURE:
		case REMOVE_TAX_SUCCESS:
		case TOGGLE_TAX_SUCCESS:
		case TOGGLE_TAX_FAILURE:
		case UPDATE_COUNTRY_TAX_SUCCESS:
		case UPDATE_COUNTRY_TAX_FAILURE:
		case DELETE_COUNTRY_TAX_SUCCESS:
		case DELETE_COUNTRY_TAX_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: {},
			};

		case GET_TAX_SUCCESS:
			return {
				...state,
				taxes: action.payload?.data,
			};
		case GET_TAX_FAILURE:
			return {
				...state,
				taxes: [],
				errors: action.payload,
			};
		case GET_COUNTRY_TAX_SUCCESS:
			return {
				...state,
				countryTaxes: action.payload?.data,
			};
		case GET_COUNTRY_TAX_FAILURE:
			return {
				...state,
				countryTaxes: [],
				errors: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
