import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_SOFTWARE_PACKAGE_REQUEST,
	UPDATE_SOFTWARE_PACKAGE_REQUEST,
	REMOVE_SOFTWARE_PACKAGE_REQUEST,
	TOGGLE_SOFTWARE_PACKAGE_REQUEST,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const create = (data) => {
	return {
		type: CREATE_SOFTWARE_PACKAGE_REQUEST,
		payload: data,
	};
};

export const update = (data) => {
	return {
		type: UPDATE_SOFTWARE_PACKAGE_REQUEST,
		payload: data,
	};
};

export const toggle = (data) => {
	return {
		type: TOGGLE_SOFTWARE_PACKAGE_REQUEST,
		payload: data,
	};
};

export const remove = (data) => {
	return {
		type: REMOVE_SOFTWARE_PACKAGE_REQUEST,
		payload: data,
	};
};
