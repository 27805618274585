import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_PRODUCT_REQUEST,
	UPDATE_PRODUCT_REQUEST,
	REMOVE_PRODUCT_REQUEST,
	TOGGLE_PRODUCT_REQUEST,
	GET_PRODUCT_REQUEST,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAILURE,
	GET_PRODUCT_CATEGORY_SUCCESS,
	GET_PRODUCT_CATEGORY_REQUEST,
	GET_PRODUCT_CATEGORY_FAILURE,
	GET_PRODUCT_OPTION_SUCCESS,
	GET_PRODUCT_OPTION_REQUEST,
	GET_PRODUCT_OPTION_FAILURE,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const getProducts = (data) => {
	return {
		type: GET_PRODUCT_REQUEST,
		payload: data,
	};
};

export const create = (data) => {
	return {
		type: CREATE_PRODUCT_REQUEST,
		payload: data,
	};
};

export const update = (data) => {
	return {
		type: UPDATE_PRODUCT_REQUEST,
		payload: data,
	};
};

export const toggle = (data) => {
	return {
		type: TOGGLE_PRODUCT_REQUEST,
		payload: data,
	};
};

export const remove = (data) => {
	return {
		type: REMOVE_PRODUCT_REQUEST,
		payload: data,
	};
};

export const getProductCategory = (data) => {
	return {
		type: GET_PRODUCT_CATEGORY_REQUEST,
		payload: data,
	};
};

export const getProductOption = (data) => {
	return {
		type: GET_PRODUCT_OPTION_REQUEST,
		payload: data,
	};
};
