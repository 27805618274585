import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import {
	create,
	update,
	remove,
	clearResponse,
} from "../../store/lendingPool/actions";
import Swal from "sweetalert2";
import { CopyToClipboard } from "react-copy-to-clipboard";

const LendingPool = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { response, auth } = useSelector((state) => ({
		response: state.lendingPool.response,
		auth: state.auth,
	}));

	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [apiKeyDetails, setApiKeyDetails] = useState({
		details: {},
		isModelOpen: false,
	});

	const handleTooltipOpen = () => {
		toast.success("Copied to clipboard.");
	};

	const [details, handleformData] = useState({
		users: {
			filters: {
				country: "",
				type: "",
				date: [null, null],
				time: {
					years: "",
					months: "",
					days: "",
					hours: "",
					last: "",
				},
			},
			search: "",
			sort: "",
			sortColumn: "",
		},
	});

	useEffect(() => {
		if (response && response.status === 200) {
			refreshTableData.current();
			Swal.close();
			if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		} else if (response && response?.msg) {
			refreshTableData.current();
			Swal.close();
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const removeItem = (data) => {
		if (!isEmpty(data?._id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to delete?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					dispatch(remove(data));
				}
			});
		}
	};

	let validationSchema = {
		name: Yup.string().required("Please enter type."),
		details: Yup.string(),
		apr: Yup.number().required("Please enter annual percentage rate."),
		volume: Yup.number().required("Please enter volume."),
		capacity: Yup.number().required("Please enter capacity."),
		commission: Yup.number(),
		type: Yup.string(),
	};

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			name: details && details.name ? details.name : "",
			details: details && details.details ? details.details : "",
			apr: details && details.apr ? details.apr : "",
			volume: details && details.volume ? details.volume : "",
			capacity: details && details.capacity ? details.capacity : "",
			commission:
				details && details.commissions?.commission
					? details.commissions.commission
					: "",
			type:
				details && details.commissions?.type
					? details.commissions.type
					: "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => {
			const poolDetails = {
				...values,
				commissions: {
					commission: values.commission,
					type: values.type,
				},
			};
			if (details._id) {
				dispatch(update({ ...poolDetails, _id: details._id }));
			} else {
				dispatch(create(poolDetails));
			}
		},
	};

	const validation = useFormik(useFormikOptions);

	const handleAddEditModal = (data = null) => {
		if (!isEmpty(data) && data?._id) {
			handleformData(data);
		} else {
			handleformData({});
			validation.resetForm();
		}
		toggleAddEditModal(!isOpenAddEditModal);
	};

	const columns = () => [
		{
			label: "Name",
			name: "name",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Balance",
			name: "balance",
			options: {
				filter: false,
				sort: false,
			},
		},
		// {
		// 	label: "Volume",
		// 	name: "volume",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 	},
		// },
		{
			label: "Annual per rate",
			name: "apr",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Capacity",
			name: "capacity",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Commission Type",
			name: "type",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Commission",
			name: "commission",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: hasPermission(
					["partner update", "partner delete", "partner view"],
					auth.currentUserRolePermissions
				),
				viewColumns: hasPermission(
					["partner update", "partner delete", "partner view"],
					auth.currentUserRolePermissions
				),
				customBodyRender: (data) => {
					return (
						<div className="text-center">
							<HasAnyPermission
								permission={["partner update", "partner view"]}
							>
								<button
									onClick={(e) => handleAddEditModal(data)}
									type="button"
									className="btn btn-soft-primary waves-effect waves-light"
								>
									<i className="bx bx-edit-alt font-size-16 align-middle"></i>
								</button>
							</HasAnyPermission>
							&nbsp;
							<HasAnyPermission permission={["partner delete"]}>
								<button
									onClick={(e) => removeItem(data)}
									type="button"
									className="btn btn-soft-danger waves-effect waves-light"
								>
									<i className="bx bx-trash font-size-16 align-middle"></i>
								</button>
							</HasAnyPermission>
						</div>
					);
				},
			},
		},
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				action: item,
				commission: item?.commissions?.commission
					? item.commissions.commission.toFixed(2)
					: "-",
				volume: item?.volume ? item.volume.toFixed(2) : "-",
				capacity: item?.capacity ? item.capacity.toFixed(2) : "-",
				type: item?.commissions?.type,
			};
		});
	};

	const couldHaveAddUpdatePermission = () => {
		const isUpdatePermission = hasPermission(
			["partner update"],
			auth.currentUserRolePermissions
		);
		const isAddPermission = hasPermission(
			["partner add"],
			auth.currentUserRolePermissions
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details._id)) return true;
		else if (isAddPermission && isEmpty(details._id)) return true;
		else return false;
	};

	const handleAPIkeyDetail = (isModalOpen, details) => {
		setApiKeyDetails({
			isModalOpen: isModalOpen,
			details: details,
		});
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>LendingPool | LFI</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="LendingPool" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"partner list",
														]}
													>
														<ReactDataTable
															url={`${apiUrl}/admin/lending/pool/pagination`}
															columns={columns()}
															resultFormatter={
																resultFormatter
															}
															setRefresh={
																refreshTableData
															}
															disableFilterIcon={
																false
															}
															disableSearchIcon={
																false
															}
															origin={
																<div className="row">
																	<div className="col-auto h4">
																		Lending
																		Pool
																		&nbsp;
																		<HasAnyPermission
																			permission={[
																				"partner add",
																			]}
																		>
																			<button
																				onClick={() => {
																					handleAddEditModal();
																				}}
																				type="button"
																				className="btn btn-primary waves-effect waves-light"
																			>
																				<i className="bx bx-plus-medical font-size-16 align-middle"></i>
																			</button>
																		</HasAnyPermission>
																	</div>
																</div>
															}
															rowsPerPage={10}
														/>
													</HasAnyPermission>

													<Modal
														isOpen={
															isOpenAddEditModal
														}
														toggle={
															handleAddEditModal
														}
														size="lg"
														centered={true}
													>
														<ModalHeader
															toggle={
																handleAddEditModal
															}
															tag="h4"
														>
															{details?._id
																? "Edit LendingPool"
																: "Add LendingPool"}
														</ModalHeader>
														<ModalBody>
															<fieldset
																disabled={
																	!couldHaveAddUpdatePermission()
																}
															>
																<Form
																	onSubmit={(
																		e
																	) => {
																		e.preventDefault();
																		validation.handleSubmit();
																		return false;
																	}}
																>
																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Name
																				</Label>
																				<Input
																					name="name"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.name ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.name &&
																						validation
																							.errors
																							.name
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.name &&
																				validation
																					.errors
																					.name ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.name
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>
																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Annual
																					percentage
																					rate
																				</Label>
																				<Input
																					name="apr"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.apr ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.apr &&
																						validation
																							.errors
																							.apr
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.apr &&
																				validation
																					.errors
																					.apr ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.apr
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>

																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Volume
																				</Label>
																				<Input
																					name="volume"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.volume ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.volume &&
																						validation
																							.errors
																							.volume
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.volume &&
																				validation
																					.errors
																					.volume ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.volume
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>

																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Capacity
																				</Label>
																				<Input
																					name="capacity"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.capacity ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.capacity &&
																						validation
																							.errors
																							.capacity
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.capacity &&
																				validation
																					.errors
																					.capacity ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.capacity
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>
																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Commission
																					Type
																				</Label>
																				<Input
																					name="type"
																					type="select"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.type ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.type &&
																						validation
																							.errors
																							.type
																							? true
																							: false
																					}
																				>
																					<option
																						disabled
																						value=""
																					>
																						Type
																					</option>
																					{[
																						"normal",
																						"percent",
																					].map(
																						(
																							item
																						) => (
																							<option
																								key={
																									item
																								}
																								value={
																									item
																								}
																							>
																								{
																									item
																								}
																							</option>
																						)
																					)}
																				</Input>

																				{validation
																					.touched
																					.type &&
																				validation
																					.errors
																					.type ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.type
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>
																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Commission
																				</Label>
																				<Input
																					name="commission"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.commission ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.commission &&
																						validation
																							.errors
																							.commission
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.commission &&
																				validation
																					.errors
																					.commission ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.commission
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>

																	<Row>
																		<Col>
																			<div className="text-end mt-3">
																				<button
																					type="submit"
																					className="btn btn-success save-user"
																				>
																					Save
																				</button>
																			</div>
																		</Col>
																	</Row>
																</Form>
															</fieldset>
														</ModalBody>
													</Modal>

													<Modal
														isOpen={
															apiKeyDetails.isModalOpen
														}
														toggle={() =>
															handleAPIkeyDetail(
																false
															)
														}
														size="lg"
														centered={true}
													>
														<ModalHeader
															toggle={() =>
																handleAPIkeyDetail(
																	false
																)
															}
															tag="h4"
														>
															API key details
														</ModalHeader>
														<ModalBody>
															<fieldset
																disabled={
																	!couldHaveAddUpdatePermission()
																}
															>
																<Form
																	onSubmit={(
																		e
																	) => {
																		e.preventDefault();
																		validation.handleSubmit();
																		return false;
																	}}
																>
																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<ul className="list-group list-group-flush">
																				{apiKeyDetails
																					.details
																					?.apiKeys &&
																					apiKeyDetails.details?.apiKeys?.map(
																						(
																							apikey
																						) => (
																							<li
																								key={
																									apikey.key
																								}
																								className="list-group-item"
																							>
																								<CopyToClipboard
																									text={
																										apikey.key
																									}
																									style={{
																										cursor: "pointer",
																									}}
																									onCopy={() =>
																										handleTooltipOpen()
																									}
																								>
																									<span
																										style={{
																											cursor: "pointer",
																										}}
																									>
																										{`${apikey?.key?.substring(
																											0,
																											25
																										)}.....   `}
																										<i className="mdi mdi-content-copy"></i>
																									</span>
																								</CopyToClipboard>
																							</li>
																						)
																					)}
																			</ul>
																		</Col>
																	</Row>
																</Form>
															</fieldset>
														</ModalBody>
													</Modal>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default LendingPool;
