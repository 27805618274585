import {
	GET_USER,
	GET_USER_FAILURE,
	GET_USER_SUCCESS,
	CLEAR_RESPONSE,
	ERRORS,
	GET_USER_WALLET,
	GET_USER_WALLET_FAILURE,
	GET_USER_WALLET_SUCCESS,
	GET_NODES,
	GET_NODES_FAILURE,
	GET_NODES_SUCCESS,
	GET_CONTRACT_NFT,
	GET_CONTRACT_NFT_FAILURE,
	GET_CONTRACT_NFT_SUCCESS,
	CLEAR_NODES,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	user: {},
	userWalletInfo: {},
	nodeDetails: [],
	contractNFTs: [],
	isLoading: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				isLoading: false,
			};
		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};
		case CLEAR_NODES:
			return {
				...state,
				nodeDetails: [],
			};

		case GET_USER:
			return {
				...state,
				isLoading: true,
			};

		case GET_USER_FAILURE:
			return {
				...state,
				user: [],
				isLoading: false,
			};
		case GET_USER_SUCCESS:
			return {
				...state,
				user: action.payload.data,
				isLoading: false,
			};

		case GET_USER_WALLET_FAILURE:
			return {
				...state,
				userWalletInfo: {},
				isLoading: false,
			};
		case GET_USER_WALLET_SUCCESS:
			return {
				...state,
				userWalletInfo: action.payload.data,
				isLoading: false,
			};

		case GET_NODES:
			return {
				...state,
				isLoading: true,
			};

		case GET_NODES_FAILURE:
			return {
				...state,
				nodeDetails: [],
				isLoading: false,
			};
		case GET_NODES_SUCCESS:
			return {
				...state,
				nodeDetails: action.payload.data,
				isLoading: false,
			};

		case GET_CONTRACT_NFT:
			return {
				...state,
				isLoading: true,
			};

		case GET_CONTRACT_NFT_FAILURE:
			return {
				...state,
				contractNFTs: [],
				isLoading: false,
			};
		case GET_CONTRACT_NFT_SUCCESS:
			return {
				...state,
				contractNFTs: action.payload.data,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default reducer;
