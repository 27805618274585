import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	GET_SHIPPING_SETTING_SUCCESS,
	GET_SHIPPING_SETTING_REQUEST,
	GET_SHIPPING_SETTING_FAILURE,
	UPDATE_SHIPPING_SETTING_SUCCESS,
	UPDATE_SHIPPING_SETTING_REQUEST,
	UPDATE_SHIPPING_SETTING_FAILURE,
	GET_COUNTRY_SUCCESS,
	GET_COUNTRY_REQUEST,
	GET_COUNTRY_FAILURE,
	GET_SHIPPING_METHOD_SUCCESS,
	GET_SHIPPING_METHOD_REQUEST,
	GET_SHIPPING_METHOD_FAILURE,
	GET_SHIPPING_WEIGHT_SUCCESS,
	GET_SHIPPING_WEIGHT_REQUEST,
	GET_SHIPPING_WEIGHT_FAILURE,
	GET_COUNTRYWISE_SHIPPING_FEE_SUCCESS,
	GET_COUNTRYWISE_SHIPPING_FEE_REQUEST,
	GET_COUNTRYWISE_SHIPPING_FEE_FAILURE,
	UPDATE_SHIPPING_FEE_REQUEST,
	UPDATE_SHIPPING_FEE_SUCCESS,
	UPDATE_SHIPPING_FEE_FAILURE,
} from "./actionTypes";

import {
	getSetting,
	updateSetting,
	getCountry,
	getWeight,
	getMethod,
	getFees,
	updateFee
} from "./services";

function* getShippingSetting({ payload }) {
	try {
		const response = yield call(getSetting, payload);
		if (response.status === 200) {
			yield put({
				type: GET_SHIPPING_SETTING_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_SHIPPING_SETTING_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_SHIPPING_SETTING_FAILURE,
			payload: response.data,
		});
	}
}

function* updateShippingSetting({ payload }) {
	try {
		const response = yield call(updateSetting, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_SHIPPING_SETTING_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_SHIPPING_SETTING_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_SHIPPING_SETTING_FAILURE,
			payload: response.data,
		});
	}
}

function* getCountries({ payload }) {
	try {
		const response = yield call(getCountry, payload);
		if (response.status === 200) {
			yield put({
				type: GET_COUNTRY_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_COUNTRY_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_COUNTRY_FAILURE,
			payload: response.data,
		});
	}
}

function* getShippingMethod({ payload }) {
	try {
		const response = yield call(getMethod, payload);
		if (response.status === 200) {
			yield put({
				type: GET_SHIPPING_METHOD_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_SHIPPING_METHOD_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_SHIPPING_METHOD_FAILURE,
			payload: response.data,
		});
	}
}

function* getShippingWeight({ payload }) {
	try {
		const response = yield call(getWeight, payload);
		if (response.status === 200) {
			yield put({
				type: GET_SHIPPING_WEIGHT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_SHIPPING_WEIGHT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_SHIPPING_WEIGHT_FAILURE,
			payload: response.data,
		});
	}
}
function* getShippingFees({ payload }) {
	try {
		const response = yield call(getFees, payload);
		if (response.status === 200) {
			yield put({
				type: GET_COUNTRYWISE_SHIPPING_FEE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_COUNTRYWISE_SHIPPING_FEE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_COUNTRYWISE_SHIPPING_FEE_FAILURE,
			payload: response.data,
		});
	}
}
function* updateShippingFees({ payload }) {
	try {
		const response = yield call(updateFee, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_SHIPPING_FEE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_SHIPPING_FEE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_SHIPPING_FEE_FAILURE,
			payload: response.data,
		});
	}
}

function* saga() {
	yield takeEvery(GET_SHIPPING_SETTING_REQUEST, getShippingSetting);
	yield takeEvery(UPDATE_SHIPPING_SETTING_REQUEST, updateShippingSetting);
	yield takeEvery(GET_COUNTRY_REQUEST, getCountries);
	yield takeEvery(GET_SHIPPING_METHOD_REQUEST, getShippingMethod);
	yield takeEvery(GET_SHIPPING_WEIGHT_REQUEST, getShippingWeight);
	yield takeEvery(GET_COUNTRYWISE_SHIPPING_FEE_REQUEST, getShippingFees);
	yield takeEvery(UPDATE_SHIPPING_FEE_REQUEST, updateShippingFees);
}

export default saga;
