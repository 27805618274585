import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_SOFTWARE_PACKAGE_SUCCESS,
	CREATE_SOFTWARE_PACKAGE_FAILURE,
	UPDATE_SOFTWARE_PACKAGE_SUCCESS,
	UPDATE_SOFTWARE_PACKAGE_FAILURE,
	REMOVE_SOFTWARE_PACKAGE_FAILURE,
	REMOVE_SOFTWARE_PACKAGE_SUCCESS,
	TOGGLE_SOFTWARE_PACKAGE_SUCCESS,
	TOGGLE_SOFTWARE_PACKAGE_FAILURE,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	loader: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				loading: false,
			};
		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};
		case CREATE_SOFTWARE_PACKAGE_SUCCESS:
		case CREATE_SOFTWARE_PACKAGE_FAILURE:
		case UPDATE_SOFTWARE_PACKAGE_SUCCESS:
		case UPDATE_SOFTWARE_PACKAGE_FAILURE:
		case REMOVE_SOFTWARE_PACKAGE_FAILURE:
		case REMOVE_SOFTWARE_PACKAGE_SUCCESS:
		case TOGGLE_SOFTWARE_PACKAGE_SUCCESS:
		case TOGGLE_SOFTWARE_PACKAGE_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: {},
			};
		default:
			return state;
	}
};

export default reducer;
