import React, { useEffect, useState, useRef, useMemo } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
	Label,
	Input,
	Spinner,
	Table,
} from "reactstrap";
//redux
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactDataTable from "../../common/ReactDataTable";
import { apiUrl } from "../../config";
import { getNodes, clearNodes, clearResponse } from "../../store/user/actions";

const Contract = ({ userDetails, activeTab, userWalletInfo, history }) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { response, auth, nodeDetails, isLoading } = useSelector((state) => ({
		response: state.user.response,
		nodeDetails: state.user.nodeDetails,
		isLoading: state.user.isLoading,
		auth: state.auth,
	}));

	const userId = userDetails?._id;
	useEffect(() => {
		refreshTableData.current();
	}, [userId, activeTab]);

	// console.log("userDetails, activeTab", userDetails, activeTab);

	useEffect(() => {
		if (response && response.code === "200") {
			refreshTableData.current();
			toast.success(response.msg, {
				onOpen: () => {},
			});
		} else if (response && response?.msg) {
			refreshTableData.current();
			toast.error(response.msg, {
				onOpen: () => {},
			});
		}
	}, [dispatch, response]);

	const handleTooltipOpen = () => {
		toast.success("Copied to clipboard.");
	};

	const columns = () => [
		{
			label: "Contract No",
			name: "contract_no",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Active Nodes",
			name: "active_nodes",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Total Nodes",
			name: "total_nodes",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Activated At",
			name: "activated_at",
			options: {
				filter: false,
				sort: false,
				download: false,
				customBodyRender: (createdAt) =>
					moment(createdAt).format("lll"),
			},
		},
		{
			label: "Created At",
			name: "created_at",
			options: {
				filter: false,
				sort: false,
				download: false,
				customBodyRender: (createdAt) =>
					moment(createdAt).format("lll"),
			},
		},
		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: hasPermission(
					["NFT svg update", "NFT svg delete", "NFT svg view"],
					auth.currentUserRolePermissions
				),
				viewColumns: hasPermission(
					["NFT svg update", "NFT svg delete", "NFT svg view"],
					auth.currentUserRolePermissions
				),
				customBodyRender: (data) => {
					return (
						<div className="text-center">
							<HasAnyPermission
								permission={["NFT svg update", "NFT svg view"]}
							>
								<button
									onClick={(e) => {
										e.preventDefault();
										history.push(
											`/user/${data._id}/${userWalletInfo?._id}/${userId}`
										);
									}}
									type="button"
									className="btn btn-soft-primary waves-effect waves-light"
								>
									View
								</button>
							</HasAnyPermission>
						</div>
					);
				},
			},
		},
		{
			name: "action",
			label: "action",
			options: {
				filter: false,
				display: "excluded",
				viewColumns: false,
				download: false,
			},
		},
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				action: item,
			};
		});
	};

	const expandableAPICall = (data) => {
		if (data?._id) dispatch(getNodes({ _id: data._id }));
		else dispatch(clearNodes());
	};

	const expandableComponent = (rowData, rowMeta) => {
		const { node } = nodeDetails?.[0] ?? {};
		return (
			<tr>
				<td colSpan={6}>
					{isLoading ? (
						<div className="text-center">
							<Spinner className="m-3" color="primary" />
						</div>
					) : (
						<Card className="m-2">
							<CardHeader>
								<h4 className="card-title">
									Node Details{" "}
									{node?.machine?.id &&
										`(Unit-${node.machine.id})`}
								</h4>
							</CardHeader>
							<CardBody>
								{node?.machine ? (
									<Row className="mt-1">
										<Col xl={3} sm={6}>
											<Card>
												<CardBody className="overflow-hidden position-relative">
													<h5 className="mt-3">
														IP Address (IPv6)
													</h5>
													<div className="faq-count">
														<h5 className="text-primary">
															{`${node.machine.ip}`}
														</h5>
													</div>
												</CardBody>
											</Card>
										</Col>
										<Col xl={3} sm={6}>
											<Card>
												<CardBody className="overflow-hidden position-relative">
													<h5 className="mt-3">
														MAC Address
													</h5>
													<div className="faq-count">
														<h5 className="text-primary">
															{`${node.machine.mac}`}
														</h5>
													</div>
												</CardBody>
											</Card>
										</Col>

										<Col xl={3} sm={6}>
											<Card>
												<CardBody className="overflow-hidden position-relative">
													<h5 className="mt-3">
														Status
													</h5>
													<div className="faq-count">
														<h5
															style={{
																textTransform:
																	"capitalize",
															}}
															className="text-primary"
														>
															{`${node.machine.status}`}
														</h5>
													</div>
												</CardBody>
											</Card>
										</Col>
										{nodeDetails && (
											<div className="table-responsive">
												<Table className="table table-striped mb-0">
													<thead className="table-light">
														<tr>
															<th>#</th>
															<th>SERIAL</th>
															<th>HASH RATE</th>
															<th>TERABYTES</th>
															<th>STATUS</th>
														</tr>
													</thead>
													<tbody>
														{nodeDetails?.map(
															(nodeItem) => (
																<tr
																	key={
																		nodeItem
																			.node
																			?.id
																	}
																>
																	<th scope="row">
																		{`Node #${nodeItem.node?.id}`}
																	</th>
																	<td>
																		{`${nodeItem.node?.node_no}`}
																	</td>
																	<td>
																		-
																		{/* {`${nodeItem.node?.hashrate_min}`} */}
																	</td>
																	<td>
																		-
																		{/* {`${nodeItem.node?.hashrate_max} TB`} */}
																	</td>
																	<td
																		style={{
																			textTransform:
																				"capitalize",
																		}}
																	>
																		{`${nodeItem.node?.status}`}
																	</td>
																</tr>
															)
														)}
													</tbody>
												</Table>
											</div>
										)}
									</Row>
								) : (
									<h4 className="text-center">
										No nodes available
									</h4>
								)}
							</CardBody>
						</Card>
					)}
				</td>
			</tr>
		);
	};

	return (
		<React.Fragment>
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<Row>
								<Col xl="12">
									<div className="table-rep-plugin">
										<div className="table-responsive">
											<ReactDataTable
												url={`${apiUrl}/admin/user/get-contracts/${userDetails._id}`}
												columns={columns()}
												resultFormatter={
													resultFormatter
												}
												filters={[
													{
														name: "user_id",
														value: [userDetails.id],
													},
												]}
												setRefresh={refreshTableData}
												// renderExpandableRow={
												// 	expandableComponent
												// }
												// expandableAPICall={
												// 	expandableAPICall
												// }
												// // isUnexpandedRows={true}
												// expandableRows={true}
												disableFilterIcon={false}
												disableSearchIcon={false}
												origin={
													<div className="row">
														<div className="col-auto h4">
															User Contracts
															&nbsp;
														</div>
													</div>
												}
												rowsPerPage={10}
											/>
										</div>
									</div>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	);
};
export default Contract;
