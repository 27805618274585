import {
	ERRORS,
	CLEAR_RESPONSE,
	GET_SHIPPING_SETTING_SUCCESS,
	GET_SHIPPING_SETTING_REQUEST,
	GET_SHIPPING_SETTING_FAILURE,
	UPDATE_SHIPPING_SETTING_SUCCESS,
	UPDATE_SHIPPING_SETTING_REQUEST,
	UPDATE_SHIPPING_SETTING_FAILURE,
	GET_COUNTRY_SUCCESS,
	GET_COUNTRY_REQUEST,
	GET_COUNTRY_FAILURE,
	GET_SHIPPING_METHOD_SUCCESS,
	GET_SHIPPING_METHOD_REQUEST,
	GET_SHIPPING_METHOD_FAILURE,
	GET_SHIPPING_WEIGHT_SUCCESS,
	GET_SHIPPING_WEIGHT_REQUEST,
	GET_SHIPPING_WEIGHT_FAILURE,
	GET_COUNTRYWISE_SHIPPING_FEE_SUCCESS,
	GET_COUNTRYWISE_SHIPPING_FEE_REQUEST,
	GET_COUNTRYWISE_SHIPPING_FEE_FAILURE,
	UPDATE_SHIPPING_FEE_SUCCESS,
	UPDATE_SHIPPING_FEE_REQUEST,
	UPDATE_SHIPPING_FEE_FAILURE,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const getShippinghSetting = (data) => {
	return {
		type: GET_SHIPPING_SETTING_REQUEST,
		payload: data,
	};
};
export const getCountries = (data) => {
	return {
		type: GET_COUNTRY_REQUEST,
		payload: data,
	};
};

export const updateShippinghSetting = (data) => {
	return {
		type: UPDATE_SHIPPING_SETTING_REQUEST,
		payload: data,
	};
};

export const getShippingMethod = (data) => {
	return {
		type: GET_SHIPPING_METHOD_REQUEST,
		payload: data,
	};
};

export const getShippingWeight = (data) => {
	return {
		type: GET_SHIPPING_WEIGHT_REQUEST,
		payload: data,
	};
};
export const getShippingFees = (data) => {
	return {
		type: GET_COUNTRYWISE_SHIPPING_FEE_REQUEST,
		payload: data,
	};
};

export const updateShippingFee = (data) => {
	return {
		type: UPDATE_SHIPPING_FEE_REQUEST,
		payload: data,
	};
};
