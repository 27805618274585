import axios from "axios";
import { apiUrl } from "../../config";

export const create = (data) =>
	axios
		.post(`${apiUrl}/admin/store/voucher-code-package/create`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data, _id) =>
	axios
		.put(
			`${apiUrl}/admin/store/voucher-code-package/update/${data._id}`,
			data
		)
		.then((response) => response)
		.catch((err) => err.response);

export const toggle = (data) =>
	axios
		.patch(
			`${apiUrl}/admin/store/voucher-code-package/toggle-status/${data._id}`
		)
		.then((response) => response)
		.catch((err) => err.response);

export const remove = (data) =>
	axios
		.delete(`${apiUrl}/admin/store/voucher-code-package/delete/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);
