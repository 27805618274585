export const GET_USER = "GET_USER";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

export const GET_USER_WALLET = "GET_USER_WALLET";
export const GET_USER_WALLET_FAILURE = "GET_USER_WALLET_FAILURE";
export const GET_USER_WALLET_SUCCESS = "GET_USER_WALLET_SUCCESS";

export const GET_NODES = "GET_NODES";
export const GET_NODES_FAILURE = "GET_NODES_FAILURE";
export const GET_NODES_SUCCESS = "GET_NODES_SUCCESS";

export const GET_CONTRACT_NFT = "GET_CONTRACT_NFT";
export const GET_CONTRACT_NFT_FAILURE = "GET_CONTRACT_NFT_FAILURE";
export const GET_CONTRACT_NFT_SUCCESS = "GET_CONTRACT_NFT_SUCCESS";

export const CLEAR_NODES = "CLEAR_NODES";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";
export const ERRORS = "ERRORS";
