import {
	ERRORS,
	CLEAR_RESPONSE,
	UPDATE_META_REQUEST,
	UPDATE_META_SUCCESS,
	UPDATE_META_FAILURE,
	GET_META_REQUEST,
	GET_META_SUCCESS,
	GET_META_FAILURE,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	loader: false,
	details: {},
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				loading: false,
			};
		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};
		case GET_META_SUCCESS:
			return {
				...state,
				details: action.payload?.data,
			};
		case GET_META_FAILURE:
			return {
				...state,
				details: {},
			};

		case UPDATE_META_SUCCESS:
		case UPDATE_META_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: {},
			};
		default:
			return state;
	}
};

export default reducer;
