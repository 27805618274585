import axios from "axios";
import { apiUrl } from "../../config";

export const create = ({ formData }) =>
	axios
		.post(`${apiUrl}/admin/store/products`, formData)
		.then((response) => response)
		.catch((err) => err.response);

export const update = ({ formData, id }) =>
	axios
		.put(`${apiUrl}/admin/store/products/${id}`, formData)
		.then((response) => response)
		.catch((err) => err.response);

export const toggle = (data) =>
	axios
		.patch(`${apiUrl}/admin/partner/toggle-status/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const remove = (data) =>
	axios
		.delete(`${apiUrl}/admin/store/products/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getProduct = (data) =>
	axios
		.get(`${apiUrl}/admin/store/products`)
		.then((response) => response)
		.catch((err) => err.response);

export const getCategories = (data) =>
	axios
		.get(`${apiUrl}/admin/store/product-option/categories`)
		.then((response) => response)
		.catch((err) => err.response);

export const getOptions = (data) =>
	axios
		.get(`${apiUrl}/admin/store/product-option/`)
		.then((response) => response)
		.catch((err) => err.response);
