import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Row,
	Container,
	CardHeader,
	CardTitle,
	Table,
	Input,
	Label,
	Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import isEmpty from "../../utils/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import {
	getCloudUsage,
	update,
	clearResponse,
} from "../../store/cloudUsage/actions";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";

const Setting = (props) => {
	const dispatch = useDispatch();
	const { cloudUsageDetails, auth, response } = useSelector((state) => ({
		cloudUsageDetails: state.cloudUsage.cloudUsageDetails,
		response: state.cloudUsage.response,
		auth: state.auth,
	}));

	useEffect(() => {
		dispatch(getCloudUsage());
	}, []);

	useEffect(() => {
		if (response && response.code === "200") {
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
					dispatch(getCloudUsage());
				},
			});
		} else if (response && response?.msg) {
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const validate = useFormik({
		enableReinitialize: true,
		initialValues: {
			minutes: cloudUsageDetails.minutes ?? 0,
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				Swal.fire({
					title: "Are you sure?",
					text: "Do you really want to update?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Confirm",
				}).then((result) => {
					if (result.value) {
						const updatedCloudUsageDetails = {
							...cloudUsageDetails,
							...values,
						};
						dispatch(update(updatedCloudUsageDetails));
					}
				});
			}
		},
	});

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>CLOUD USAGE | LFI</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Meta Data" breadcrumbItem="Setting" />

					<Row>
						<Card className="border border-default">
							<CardHeader className="align-items-center d-flex bg-transparent border-default">
								<CardTitle className="mb-0 flex-grow-1">
									<h5 className="my-0 text-primary">
										<i className="mdi mdi-bullseye-arrow me-3"></i>
										CLOUD USAGE SETTING
									</h5>
								</CardTitle>
							</CardHeader>
							<CardBody>
								<HasAnyPermission
									permission={[
										"LFI setting update",
										"LFI setting view",
									]}
								>
									<Row className="mt-3">
										<Col sm={3}>
											<h5>Minutes</h5>
										</Col>
										<Col>
											<div className="mb-1">
												<fieldset
													disabled={
														!hasPermission(
															[
																"LFI setting update",
															],
															auth.currentUserRolePermissions
														)
													}
												>
													<Form
														onSubmit={(e) => {
															e.preventDefault();
															validate.handleSubmit();
															return false;
														}}
														className="row gx-3 gy-2 align-items-center"
													>
														<Col sm={3}>
															<Input
																type="text"
																name="minutes"
																className="form-control"
																onChange={
																	validate.handleChange
																}
																onBlur={
																	validate.handleBlur
																}
																value={
																	validate
																		.values
																		.minutes ||
																	""
																}
															/>
														</Col>
														<div className="col-auto">
															<button
																type="submit"
																className="btn btn-primary"
															>
																Update
															</button>
														</div>
													</Form>
												</fieldset>
											</div>
										</Col>
									</Row>
								</HasAnyPermission>
							</CardBody>
						</Card>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Setting;
