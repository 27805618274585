export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_NETWORK_INFO_REQUEST = "CREATE_NETWORK_INFO_REQUEST";
export const CREATE_NETWORK_INFO_SUCCESS = "CREATE_NETWORK_INFO_SUCCESS";
export const CREATE_NETWORK_INFO_FAILURE = "CREATE_NETWORK_INFO_FAILURE";

export const UPDATE_NETWORK_INFO_REQUEST = "UPDATE_NETWORK_INFO_REQUEST";
export const UPDATE_NETWORK_INFO_SUCCESS = "UPDATE_NETWORK_INFO_SUCCESS";
export const UPDATE_NETWORK_INFO_FAILURE = "UPDATE_NETWORK_INFO_FAILURE";

export const REMOVE_NETWORK_INFO_REQUEST = "REMOVE_NETWORK_INFO_REQUEST";
export const REMOVE_NETWORK_INFO_SUCCESS = "REMOVE_NETWORK_INFO_SUCCESS";
export const REMOVE_NETWORK_INFO_FAILURE = "REMOVE_NETWORK_INFO_FAILURE";

export const TOGGLE_NETWORK_INFO_SUCCESS = "TOGGLE_NETWORK_INFO_SUCCESS";
export const TOGGLE_NETWORK_INFO_REQUEST = "TOGGLE_NETWORK_INFO_REQUEST";
export const TOGGLE_NETWORK_INFO_FAILURE = "TOGGLE_NETWORK_INFO_FAILURE";
