import axios from "axios";
import { apiUrl } from "../../config";

export const getUser = ({ _id }) =>
	axios
		.get(`${apiUrl}/admin/user/details/${_id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const userWalletService = ({ _id }) =>
	axios
		.get(`${apiUrl}/admin/wallet/user/${_id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getNodeService = ({ contractID }) =>
	axios
		.get(`${apiUrl}/admin/user/get-nodes/${contractID}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getContractNFTService = ({ contractId, walletId }) =>
	axios
		.get(`${apiUrl}/admin/nft/lists/${contractId}/${walletId}`)
		.then((response) => response)
		.catch((err) => err.response);
