export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_VOUCHER_PACKAGE_REQUEST = "CREATE_VOUCHER_PACKAGE_REQUEST";
export const CREATE_VOUCHER_PACKAGE_SUCCESS = "CREATE_VOUCHER_PACKAGE_SUCCESS";
export const CREATE_VOUCHER_PACKAGE_FAILURE = "CREATE_VOUCHER_PACKAGE_FAILURE";

export const UPDATE_VOUCHER_PACKAGE_REQUEST = "UPDATE_VOUCHER_PACKAGE_REQUEST";
export const UPDATE_VOUCHER_PACKAGE_SUCCESS = "UPDATE_VOUCHER_PACKAGE_SUCCESS";
export const UPDATE_VOUCHER_PACKAGE_FAILURE = "UPDATE_VOUCHER_PACKAGE_FAILURE";

export const REMOVE_VOUCHER_PACKAGE_REQUEST = "REMOVE_VOUCHER_PACKAGE_REQUEST";
export const REMOVE_VOUCHER_PACKAGE_SUCCESS = "REMOVE_VOUCHER_PACKAGE_SUCCESS";
export const REMOVE_VOUCHER_PACKAGE_FAILURE = "REMOVE_VOUCHER_PACKAGE_FAILURE";

export const TOGGLE_VOUCHER_PACKAGE_SUCCESS = "TOGGLE_VOUCHER_PACKAGE_SUCCESS";
export const TOGGLE_VOUCHER_PACKAGE_REQUEST = "TOGGLE_VOUCHER_PACKAGE_REQUEST";
export const TOGGLE_VOUCHER_PACKAGE_FAILURE = "TOGGLE_VOUCHER_PACKAGE_FAILURE";
