import React, { useEffect, useState, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Row,
	Container,
	CardHeader,
	CardTitle,
	Table,
	Input,
	Label,
	Form,
	FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import isEmpty from "../../utils/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import {
	getCountries,
	updateShippinghSetting,
	clearResponse,
	getShippinghSetting,
} from "../../store/shipping/actions";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const Setting = (props) => {
	const dispatch = useDispatch();
	const animatedComponents = makeAnimated();
	const { shipping, auth } = useSelector((state) => ({
		shipping: state.shipping,
		auth: state.auth,
	}));
	const { response, setting, countries } = shipping;
	useEffect(() => {
		dispatch(getCountries());
		dispatch(getShippinghSetting());
	}, []);

	useEffect(() => {
		if (response && response.code === "200") {
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
					dispatch(getShippinghSetting());
				},
			});
		} else if (response && response?.msg) {
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const countryOptionDropdown = useMemo(() => {
		const result = countries?.map((item) => ({
			label: `${item.name}`,
			value: item._id,
		}));
		return result ?? [];
	}, [countries]);

	const getSelecetedCountries = useMemo(() => {
		const result = setting?.countries?.map((item) => ({
			label: `${item.name}`,
			value: item._id,
		}));
		return result ?? [];
	}, [setting]);
	let validationSchema = {
		countries: Yup.array().required("Please select countries."),
		conversionRate: Yup.number().required(),
	};

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			countries: getSelecetedCountries,
			conversionRate:
				setting && setting.conversionRate ? setting.conversionRate : "",
			type:
				setting && setting?.commission?.type
					? setting.commission.type
					: "",
			commission:
				setting && setting?.commission?.commission
					? setting.commission.commission
					: "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => {
			const countries = values.countries?.map((country) => country.value);
			const updateDetails = {
				...values,
				commission: {
					type: values.type,
					commission: values.commission,
				},
				countries: countries,
			};
			dispatch(updateShippinghSetting(updateDetails));
		},
	};
	const validation = useFormik(useFormikOptions);

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Shipping SETTING | LFI</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs
						title="Reward setting                                              "
						breadcrumbItem="Setting"
					/>

					<Row>
						<Card className="border border-default">
							<CardHeader className="align-items-center d-flex bg-transparent border-default">
								<CardTitle className="mb-0 flex-grow-1">
									<h5 className="my-0 text-primary">
										<i className="mdi mdi-bullseye-arrow me-3"></i>
										Shipping setting
									</h5>
								</CardTitle>
							</CardHeader>
							<CardBody>
								<Row form={"true"}>
									<Col md={6}>
										<Form
											onSubmit={(e) => {
												e.preventDefault();
												validation.handleSubmit();
												return false;
											}}
										>
											<Row form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															Select countries
														</Label>
														<Select
															name="countries"
															value={
																validation
																	.values
																	.countries ||
																""
															}
															placeholder="Select shipping countries"
															onChange={(
																value,
																action
															) => {
																validation.setFieldValue(
																	"countries",
																	value
																);
															}}
															isMulti={true}
															options={
																countryOptionDropdown
															}
															classNamePrefix="select2-selection"
															onSelectResetsInput={
																true
															}
															closeMenuOnSelect={
																true
															}
															components={
																animatedComponents
															}
															required
														/>
														{validation.touched
															.countries &&
														validation.errors
															.countries ? (
															<FormFeedback
																className="error"
																type="invalid"
															>
																{
																	validation
																		.errors
																		.countries
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>
											<Row form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															Conversion Rate
														</Label>
														<Input
															name="conversionRate"
															type="text"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.conversionRate ||
																""
															}
															invalid={
																validation
																	.touched
																	.conversionRate &&
																validation
																	.errors
																	.conversionRate
																	? true
																	: false
															}
														/>
														{validation.touched
															.conversionRate &&
														validation.errors
															.conversionRate ? (
															<FormFeedback type="invalid">
																{
																	validation
																		.errors
																		.conversionRate
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<Row form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															Commission Type
														</Label>
														<Input
															name="type"
															type="select"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.type || ""
															}
															invalid={
																validation
																	.touched
																	.type &&
																validation
																	.errors.type
																	? true
																	: false
															}
														>
															<option
																disabled
																value=""
															>
																Type
															</option>
															{[
																"percentage",
																"normal",
															].map((item) => (
																<option
																	key={item}
																	value={item}
																>
																	{item}
																</option>
															))}
														</Input>

														{validation.touched
															.type &&
														validation.errors
															.type ? (
															<FormFeedback type="invalid">
																{
																	validation
																		.errors
																		.type
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>
											<Row form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															Commission
														</Label>
														<Input
															name="commission"
															type="text"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.commission ||
																""
															}
															invalid={
																validation
																	.touched
																	.commission &&
																validation
																	.errors
																	.commission
																	? true
																	: false
															}
														/>
														{validation.touched
															.commission &&
														validation.errors
															.commission ? (
															<FormFeedback type="invalid">
																{
																	validation
																		.errors
																		.commission
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<Row>
												<Col>
													<div className="text-end mt-3">
														<button
															type="submit"
															className="btn btn-success save-user"
														>
															Update
														</button>
													</div>
												</Col>
											</Row>
										</Form>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Setting;
