import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import Swal from "sweetalert2";
import moment from "moment";

const MacAddress = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { auth } = useSelector((state) => ({
		auth: state.auth,
	}));

	const columns = () => [
		{
			label: "MAC address",
			name: "macAddress",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Status",
			name: "isAssignable",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (isAssignable) => {
					return isAssignable ? (
						<div className="badge badge-soft-danger font-size-12">
							Assignable
						</div>
					) : (
						<div className="badge badge-soft-primary  font-size-12">
							Not Assignable
						</div>
					);
				},
				filterType: "custom",
				customFilterListOptions: {
					render: (v) => {
						const [selectedValue] = v;
						if (selectedValue)
							return selectedValue === "true"
								? "Assignable"
								: "Not Assignable";
						return null;
					},
					update: (filterList, filterPos, index) => {
						filterList[index] = [];
						return filterList;
					},
				},
				filterOptions: {
					names: [],
					display: (filterList, onChange, index, column) => {
						const optionValues = ["Assignable", "Not Assignable"];
						return (
							<div className="auto">
								<label
									htmlFor="user-status"
									className="form-label font-size-13 text-muted"
								>
									{" "}
									Status
								</label>
								<select
									value={filterList[index][0] || ""}
									onChange={(event) => {
										filterList[index][0] =
											event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
									className="form-control"
									name="user-status"
								>
									<option disabled value="">
										Status
									</option>
									{optionValues.map((item) => (
										<option
											key={item}
											value={
												item === "Assignable"
													? true
													: false
											}
										>
											{item}
										</option>
									))}
								</select>
							</div>
						);
					},
				},
			},
		},
		{
			label: "CreatedAt",
			name: "created_at",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (created_at) =>
					moment(created_at).format("lll"),
			},
		},

		// {
		// 	label: "Actions",
		// 	name: "action",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 		empty: true,
		// 		download: false,
		// 		display: hasPermission(
		// 			["admin update", "admin delete", "admin view"],
		// 			auth.currentUserRolePermissions
		// 		),
		// 		viewColumns: hasPermission(
		// 			["admin update", "admin delete", "admin view"],
		// 			auth.currentUserRolePermissions
		// 		),
		// 		customBodyRender: (data) => {
		// 			return (
		// 				<div className="text-center">
		// 					<HasAnyPermission
		// 						permission={["admin update", "admin view"]}
		// 					>
		// 						<button
		// 							onClick={(e) => {
		// 								e.preventDefault();
		// 								console.log("admin");
		// 								props.history.push(`/user/${data._id}`);
		// 							}}
		// 							type="button"
		// 							className="btn btn-soft-primary waves-effect waves-light"
		// 						>
		// 							View
		// 						</button>
		// 					</HasAnyPermission>
		// 				</div>
		// 			);
		// 		},
		// 	},
		// },
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				action: item,
			};
		});
	};
	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Mac address | LFi</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Mac address" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"admin list",
														]}
													>
														<ReactDataTable
															url={`${apiUrl}/admin/mac-address/pagination`}
															columns={columns()}
															resultFormatter={
																resultFormatter
															}
															setRefresh={
																refreshTableData
															}
															disableFilterIcon={
																false
															}
															disableSearchIcon={
																false
															}
															origin={
																<div className="row">
																	<div className="col-auto h4">
																		MAC
																		address
																		&nbsp;
																		<HasAnyPermission
																			permission={[
																				"admin add",
																			]}
																		>
																			{/* <button
																				onClick={() => {
																					handleAddEditModal();
																				}}
																				type="button"
																				className="btn btn-primary waves-effect waves-light"
																			>
																				<i className="bx bx-plus-medical font-size-16 align-middle"></i>
																			</button> */}
																		</HasAnyPermission>
																	</div>
																</div>
															}
															rowsPerPage={10}
														/>
													</HasAnyPermission>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default MacAddress;
