import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
	CardHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Avatar from "react-avatar";

//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import MUIDataTable from "mui-datatables";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import moment from "moment";
import isEmpty from "../../utils/isEmpty";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import {
	create,
	update,
	remove,
	toggle,
	getCustomers,
	clearResponse,
} from "../../store/customer/actions";

let editor;

const Customer = (props) => {
	const dispatch = useDispatch();
	const { auth, customer } = useSelector((state) => ({
		customer: state.customer,
		auth: state.auth,
	}));

	const [images, setImages] = useState([{ src: "" }]);

	const { response, customers, isLoading } = customer;
	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const noMatch = isLoading ? (
		<CircularProgress size={30} style={{ color: "#3f51b5" }} />
	) : (
		"Sorry, no matching records found."
	);

	useEffect(() => {
		dispatch(getCustomers());
	}, []);

	const [details, handleformData] = useState({});

	useEffect(() => {
		if (response && response.status === 200) {
			dispatch(getCustomers());
			Swal.close();
			if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		} else if (response && response?.msg) {
			Swal.close();
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const removeItem = (data) => {
		if (!isEmpty(data?._id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to delete?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					dispatch(remove(data));
				}
			});
		}
	};

	const handleToggle = (data) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to update?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				Swal.fire({
					title: "Updating",
					html: "Please wait...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				dispatch(toggle(data));
			}
		});
	};

	let validationSchema = {
		name: Yup.string().required(),
		regularPrice: Yup.string().required(),
		price: Yup.string().required(),
		// images: Yup.string().required(),
	};

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			regularPrice:
				details && details.regularPrice ? details.regularPrice : "",
			price: details && details.price ? details.price : "",
			name: details && details.name ? details.name : "",
			images: details && details.images ? details.images : [],
			description:
				details && details.description ? details.description : "",
			shortDescription:
				details && details.shortDescription
					? details.shortDescription
					: "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => {
			Swal.fire({
				title: "Updating",
				html: "Please wait...",
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			if (details.id) {
				dispatch(update({ ...values, id: details.id }));
			} else {
				dispatch(create(values));
			}
		},
	};

	const validation = useFormik(useFormikOptions);

	const handleAddEditModal = (data = null) => {
		if (!isEmpty(data) && data?.id) {
			validation.setFieldValue("images", data.images ?? [{ src: "" }]);
			setImages(data.images ?? [{ src: "" }]);
			handleformData(data);
		} else {
			handleformData({
				name: "",
				regularPrice: "",
				images: [],
				description: "",
				shortDescription: "",
			});
			validation.resetForm();
		}
		toggleAddEditModal(!isOpenAddEditModal);
	};

	const onEditorChange = (data) => {
		validation.setFieldValue("description", data);
	};

	const handleImageSrcChange = (index) => (event) => {
		const imageDetails = [...images];
		imageDetails[index]["src"] = event.target.value;
		validation.setFieldValue("images", imageDetails);
		setImages(imageDetails);
	};
	const addImageField = () => {
		setImages([
			...images,
			{
				src: "",
			},
		]);
	};

	const removeImageField = (index) => () => {
		const imageDetails = [...images];
		imageDetails.splice(index, 1);
		setImages(imageDetails);
	};

	const columns = () => [
		{
			label: "Name",
			name: "name",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData ? (
						rowData
					) : (
						<div className="text-center">-</div>
					);
				},
			},
		},

		{
			label: "Email",
			name: "email",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData ? (
						rowData
					) : (
						<div className="text-center">-</div>
					);
				},
			},
		},

		{
			label: "CreatedAt",
			name: "date_created",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return moment(rowData.date_created).format("LLL");
				},
			},
		},

		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: hasPermission(
					["partner update", "partner delete", "partner view"],
					auth.currentUserRolePermissions
				),
				viewColumns: hasPermission(
					["partner update", "partner delete", "partner view"],
					auth.currentUserRolePermissions
				),
				customBodyRender: (data) => {
					return (
						<div className="text-center">
							<HasAnyPermission
								permission={["partner update", "partner view"]}
							>
								<button
									onClick={(e) => handleAddEditModal(data)}
									type="button"
									className="btn btn-soft-primary waves-effect waves-light"
								>
									View
								</button>
							</HasAnyPermission>
						</div>
					);
				},
			},
		},
	];

	const resultFormatter = (result) => {
		return result.map((item) => {
			return {
				...item,
				name: `${item.first_name} ${item.last_name}`,
				action: item,
			};
		});
	};

	const couldHaveAddUpdatePermission = () => {
		const isUpdatePermission = hasPermission(
			["chain update"],
			auth.currentUserRolePermissions
		);
		const isAddPermission = hasPermission(
			["chain add"],
			auth.currentUserRolePermissions
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details._id)) return true;
		else if (isAddPermission && isEmpty(details._id)) return true;
		else return false;
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Customer | LFI</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Customer" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<MUIDataTable
														title={
															<div className="row">
																<div className="col-auto h4">
																	Customers
																	{/* &nbsp;
																	<HasAnyPermission
																		permission={[
																			"chain add",
																		]}
																	>
																		<button
																			onClick={() => {
																				handleAddEditModal();
																			}}
																			type="button"
																			className="btn btn-primary waves-effect waves-light"
																		>
																			<i className="bx bx-plus-medical font-size-16 align-middle"></i>
																		</button>
																	</HasAnyPermission> */}
																</div>
															</div>
														}
														data={resultFormatter(
															customers ?? []
														)}
														columns={columns()}
														options={{
															filter: false,
															print: false,
															download: true,
															responsive:
																"standard",
															viewColumns: false,
															selectableRows:
																"none",
															textLabels: {
																body: {
																	noMatch:
																		noMatch,
																},
															},
															expandableRowsOnClick: false,
															setTableProps:
																() => ({
																	className:
																		"mb-0 table-bordered responsiveTable",
																	// className: "align-middle table table-bordered table-bordered mb-0",
																}),
														}}
													/>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
							<Modal
								isOpen={isOpenAddEditModal}
								toggle={handleAddEditModal}
								size="lg"
								centered={true}
							>
								<ModalHeader
									toggle={handleAddEditModal}
									tag="h4"
								>
									User Details
								</ModalHeader>
								<ModalBody>
									<pre>
										{JSON.stringify(details, null, 2)}
									</pre>

									<Row>
										<Col lg="12">
											<Card>
												<CardBody>
													<div className="d-flex">
														<div className="ms-3">
															<Avatar
																className=""
																color={
																	"#74788d"
																}
																name={`${details?.first_name} ${details?.last_name}`}
																round={true}
																size={60}
															/>
														</div>
														<div className="flex-grow-1 align-self-center ms-3">
															<div className="text-muted">
																<h5>
																	{`${details?.first_name} ${details?.last_name}`}
																</h5>
																<p className="mb-1">
																	{
																		details?.email
																	}
																</p>
																<p className="mb-0">
																	Role: #
																	{details?.role?.toUpperCase()}
																</p>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</Col>
									</Row>

									<Row>
										<Col xs={12}>
											<Card>
												<CardHeader>
													<h4 className="card-title mb-0">
														Billing Addess
													</h4>
												</CardHeader>
												<CardBody>
													<Form
														name="profileUpdateForm"
														onSubmit={(e) => {
															e.preventDefault();
															// validationProfile.handleSubmit();
															return false;
														}}
													>
														<Row form={"true"}>
															<Col xs={12}>
																<div className="mb-3">
																	<Input
																		name="first_name"
																		type="text"
																		placeholder="first_name"
																		value={
																			details
																				?.billing
																				?.first_name ||
																			""
																		}
																	/>
																</div>
																<div className="mb-3">
																	<Input
																		name="last_name"
																		type="text"
																		placeholder="last_name"
																		value={
																			details
																				?.billing
																				?.last_name ||
																			""
																		}
																	/>
																</div>
																<div className="mb-3">
																	<Input
																		name="address_1"
																		type="text"
																		placeholder="address_1"
																		value={
																			details
																				?.billing
																				?.address_1 ||
																			""
																		}
																	/>
																</div>
																<div className="mb-3">
																	<Input
																		name="address_2"
																		type="text"
																		placeholder="address_2"
																		value={
																			details
																				?.billing
																				?.address_2 ||
																			""
																		}
																	/>
																</div>
																<div className="mb-3">
																	<Input
																		name="city"
																		type="text"
																		placeholder="city"
																		value={
																			details
																				?.billing
																				?.city ||
																			""
																		}
																	/>
																</div>
																<div className="mb-3">
																	<Input
																		name="state"
																		type="text"
																		placeholder="state"
																		value={
																			details
																				?.billing
																				?.state ||
																			""
																		}
																	/>
																</div>
																<div className="mb-3">
																	<Input
																		name="country"
																		type="text"
																		placeholder="country"
																		value={
																			details
																				?.billing
																				?.country ||
																			""
																		}
																	/>
																</div>
															</Col>
														</Row>
													</Form>
												</CardBody>
											</Card>
										</Col>
									</Row>
								</ModalBody>
							</Modal>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Customer;
