import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_ORDER_REQUEST,
	CREATE_ORDER_SUCCESS,
	CREATE_ORDER_FAILURE,
	UPDATE_ORDER_SUCCESS,
	UPDATE_ORDER_REQUEST,
	UPDATE_ORDER_FAILURE,
	REMOVE_ORDER_FAILURE,
	REMOVE_ORDER_SUCCESS,
	REMOVE_ORDER_REQUEST,
	TOGGLE_ORDER_SUCCESS,
	TOGGLE_ORDER_REQUEST,
	TOGGLE_ORDER_FAILURE,
	GET_ORDER_REQUEST,
	GET_ORDER_SUCCESS,
	GET_ORDER_FAILURE,
	GET_ORDER_WITH_ID_REQUEST,
	GET_ORDER_WITH_ID_SUCCESS,
	GET_ORDER_WITH_ID_FAILURE,
	GET_MAC_ADDRESS_REQUEST,
	GET_MAC_ADDRESS_SUCCESS,
	GET_MAC_ADDRESS_FAILURE,
	GET_INVOICE_REQUEST,
	GET_INVOICE_SUCCESS,
	GET_INVOICE_FAILURE,
	GET_UPGRADE_PRODUCT_REQUEST,
	GET_UPGRADE_PRODUCT_SUCCESS,
	GET_UPGRADE_PRODUCT_FAILURE,
	GET_UPGRADE_ORDER_PRODUCT_REQUEST,
	GET_UPGRADE_ORDER_PRODUCT_SUCCESS,
	GET_UPGRADE_ORDER_PRODUCT_FAILURE,
	EXPORT_ORDER_REQUEST,
	EXPORT_ORDER_SUCCESS,
	EXPORT_ORDER_FAILURE,
	GET_ORDER_MERCHANT_INVOICE_REQUEST,
	GET_ORDER_MERCHANT_INVOICE_SUCCESS,
	GET_ORDER_MERCHANT_INVOICE_FAILURE,
} from "./actionTypes";

import {
	create,
	update,
	remove,
	toggle,
	getOrder,
	getOrderUsingId,
	getMacAddressService,
	getUpgradeProductService,
	getInvoiceDetails,
	upgradeOrderProductService,
	getExportOrderCSVService,
	getOrderMerchantInvoiceService,
} from "./services";

function* createOrder({ payload }) {
	try {
		const response = yield call(create, payload);
		if (response.status === 200) {
			yield put({
				type: CREATE_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_ORDER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_ORDER_FAILURE,
			payload: response.data,
		});
	}
}

function* getOrders({ payload }) {
	try {
		const response = yield call(getOrder, payload);
		if (response.status === 200) {
			yield put({
				type: GET_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_ORDER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_ORDER_FAILURE,
			payload: response.data,
		});
	}
}

function* getInvoice({ payload }) {
	try {
		const response = yield call(getInvoiceDetails, payload);
		if (response.status === 200) {
			yield put({
				type: GET_INVOICE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_INVOICE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_INVOICE_FAILURE,
			payload: response.data,
		});
	}
}

function* getUpgradeProduct({ payload }) {
	try {
		const response = yield call(getUpgradeProductService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_UPGRADE_PRODUCT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_UPGRADE_PRODUCT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_UPGRADE_PRODUCT_FAILURE,
			payload: response.data,
		});
	}
}

function* upgradeOrderProduct({ payload }) {
	try {
		const response = yield call(upgradeOrderProductService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_UPGRADE_ORDER_PRODUCT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_UPGRADE_ORDER_PRODUCT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_UPGRADE_ORDER_PRODUCT_FAILURE,
			payload: response.data,
		});
	}
}

function* getOrderUsingID({ payload }) {
	try {
		const response = yield call(getOrderUsingId, payload);
		if (response.status === 200) {
			yield put({
				type: GET_ORDER_WITH_ID_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_ORDER_WITH_ID_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_ORDER_WITH_ID_FAILURE,
			payload: response.data,
		});
	}
}

function* getMacAddress({ payload }) {
	try {
		const response = yield call(getMacAddressService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_MAC_ADDRESS_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_MAC_ADDRESS_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_MAC_ADDRESS_FAILURE,
			payload: response.data,
		});
	}
}

function* updateOrder({ payload }) {
	try {
		const response = yield call(update, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_ORDER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_ORDER_FAILURE,
			payload: {},
		});
	}
}

function* toggleOrder({ payload }) {
	try {
		const response = yield call(toggle, payload);
		if (response.status === 200) {
			yield put({
				type: TOGGLE_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: TOGGLE_ORDER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: TOGGLE_ORDER_FAILURE,
			payload: response.data,
		});
	}
}

function* removeOrder({ payload }) {
	try {
		const response = yield call(remove, payload);
		if (response.status === 200) {
			yield put({
				type: REMOVE_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_ORDER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_ORDER_FAILURE,
			payload: response.data,
		});
	}
}

function* getExportOrderCSV({ payload }) {
	try {
		const response = yield call(getExportOrderCSVService, payload);
		if (response.status === 200) {
			yield put({
				type: EXPORT_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: EXPORT_ORDER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: EXPORT_ORDER_FAILURE,
			payload: {},
		});
	}
}

function* getOrderMerchantInvoice({ payload }) {
	try {
		const response = yield call(getOrderMerchantInvoiceService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_ORDER_MERCHANT_INVOICE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_ORDER_MERCHANT_INVOICE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_ORDER_MERCHANT_INVOICE_FAILURE,
			payload: {},
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_ORDER_REQUEST, createOrder);
	yield takeEvery(UPDATE_ORDER_REQUEST, updateOrder);
	yield takeEvery(REMOVE_ORDER_REQUEST, removeOrder);
	yield takeEvery(TOGGLE_ORDER_REQUEST, toggleOrder);
	yield takeEvery(GET_ORDER_REQUEST, getOrders);
	yield takeEvery(GET_INVOICE_REQUEST, getInvoice);
	yield takeEvery(GET_UPGRADE_PRODUCT_REQUEST, getUpgradeProduct);
	yield takeEvery(GET_UPGRADE_ORDER_PRODUCT_REQUEST, upgradeOrderProduct);
	yield takeEvery(GET_ORDER_WITH_ID_REQUEST, getOrderUsingID);
	yield takeEvery(GET_MAC_ADDRESS_REQUEST, getMacAddress);
	yield takeEvery(EXPORT_ORDER_REQUEST, getExportOrderCSV);
	yield takeEvery(
		GET_ORDER_MERCHANT_INVOICE_REQUEST,
		getOrderMerchantInvoice
	);
}

export default saga;
