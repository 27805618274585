import { call, put, takeEvery } from "redux-saga/effects";
import {
	GET_USER,
	GET_USER_FAILURE,
	GET_USER_SUCCESS,
	GET_USER_WALLET,
	GET_USER_WALLET_FAILURE,
	GET_USER_WALLET_SUCCESS,
	GET_NODES,
	GET_NODES_FAILURE,
	GET_NODES_SUCCESS,
	GET_CONTRACT_NFT,
	GET_CONTRACT_NFT_FAILURE,
	GET_CONTRACT_NFT_SUCCESS,
} from "./actionTypes";
import {
	getUser,
	userWalletService,
	getNodeService,
	getContractNFTService,
} from "./services";

function* getUserDetails({ payload }) {
	try {
		const response = yield call(getUser, payload);
		if (response.status === 200) {
			yield put({
				type: GET_USER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_USER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_USER_FAILURE,
			payload: err,
		});
	}
}

function* getUserWallets({ payload }) {
	try {
		const response = yield call(userWalletService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_USER_WALLET_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_USER_WALLET_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_USER_WALLET_FAILURE,
			payload: err,
		});
	}
}

function* getNodes({ payload }) {
	try {
		const response = yield call(getNodeService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_NODES_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_NODES_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_NODES_FAILURE,
			payload: err,
		});
	}
}

function* getContractNFTDetails({ payload }) {
	try {
		const response = yield call(getContractNFTService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_CONTRACT_NFT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_CONTRACT_NFT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_CONTRACT_NFT_FAILURE,
			payload: err,
		});
	}
}

function* userSaga() {
	yield takeEvery(GET_USER, getUserDetails);
	yield takeEvery(GET_USER_WALLET, getUserWallets);
	yield takeEvery(GET_NODES, getNodes);
	yield takeEvery(GET_CONTRACT_NFT, getContractNFTDetails);
}

export default userSaga;
