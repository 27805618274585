import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_LENDING_POOL_REQUEST,
	UPDATE_LENDING_POOL_REQUEST,
	REMOVE_LENDING_POOL_REQUEST,
	APPROVE_LENDING_POOL_REQUEST,
	GET_ALL_LENDING_POOL_SUCCESS,
	GET_ALL_LENDING_POOL_REQUEST,
	GET_ALL_LENDING_POOL_FAILURE,
	CANCEL_USER_LENDING_POOL_SUCCESS,
	CANCEL_USER_LENDING_POOL_REQUEST,
	CANCEL_USER_LENDING_POOL_FAILURE,
	GET_LENDING_SETTING_SUCCESS,
	GET_LENDING_SETTING_REQUEST,
	GET_LENDING_SETTING_FAILURE,
	UPDATE_LENDING_SETTING_SUCCESS,
	UPDATE_LENDING_SETTING_REQUEST,
	UPDATE_LENDING_SETTING_FAILURE,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const create = (data) => {
	return {
		type: CREATE_LENDING_POOL_REQUEST,
		payload: data,
	};
};

export const update = (data) => {
	return {
		type: UPDATE_LENDING_POOL_REQUEST,
		payload: data,
	};
};

export const remove = (data) => {
	return {
		type: REMOVE_LENDING_POOL_REQUEST,
		payload: data,
	};
};

export const approve = (data) => {
	return {
		type: APPROVE_LENDING_POOL_REQUEST,
		payload: data,
	};
};
export const getAll = (data) => {
	return {
		type: GET_ALL_LENDING_POOL_REQUEST,
		payload: data,
	};
};

export const cancelUserLending = (data) => {
	return {
		type: CANCEL_USER_LENDING_POOL_REQUEST,
		payload: data,
	};
};

export const getLendingSetting = (data) => {
	return {
		type: GET_LENDING_SETTING_REQUEST,
		payload: data,
	};
};

export const updateLendingSetting = (data) => {
	return {
		type: UPDATE_LENDING_SETTING_REQUEST,
		payload: data,
	};
};
