import { all, fork } from "redux-saga/effects";

//public
import AuthSaga from "./auth/saga";
import LayoutSaga from "./layout/saga";
import networkInfoSaga from "./networkInfo/saga";
import adminSaga from "./admin/saga";
import dashboardSaga from "./dashboard/saga";
import userSaga from "./user/saga";
import metaSaga from "./meta/saga";
import nftSaga from "./nft/saga";
import partnerSaga from "./partner/saga";
import softwarePackageSaga from "./softwarePackage/saga";
import chainSettingSaga from "./chainSetting/saga";
import cloudUsageSaga from "./cloudUsage/saga";
import productSaga from "./product/saga";
import customerSaga from "./customer/saga";
import orderSaga from "./order/saga";
import voucherCodePackageSaga from "./voucherCodePackage/saga";
import stakingPoolSaga from "./stakingPool/saga";
import lendingPoolSaga from "./lendingPool/saga";
import productOptionSaga from "./productOption/saga";
import shippingSaga from "./shipping/saga";
import taxSaga from "./tax/saga";

export default function* rootSaga() {
	yield all([
		fork(AuthSaga),
		fork(productSaga),
		fork(customerSaga),
		fork(orderSaga),
		fork(LayoutSaga),
		fork(adminSaga),
		fork(dashboardSaga),
		fork(networkInfoSaga),
		fork(userSaga),
		fork(metaSaga),
		fork(nftSaga),
		fork(partnerSaga),
		fork(softwarePackageSaga),
		fork(chainSettingSaga),
		fork(cloudUsageSaga),
		fork(voucherCodePackageSaga),
		fork(stakingPoolSaga),
		fork(lendingPoolSaga),
		fork(productOptionSaga),
		fork(shippingSaga),
		fork(taxSaga),
	]);
}
