import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_CLOUD_USAGE_REQUEST,
	CREATE_CLOUD_USAGE_SUCCESS,
	CREATE_CLOUD_USAGE_FAILURE,
	UPDATE_CLOUD_USAGE_SUCCESS,
	UPDATE_CLOUD_USAGE_REQUEST,
	UPDATE_CLOUD_USAGE_FAILURE,
	REMOVE_CLOUD_USAGE_FAILURE,
	REMOVE_CLOUD_USAGE_SUCCESS,
	REMOVE_CLOUD_USAGE_REQUEST,
	TOGGLE_CLOUD_USAGE_SUCCESS,
	TOGGLE_CLOUD_USAGE_REQUEST,
	TOGGLE_CLOUD_USAGE_FAILURE,
	GET_CLOUD_USAGE_SUCCESS,
	GET_CLOUD_USAGE_REQUEST,
	GET_CLOUD_USAGE_FAILURE,
} from "./actionTypes";

import { create, update, remove, toggle, getCloudUsage } from "./services";

function* createCloudUsage({ payload }) {
	try {
		const response = yield call(create, payload);
		if (response.status === 200) {
			yield put({
				type: CREATE_CLOUD_USAGE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_CLOUD_USAGE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_CLOUD_USAGE_FAILURE,
			payload: response.data,
		});
	}
}

function* updateCloudUsage({ payload }) {
	try {
		const response = yield call(update, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_CLOUD_USAGE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_CLOUD_USAGE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_CLOUD_USAGE_FAILURE,
			payload: {},
		});
	}
}

function* toggleCloudUsage({ payload }) {
	try {
		const response = yield call(toggle, payload);
		if (response.status === 200) {
			yield put({
				type: TOGGLE_CLOUD_USAGE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: TOGGLE_CLOUD_USAGE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: TOGGLE_CLOUD_USAGE_FAILURE,
			payload: response.data,
		});
	}
}

function* removeCloudUsage({ payload }) {
	try {
		const response = yield call(remove, payload);
		if (response.status === 200) {
			yield put({
				type: REMOVE_CLOUD_USAGE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_CLOUD_USAGE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_CLOUD_USAGE_FAILURE,
			payload: response.data,
		});
	}
}

function* getCloud({ payload }) {
	try {
		const response = yield call(getCloudUsage, payload);
		if (response.status === 200) {
			yield put({
				type: GET_CLOUD_USAGE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_CLOUD_USAGE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_CLOUD_USAGE_FAILURE,
			payload: response.data,
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_CLOUD_USAGE_REQUEST, createCloudUsage);
	yield takeEvery(UPDATE_CLOUD_USAGE_REQUEST, updateCloudUsage);
	yield takeEvery(REMOVE_CLOUD_USAGE_REQUEST, removeCloudUsage);
	yield takeEvery(TOGGLE_CLOUD_USAGE_REQUEST, toggleCloudUsage);
	yield takeEvery(GET_CLOUD_USAGE_REQUEST, getCloud);
}

export default saga;
