import {
	GET_USER,
	GET_USER_FAILURE,
	GET_USER_SUCCESS,
	CLEAR_RESPONSE,
	GET_USER_WALLET,
	GET_USER_WALLET_FAILURE,
	GET_USER_WALLET_SUCCESS,
	GET_NODES,
	GET_CONTRACT_NFT,
	GET_CONTRACT_NFT_FAILURE,
	GET_CONTRACT_NFT_SUCCESS,
	GET_NODES_FAILURE,
	GET_NODES_SUCCESS,
	CLEAR_NODES,
	// ERRORS,
} from "./actionTypes";

export const getUserWallets = (data) => {
	return {
		type: GET_USER_WALLET,
		payload: data,
	};
};

export const getUser = (data) => {
	return {
		type: GET_USER,
		payload: data,
	};
};

export const getNodes = (data) => {
	return {
		type: GET_NODES,
		payload: data,
	};
};

export const getContractNFT = (data) => {
	return {
		type: GET_CONTRACT_NFT,
		payload: data,
	};
};

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};
export const clearNodes = () => {
	return {
		type: CLEAR_NODES,
		payload: {},
	};
};
