import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_PRODUCT_OPTION_REQUEST,
	CREATE_PRODUCT_OPTION_SUCCESS,
	CREATE_PRODUCT_OPTION_FAILURE,
	UPDATE_PRODUCT_OPTION_SUCCESS,
	UPDATE_PRODUCT_OPTION_REQUEST,
	UPDATE_PRODUCT_OPTION_FAILURE,
	REMOVE_PRODUCT_OPTION_FAILURE,
	REMOVE_PRODUCT_OPTION_SUCCESS,
	REMOVE_PRODUCT_OPTION_REQUEST,
	TOGGLE_PRODUCT_OPTION_SUCCESS,
	TOGGLE_PRODUCT_OPTION_REQUEST,
	TOGGLE_PRODUCT_OPTION_FAILURE,
} from "./actionTypes";

import { create, update, remove, toggle } from "./services";

function* createSoftwarePackage({ payload }) {
	try {
		const response = yield call(create, payload);
		if (response.status === 200) {
			yield put({
				type: CREATE_PRODUCT_OPTION_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_PRODUCT_OPTION_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_PRODUCT_OPTION_FAILURE,
			payload: response.data,
		});
	}
}

function* updateSoftwarePackage({ payload }) {
	try {
		const response = yield call(update, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_PRODUCT_OPTION_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_PRODUCT_OPTION_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_PRODUCT_OPTION_FAILURE,
			payload: {},
		});
	}
}

function* toggleSoftwarePackage({ payload }) {
	try {
		const response = yield call(toggle, payload);
		if (response.status === 200) {
			yield put({
				type: TOGGLE_PRODUCT_OPTION_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: TOGGLE_PRODUCT_OPTION_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: TOGGLE_PRODUCT_OPTION_FAILURE,
			payload: response.data,
		});
	}
}

function* removeSoftwarePackage({ payload }) {
	try {
		const response = yield call(remove, payload);
		if (response.status === 200) {
			yield put({
				type: REMOVE_PRODUCT_OPTION_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_PRODUCT_OPTION_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_PRODUCT_OPTION_FAILURE,
			payload: response.data,
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_PRODUCT_OPTION_REQUEST, createSoftwarePackage);
	yield takeEvery(UPDATE_PRODUCT_OPTION_REQUEST, updateSoftwarePackage);
	yield takeEvery(REMOVE_PRODUCT_OPTION_REQUEST, removeSoftwarePackage);
	yield takeEvery(TOGGLE_PRODUCT_OPTION_REQUEST, toggleSoftwarePackage);
}

export default saga;
