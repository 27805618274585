import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Row,
	Container,
	CardHeader,
	CardTitle,
	Table,
	Input,
	Label,
	Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import isEmpty from "../../utils/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import { getMeta, update, clearResponse } from "../../store/meta/actions";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";

const Setting = (props) => {
	const dispatch = useDispatch();
	const { metaDetails, auth } = useSelector((state) => ({
		metaDetails: state.meta,
		auth: state.auth,
	}));

	const { response } = metaDetails;

	useEffect(() => {
		dispatch(getMeta());
	}, []);

	useEffect(() => {
		if (response && response.code === "200") {
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
					dispatch(getMeta());
				},
			});
		} else if (response && response?.msg) {
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const validateMarketPrice = useFormik({
		enableReinitialize: true,
		initialValues: {
			marketPrice: metaDetails?.details?.marketPrice ?? 0,
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				Swal.fire({
					title: "Are you sure?",
					text: "Do you really want to update?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Confirm",
				}).then((result) => {
					if (result.value) {
						dispatch(
							update({
								name: Object.keys(values)?.[0],
								value: Object.values(values)?.[0],
							})
						);
					}
				});
			}
		},
	});

	const validateBaseTokenEmission = useFormik({
		enableReinitialize: true,
		initialValues: {
			baseTokenEmission: metaDetails?.details?.baseTokenEmission ?? 0,
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				Swal.fire({
					title: "Are you sure?",
					text: "Do you really want to update?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Confirm",
				}).then((result) => {
					if (result.value) {
						dispatch(
							update({
								name: Object.keys(values)?.[0],
								value: Object.values(values)?.[0],
							})
						);
					}
				});
			}
		},
	});
	const validateBaseValue = useFormik({
		enableReinitialize: true,
		initialValues: {
			baseValue: metaDetails?.details?.baseValue ?? 0,
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				Swal.fire({
					title: "Are you sure?",
					text: "Do you really want to update?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Confirm",
				}).then((result) => {
					if (result.value) {
						dispatch(
							update({
								name: Object.keys(values)?.[0],
								value: Object.values(values)?.[0],
							})
						);
					}
				});
			}
		},
	});

	const validateRewardCapping = useFormik({
		enableReinitialize: true,
		initialValues: {
			rewardCapping: metaDetails?.details?.rewardCapping ?? 0,
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				Swal.fire({
					title: "Are you sure?",
					text: "Do you really want to update?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Confirm",
				}).then((result) => {
					if (result.value) {
						dispatch(
							update({
								name: Object.keys(values)?.[0],
								value: Object.values(values)?.[0],
							})
						);
					}
				});
			}
		},
	});
	const validateRewardClaimDelay = useFormik({
		enableReinitialize: true,
		initialValues: {
			rewardClaimDelay: metaDetails?.details?.rewardClaimDelay ?? 0,
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				Swal.fire({
					title: "Are you sure?",
					text: "Do you really want to update?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Confirm",
				}).then((result) => {
					if (result.value) {
						dispatch(
							update({
								name: Object.keys(values)?.[0],
								value: Object.values(values)?.[0],
							})
						);
					}
				});
			}
		},
	});
	const validateRewardDuration = useFormik({
		enableReinitialize: true,
		initialValues: {
			rewardDuration: metaDetails?.details?.rewardDuration ?? 0,
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				Swal.fire({
					title: "Are you sure?",
					text: "Do you really want to update?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Confirm",
				}).then((result) => {
					if (result.value) {
						dispatch(
							update({
								name: Object.keys(values)?.[0],
								value: Object.values(values)?.[0],
							})
						);
					}
				});
			}
		},
	});
	const validateCLFIValue = useFormik({
		enableReinitialize: true,
		initialValues: {
			clfiValue: metaDetails?.details?.clfiValue ?? 0,
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				Swal.fire({
					title: "Are you sure?",
					text: "Do you really want to update?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Confirm",
				}).then((result) => {
					if (result.value) {
						dispatch(
							update({
								name: Object.keys(values)?.[0],
								value: Object.values(values)?.[0],
							})
						);
					}
				});
			}
		},
	});

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>LFI MARKET PRICE | LFI</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Meta Data" breadcrumbItem="Setting" />

					<Row>
						<Card className="border border-default">
							<CardHeader className="align-items-center d-flex bg-transparent border-default">
								<CardTitle className="mb-0 flex-grow-1">
									<h5 className="my-0 text-primary">
										<i className="mdi mdi-bullseye-arrow me-3"></i>
										LFI TOKEN
									</h5>
								</CardTitle>
								{/* <div className="flex-shrink-0">
									<div className="d-flex flex-wrap gap-2 mb-0 my-n1">
										<button
											type="button"
											className="btn btn-primary waves-effect waves-light"
											// onClick={() =>
											// 	this.openModal(
											// 		"isTransferModalOpen",
											// 		"deposit"
											// 	)
											// }
										>
											Transfer Balance
										</button>
									</div>
								</div> */}
							</CardHeader>
							<CardBody>
								<HasAnyPermission
									permission={[
										"LFI setting update",
										"LFI setting view",
									]}
								>
									<Row className="mt-3">
										<Col sm={3}>
											<h5>Base Token Emission</h5>
										</Col>
										<Col>
											<div className="mb-1">
												<fieldset
													disabled={
														!hasPermission(
															[
																"LFI setting update",
															],
															auth.currentUserRolePermissions
														)
													}
												>
													<Form
														onSubmit={(e) => {
															e.preventDefault();
															validateBaseTokenEmission.handleSubmit();
															return false;
														}}
														className="row gx-3 gy-2 align-items-center"
													>
														<Col sm={3}>
															<Input
																type="text"
																name="baseTokenEmission"
																className="form-control"
																onChange={
																	validateBaseTokenEmission.handleChange
																}
																onBlur={
																	validateBaseTokenEmission.handleBlur
																}
																value={
																	validateBaseTokenEmission
																		.values
																		.baseTokenEmission ||
																	""
																}
															/>
														</Col>
														<div className="col-auto">
															<button
																type="submit"
																className="btn btn-primary"
															>
																Update
															</button>
														</div>
													</Form>
												</fieldset>
											</div>
										</Col>
									</Row>

									<Row className="mt-3">
										<Col sm={3}>
											<h5>Base Value</h5>
										</Col>
										<Col>
											<div className="mb-1">
												<fieldset
													disabled={
														!hasPermission(
															[
																"LFI setting update",
															],
															auth.currentUserRolePermissions
														)
													}
												>
													<Form
														onSubmit={(e) => {
															e.preventDefault();
															validateBaseValue.handleSubmit();
															return false;
														}}
														className="row gx-3 gy-2 align-items-center"
													>
														<Col sm={3}>
															<Input
																type="text"
																name="baseValue"
																className="form-control"
																onChange={
																	validateBaseValue.handleChange
																}
																onBlur={
																	validateBaseValue.handleBlur
																}
																value={
																	validateBaseValue
																		.values
																		.baseValue ||
																	""
																}
															/>
														</Col>
														<div className="col-auto">
															<button
																type="submit"
																className="btn btn-primary"
															>
																Update
															</button>
														</div>
													</Form>
												</fieldset>
											</div>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col sm={3}>
											<h5>Reward Capping</h5>
										</Col>
										<Col>
											<div className="mb-1">
												<fieldset
													disabled={
														!hasPermission(
															[
																"LFI setting update",
															],
															auth.currentUserRolePermissions
														)
													}
												>
													<Form
														onSubmit={(e) => {
															e.preventDefault();
															validateRewardCapping.handleSubmit();
															return false;
														}}
														className="row gx-3 gy-2 align-items-center"
													>
														<Col sm={3}>
															<Input
																type="text"
																name="rewardCapping"
																className="form-control"
																onChange={
																	validateRewardCapping.handleChange
																}
																onBlur={
																	validateRewardCapping.handleBlur
																}
																value={
																	validateRewardCapping
																		.values
																		.rewardCapping ||
																	""
																}
															/>
														</Col>
														<div className="col-auto">
															<button
																type="submit"
																className="btn btn-primary"
															>
																Update
															</button>
														</div>
													</Form>
												</fieldset>
											</div>
										</Col>
									</Row>

									<Row className="mt-3">
										<Col sm={3}>
											<h5>
												Reward Claim Delay In Seconds
											</h5>
										</Col>
										<Col>
											<div className="mb-1">
												<fieldset
													disabled={
														!hasPermission(
															[
																"LFI setting update",
															],
															auth.currentUserRolePermissions
														)
													}
												>
													<Form
														onSubmit={(e) => {
															e.preventDefault();
															validateRewardClaimDelay.handleSubmit();
															return false;
														}}
														className="row gx-3 gy-2 align-items-center"
													>
														<Col sm={3}>
															<Input
																type="text"
																name="rewardClaimDelay"
																className="form-control"
																onChange={
																	validateRewardClaimDelay.handleChange
																}
																onBlur={
																	validateRewardClaimDelay.handleBlur
																}
																value={
																	validateRewardClaimDelay
																		.values
																		.rewardClaimDelay ||
																	""
																}
															/>
														</Col>
														<div className="col-auto">
															<button
																type="submit"
																className="btn btn-primary"
															>
																Update
															</button>
														</div>
													</Form>
												</fieldset>
											</div>
										</Col>
									</Row>

									<Row className="mt-3">
										<Col sm={3}>
											<h5>Reward Duration In Seconds</h5>
										</Col>
										<Col>
											<div className="mb-1">
												<fieldset
													disabled={
														!hasPermission(
															[
																"LFI setting update",
															],
															auth.currentUserRolePermissions
														)
													}
												>
													<Form
														onSubmit={(e) => {
															e.preventDefault();
															validateRewardDuration.handleSubmit();
															return false;
														}}
														className="row gx-3 gy-2 align-items-center"
													>
														<Col sm={3}>
															<Input
																type="text"
																name="rewardDuration"
																className="form-control"
																onChange={
																	validateRewardDuration.handleChange
																}
																onBlur={
																	validateRewardDuration.handleBlur
																}
																value={
																	validateRewardDuration
																		.values
																		.rewardDuration ||
																	""
																}
															/>
														</Col>
														<div className="col-auto">
															<button
																type="submit"
																className="btn btn-primary"
															>
																Update
															</button>
														</div>
													</Form>
												</fieldset>
											</div>
										</Col>
									</Row>

									<Row className="mt-3">
										<Col sm={3}>
											<h5>CLFI Value</h5>
										</Col>
										<Col>
											<div className="mb-1">
												<fieldset
													disabled={
														!hasPermission(
															[
																"LFI setting update",
															],
															auth.currentUserRolePermissions
														)
													}
												>
													<Form
														onSubmit={(e) => {
															e.preventDefault();
															validateCLFIValue.handleSubmit();
															return false;
														}}
														className="row gx-3 gy-2 align-items-center"
													>
														<Col sm={3}>
															<Input
																type="text"
																name="clfiValue "
																className="form-control"
																onChange={
																	validateCLFIValue.handleChange
																}
																onBlur={
																	validateCLFIValue.handleBlur
																}
																value={
																	validateCLFIValue
																		.values
																		.clfiValue ||
																	""
																}
															/>
														</Col>
														<div className="col-auto">
															<button
																type="submit"
																className="btn btn-primary"
															>
																Update
															</button>
														</div>
													</Form>
												</fieldset>
											</div>
										</Col>
									</Row>

									<Row className="mt-3">
										<Col sm={3}>
											<h5>Market Price</h5>
										</Col>
										<Col>
											<div className="mb-1">
												<fieldset
													disabled={
														!hasPermission(
															[
																"LFI setting update",
															],
															auth.currentUserRolePermissions
														)
													}
												>
													<Form
														onSubmit={(e) => {
															e.preventDefault();
															validateMarketPrice.handleSubmit();
															return false;
														}}
														className="row gx-3 gy-2 align-items-center"
													>
														<Col sm={3}>
															<Input
																type="text"
																name="marketPrice"
																className="form-control"
																onChange={
																	validateMarketPrice.handleChange
																}
																onBlur={
																	validateMarketPrice.handleBlur
																}
																value={
																	validateMarketPrice
																		.values
																		.marketPrice ||
																	""
																}
															/>
														</Col>
														<div className="col-auto">
															<button
																type="submit"
																className="btn btn-primary"
															>
																Update
															</button>
														</div>
													</Form>
												</fieldset>
											</div>
										</Col>
									</Row>
								</HasAnyPermission>
							</CardBody>
						</Card>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Setting;
