import React, { useEffect, useState, useRef, useMemo } from "react";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Form,
	FormGroup,
	Input,
	InputGroup,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
	UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import Swal from "sweetalert2";
import Select from "react-select";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import makeAnimated from "react-select/animated";
import moment from "moment";
import Contract from "./Contract";
// import NFTDetails from "./NFTDetails";
import {
	getUser,
	getUserWallets,
	clearResponse,
} from "../../store/user/actions";

const UserDetails = (props) => {
	const animatedComponents = makeAnimated();
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState("Contract");
	const { response, auth, user, userWalletInfo } = useSelector((state) => ({
		response: state.user.response,
		user: state.user.user,
		userWalletInfo: state.user.userWalletInfo,
		auth: state.auth,
	}));
	const _id = props.match.params?._id;
	useEffect(() => {
		if (_id) {
			dispatch(getUser({ _id }));
			dispatch(getUserWallets({ _id }));
		}
	}, [_id]);

	useEffect(() => {
		if (response && response.code === "200") {
			dispatch(getUser({ _id }));
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		} else if (response && response?.msg) {
			dispatch(getUser({ _id }));
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	// const block = (_id) => {
	// 	Swal.fire({
	// 		title: "Are you sure?",
	// 		text: "Do you really want to update?",
	// 		icon: "warning",
	// 		showCancelButton: true,
	// 		confirmButtonColor: "#3085d6",
	// 		cancelButtonColor: "#d33",
	// 		confirmButtonText: "Confirm",
	// 	}).then((result) => {
	// 		if (result.value) {
	// 			dispatch(blockUser({ _id }));
	// 		}
	// 	});
	// };

	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					{/* Render Breadcrumb */}
					<Breadcrumbs title="Apps" breadcrumbItem="User Details" />

					<div className="container-fluid">
						<Row>
							<Col xl={12} lg={12}>
								<Card>
									<CardBody>
										<Row>
											<div className="col-sm order-2 order-sm-1">
												<div className="d-flex align-items-start mt-3 mt-sm-0">
													<div className="flex-shrink-0">
														<div className="avatar-xl me-3">
															<Avatar
																className=""
																color={
																	"#74788d"
																}
																name={`${
																	user.name ??
																	user.identifier ??
																	"-"
																}`}
																round={true}
																size={60}
															/>
														</div>
													</div>
													<div className="flex-grow-1">
														<div>
															<h5 className="font-size-16 mb-1">
																{`${
																	user.identifier
																		? `${
																				user.name ??
																				"-"
																		  }  ( ${
																				user.identifier ??
																				"-"
																		  } )`
																		: "-"
																}`}
															</h5>
															<p className="text-muted font-size-13">
																{`${
																	user.email ??
																	"-"
																} `}
															</p>

															<div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
																<div>
																	<button
																		type="button"
																		data-tip
																		data-for="status"
																		className={`btn waves-effect btn-label waves-light w-sm ${
																			user?.status
																				? "btn-danger"
																				: "btn-success"
																		} label-icon`}
																		onClick={(
																			e
																		) => {
																			e.preventDefault();
																			// block(
																			// 	user._id
																			// );
																		}}
																	>
																		<i
																			className={`bx ${
																				user?.status ===
																				"inactive"
																					? "bx-x"
																					: "bx-check-double"
																			} label-icon`}
																		></i>{" "}
																		{user?.status ===
																		"inactive"
																			? "Inactive"
																			: "Active"}
																	</button>
																</div>
																<div></div>
															</div>
														</div>
													</div>
													<div className="flex-grow-1">
														<h5 className="font-size-16 mb-1">
															{`User wallet address: ${
																userWalletInfo?.address
																	? userWalletInfo.address
																	: "Not available"
															}`}
														</h5>
														<p className="text-muted font-size-13">
															{`Type:  ${
																userWalletInfo?.type
																	? userWalletInfo.type
																	: "Not available"
															} `}
														</p>
													</div>
												</div>
											</div>
											<div className="col-sm-auto order-1 order-sm-2">
												<div className="d-flex align-items-start justify-content-end gap-2">
													<div>
														<h5 className="font-size-16 mb-1">
															{`Account Open Date: ${moment(
																user?.created_at
															).format("lll")}`}
														</h5>
													</div>
												</div>
											</div>
										</Row>

										<Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
											<NavItem>
												<NavLink
													to="#"
													className={classnames(
														{
															active:
																activeTab ===
																"Contract",
														},
														"px-3"
													)}
													onClick={() => {
														toggleTab("Contract");
													}}
												>
													Contracts Info
												</NavLink>
											</NavItem>
											{/* <NavItem>
												<NavLink
													to="#"
													className={classnames(
														{
															active:
																activeTab ===
																"NFTDetails",
														},
														"px-3"
													)}
													onClick={() => {
														toggleTab("NFTDetails");
													}}
												>
													NFT details
												</NavLink>
											</NavItem> */}
										</Nav>
									</CardBody>
								</Card>
								<TabContent activeTab={activeTab}>
									<TabPane tabId="Contract">
										{user?._id && (
											<Contract
												userDetails={user}
												activeTab={activeTab}
												userWalletInfo={userWalletInfo}
												history={props.history}
											/>
										)}
									</TabPane>
									{/* <TabPane tabId="NFTDetails">
										{user?._id && userWalletInfo?._id ? (
											<NFTDetails
												userDetails={user}
												userWalletInfo={userWalletInfo}
												activeTab={activeTab}
											/>
										) : (
											<Row>
												<Col
													className="text-center"
													lg="12"
												>
													<h4>NO NFT Details</h4>
												</Col>
											</Row>
										)}
									</TabPane> */}

									{/* 
									<TabPane tabId="UserWallet">
										<UserWallet
											userDetails={user}
											userWalletInfo={userWalletInfo}
											activeTab={activeTab}
										/>
									</TabPane>
									<TabPane tabId="UserTransaction">
										<UserTransaction
											activeTab={activeTab}
											userDetails={user}
										/>
									</TabPane>
									<TabPane tabId="SwapTransaction">
										<SwapTransaction
											activeTab={activeTab}
											userDetails={user}
										/>
									</TabPane>
									<TabPane tabId="BuyTransaction">
										<BuyTransaction
											activeTab={activeTab}
											userDetails={user}
										/>
									</TabPane>
									<TabPane tabId="FiatTransaction">
										<FiatTransaction
											activeTab={activeTab}
											userDetails={user}
										/>
									</TabPane> */}
								</TabContent>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
		</React.Fragment>
	);
};
export default UserDetails;
