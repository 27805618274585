import axios from "axios";
import { apiUrl } from "../../config";

export const create = (data) =>
	axios
		.post(`${apiUrl}/admin/store/tax`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data, _id) =>
	axios
		.put(`${apiUrl}/admin/store/tax/${data._id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const toggle = (data) =>
	axios
		.patch(`${apiUrl}/admin/store/tax/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const remove = (data) =>
	axios
		.delete(`${apiUrl}/admin/store/tax/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getTax = (data) =>
	axios
		.get(`${apiUrl}/admin/store/tax`)
		.then((response) => response)
		.catch((err) => err.response);

export const getCountryTax = (data) =>
	axios
		.get(`${apiUrl}/admin/store/tax/country/all`)
		.then((response) => response)
		.catch((err) => err.response);

export const updateCountryTaxService = (data) =>
	axios
		.put(`${apiUrl}/admin/store/tax/country/${data._id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const deleteCountryTaxService = (data) =>
	axios
		.delete(`${apiUrl}/admin/store/tax/country/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);
