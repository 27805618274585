import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_NFT_SVG_REQUEST,
	CREATE_NFT_SVG_SUCCESS,
	CREATE_NFT_SVG_FAILURE,
	UPDATE_NFT_SVG_SUCCESS,
	UPDATE_NFT_SVG_REQUEST,
	UPDATE_NFT_SVG_FAILURE,
	REMOVE_NFT_SVG_FAILURE,
	REMOVE_NFT_SVG_SUCCESS,
	REMOVE_NFT_SVG_REQUEST,
	TOGGLE_NFT_SVG_SUCCESS,
	TOGGLE_NFT_SVG_REQUEST,
	TOGGLE_NFT_SVG_FAILURE,
} from "./actionTypes";

import { create, update, remove, toggle } from "./services";

function* createBanner({ payload: { data } }) {
	try {
		const formData = new FormData();
		Object.entries(data).forEach(([key, value]) =>
			formData.append(
				key,
				Array.isArray(value) ? JSON.stringify(value) : value
			)
		);
		const response = yield call(create, formData);
		if (response.status === 200) {
			yield put({
				type: CREATE_NFT_SVG_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_NFT_SVG_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_NFT_SVG_FAILURE,
			payload: response.data,
		});
	}
}

function* updateBanner({ payload: { data } }) {
	try {
		const formData = new FormData();
		Object.entries(data).forEach(([key, value]) =>
			formData.append(
				key,
				Array.isArray(value) ? JSON.stringify(value) : value
			)
		);
		const response = yield call(update, formData, data._id);
		if (response.status === 200) {
			yield put({
				type: UPDATE_NFT_SVG_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_NFT_SVG_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_NFT_SVG_FAILURE,
			payload: response.data,
		});
	}
}

function* toggleBanner({ payload: { id } }) {
	try {
		const response = yield call(toggle, id);
		if (response.status === 200) {
			yield put({
				type: TOGGLE_NFT_SVG_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: TOGGLE_NFT_SVG_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: TOGGLE_NFT_SVG_FAILURE,
			payload: response.data,
		});
	}
}

function* removeBanner({ payload: { id } }) {
	try {
		const response = yield call(remove, id);
		if (response.status === 200) {
			yield put({
				type: REMOVE_NFT_SVG_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_NFT_SVG_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_NFT_SVG_FAILURE,
			payload: response.data,
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_NFT_SVG_REQUEST, createBanner);
	yield takeEvery(UPDATE_NFT_SVG_REQUEST, updateBanner);
	yield takeEvery(REMOVE_NFT_SVG_REQUEST, removeBanner);
	yield takeEvery(TOGGLE_NFT_SVG_REQUEST, toggleBanner);
}

export default saga;
