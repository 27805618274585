import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_TAX_REQUEST,
	UPDATE_TAX_REQUEST,
	REMOVE_TAX_REQUEST,
	TOGGLE_TAX_REQUEST,
	GET_TAX_SUCCESS,
	GET_TAX_REQUEST,
	GET_TAX_FAILURE,
	GET_COUNTRY_TAX_SUCCESS,
	GET_COUNTRY_TAX_REQUEST,
	GET_COUNTRY_TAX_FAILURE,
	UPDATE_COUNTRY_TAX_SUCCESS,
	UPDATE_COUNTRY_TAX_REQUEST,
	UPDATE_COUNTRY_TAX_FAILURE,
	DELETE_COUNTRY_TAX_SUCCESS,
	DELETE_COUNTRY_TAX_REQUEST,
	DELETE_COUNTRY_TAX_FAILURE,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const getAllTax = (data) => {
	return {
		type: GET_TAX_REQUEST,
		payload: data,
	};
};

export const create = (data) => {
	return {
		type: CREATE_TAX_REQUEST,
		payload: data,
	};
};

export const update = (data) => {
	return {
		type: UPDATE_TAX_REQUEST,
		payload: data,
	};
};

export const toggle = (data) => {
	return {
		type: TOGGLE_TAX_REQUEST,
		payload: data,
	};
};

export const remove = (data) => {
	return {
		type: REMOVE_TAX_REQUEST,
		payload: data,
	};
};

export const getAllCountryTax = (data) => {
	return {
		type: GET_COUNTRY_TAX_REQUEST,
		payload: data,
	};
};

export const updateCountryTax = (data) => {
	return {
		type: UPDATE_COUNTRY_TAX_REQUEST,
		payload: data,
	};
};
export const deleteCountryTax = (data) => {
	return {
		type: DELETE_COUNTRY_TAX_REQUEST,
		payload: data,
	};
};
