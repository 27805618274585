import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_CUSTOMER_SUCCESS,
	CREATE_CUSTOMER_FAILURE,
	UPDATE_CUSTOMER_SUCCESS,
	UPDATE_CUSTOMER_FAILURE,
	REMOVE_CUSTOMER_FAILURE,
	REMOVE_CUSTOMER_SUCCESS,
	TOGGLE_CUSTOMER_SUCCESS,
	TOGGLE_CUSTOMER_FAILURE,
	GET_CUSTOMER_REQUEST,
	GET_CUSTOMER_SUCCESS,
	GET_CUSTOMER_FAILURE,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	isLoading: false,
	customers: [],
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				isLoading: false,
			};
		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};
		case CREATE_CUSTOMER_SUCCESS:
		case CREATE_CUSTOMER_FAILURE:
		case UPDATE_CUSTOMER_SUCCESS:
		case UPDATE_CUSTOMER_FAILURE:
		case REMOVE_CUSTOMER_FAILURE:
		case REMOVE_CUSTOMER_SUCCESS:
		case TOGGLE_CUSTOMER_SUCCESS:
		case TOGGLE_CUSTOMER_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: {},
			};

		case GET_CUSTOMER_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case GET_CUSTOMER_SUCCESS:
			return {
				...state,
				isLoading: false,
				customers: action.payload?.data?.customers,
			};
		case GET_CUSTOMER_FAILURE:
			return {
				...state,
				isLoading: false,
				errors: {},
				customers: [],
			};

		default:
			return state;
	}
};

export default reducer;
