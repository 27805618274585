import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
	FormText,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import {
	getAllTax,
	getAllCountryTax,
	updateCountryTax,
	deleteCountryTax,
	clearResponse,
} from "../../store/tax/actions";
import { getCountries } from "../../store/shipping/actions";
import Swal from "sweetalert2";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CountryTax = (props) => {
	const dispatch = useDispatch();
	const animatedComponents = makeAnimated();

	const refreshTableData = useRef(null);
	const { tax, auth, countries } = useSelector((state) => ({
		tax: state.tax,
		countries: state.shipping.countries,
		auth: state.auth,
	}));
	const { response, countryTaxes, taxes } = tax;
	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [details, handleformData] = useState({});
	const [selectedTaxes, setSelectedTax] = useState({});

	useEffect(() => {
		if (response && response.code === "200") {
			refreshTableData.current();
			Swal.close();
			if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		} else if (response && response?.msg) {
			refreshTableData.current();
			Swal.close();
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	useEffect(() => {
		dispatch(getCountries());
		dispatch(getAllTax());
		dispatch(getAllCountryTax());
	}, []);

	const countryOptionDropdown = useMemo(() => {
		const result = countries?.map((item) => ({
			label: `${item.name}`,
			value: item._id,
		}));
		return result ?? [];
	}, [countries]);

	const taxOptionDropdown = useMemo(() => {
		const result = taxes?.map((item) => ({
			label: `${item.name}`,
			value: item._id,
			tax: item.value,
		}));
		return result ?? [];
	}, [taxes]);

	const handleCountryChange = (country) => {
		if (country?.value) {
			const countryTaxDetails = countryTaxes?.find(
				(countryTax) =>
					countryTax?.country?._id?.toString() === country.value
			);
			handleformData(countryTaxDetails);
		}
	};

	const handleTaxChange = (taxDetails, action) => {
		if (action?.removedValue) {
			validation.setFieldValue(`${action["removedValue"].value}`, null);
		}
		if (action?.removedValues) {
			action?.removedValues?.forEach((removedValue) => {
				validation.setFieldValue(`${removedValue.value}`, null);
			});
		}
		taxDetails?.forEach((tax) => {
			if (isEmpty(validation.values[`${tax.value}`])) {
				validation.setFieldValue(`${tax.value}`, tax.tax);
			}
		});
		setSelectedTax(taxDetails);
	};

	const removeItem = (data) => {
		if (!isEmpty(data?._id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to delete?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					dispatch(deleteCountryTax(data));
				}
			});
		}
	};

	const handleToggle = (data) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to update?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				Swal.fire({
					title: "Updating",
					html: "Please wait...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				dispatch(toggle(data));
			}
		});
	};

	const getInitialValue = useMemo(() => {
		let initialValues = {
			country: details?.country?._id
				? {
						label: `${details.country.name}`,
						value: details.country._id,
				  }
				: null,
			taxes: [],
		};
		const selectedTaxes = details?.taxes?.map((item) => {
			initialValues[`${item.tax._id}`] = item.taxValue;
			return {
				label: `${item.tax.name}`,
				value: item.tax._id,
				tax: item.taxValue,
			};
		});
		initialValues.taxes = selectedTaxes ? selectedTaxes : [];
		return initialValues;
	}, [details]);

	function yupOptionValidation(values) {
		return values.length ? true : false;
	}

	const validationSchema = Yup.object()
		.shape({
			country: Yup.object().required("Please select country."),
			taxes: Yup.array(
				Yup.object({
					value: Yup.string().required("Field is required"),
				})
			).test(
				"Field field is required ",
				"Field field is required",
				yupOptionValidation
			),
		})
		.when((values, schema) => {
			let validationData = {};
			values.taxes.forEach((tax) => {
				validationData[`${tax.value}`] = Yup.string().required(
					`${tax.label} tax is required`
				);
			});
			return schema.shape(validationData);
		});

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: getInitialValue,
		validationSchema: validationSchema,
		onSubmit: (values) => {
			let taxes = values.taxes.map((tax) => ({
				tax: tax.value,
				taxValue: values[`${tax.value}`] ?? tax.tax,
				active: true,
			}));
			const updateDetails = {
				country: values.country?.value,
				_id: values.country?.value,
				taxes: taxes,
			};
			Swal.fire({
				title: "Updating",
				html: "Please wait...",
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			dispatch(updateCountryTax(updateDetails));
		},
	};

	const validation = useFormik(useFormikOptions);
	const handleAddEditModal = (data = null) => {
		if (!isEmpty(data) && data?._id) {
			handleformData(data);
		} else {
			handleformData({});
			validation.resetForm();
		}
		toggleAddEditModal(!isOpenAddEditModal);
	};

	const columns = () => [
		{
			label: "Country Name",
			name: "countryName",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Country Code",
			name: "countryCode",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Tax (%)",
			name: "taxes",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (taxes) => {
					return (
						<div className="d-flex flex-wrap gap-2 mt-1">
							{taxes.map((tax, index) => (
								<span key={`tax-span-${index}`} className="">
									{`${tax["tax"]?.name}`}: {`${tax.taxValue}`}
									{taxes.length !== index + 1 ? "," : ""}
								</span>
							))}
						</div>
					);
				},
			},
		},

		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: hasPermission(
					["partner update", "partner delete", "partner view"],
					auth.currentUserRolePermissions
				),
				viewColumns: hasPermission(
					["partner update", "partner delete", "partner view"],
					auth.currentUserRolePermissions
				),
				customBodyRender: (data) => {
					return (
						<div className="text-center">
							<HasAnyPermission
								permission={["partner update", "partner view"]}
							>
								<button
									onClick={(e) =>
										handleAddEditModal({ ...data })
									}
									type="button"
									className="btn btn-soft-primary waves-effect waves-light"
								>
									<i className="bx bx-edit-alt font-size-16 align-middle"></i>
								</button>
							</HasAnyPermission>
							&nbsp;
							<HasAnyPermission permission={["partner delete"]}>
								<button
									onClick={(e) => removeItem({ ...data })}
									type="button"
									className="btn btn-soft-danger waves-effect waves-light"
								>
									<i className="bx bx-trash font-size-16 align-middle"></i>
								</button>
							</HasAnyPermission>
						</div>
					);
				},
			},
		},
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				action: item,
				active: { active: item.active, _id: item._id },
				countryName: item.country?.name,
				countryCode: item.country?.code,
			};
		});
	};

	const couldHaveAddUpdatePermission = () => {
		const isUpdatePermission = hasPermission(
			["partner update"],
			auth.currentUserRolePermissions
		);
		const isAddPermission = hasPermission(
			["partner add"],
			auth.currentUserRolePermissions
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details._id)) return true;
		else if (isAddPermission && isEmpty(details._id)) return true;
		else return false;
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Country Tax | LFI</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Country Tax" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"partner list",
														]}
													>
														<ReactDataTable
															url={`${apiUrl}/admin/store/tax/country/pagination`}
															columns={columns()}
															resultFormatter={
																resultFormatter
															}
															setRefresh={
																refreshTableData
															}
															disableFilterIcon={
																false
															}
															disableSearchIcon={
																false
															}
															origin={
																<div className="row">
																	<div className="col-auto h4">
																		Country
																		Tax
																		&nbsp;
																		<HasAnyPermission
																			permission={[
																				"partner add",
																			]}
																		>
																			<button
																				onClick={() => {
																					handleAddEditModal();
																				}}
																				type="button"
																				className="btn btn-primary waves-effect waves-light"
																			>
																				<i className="bx bx-plus-medical font-size-16 align-middle"></i>
																			</button>
																		</HasAnyPermission>
																	</div>
																</div>
															}
															rowsPerPage={10}
														/>
													</HasAnyPermission>

													<Modal
														isOpen={
															isOpenAddEditModal
														}
														toggle={
															handleAddEditModal
														}
														size="lg"
														centered={true}
													>
														<ModalHeader
															toggle={
																handleAddEditModal
															}
															tag="h4"
														>
															{details?._id
																? "Edit"
																: "Add"}
														</ModalHeader>
														<ModalBody>
															<fieldset
																disabled={
																	!couldHaveAddUpdatePermission()
																}
															>
																<Form
																	onSubmit={(
																		e
																	) => {
																		e.preventDefault();
																		validation.handleSubmit();
																		return false;
																	}}
																>
																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Select
																					country
																				</Label>
																				<Select
																					name="country"
																					isDisabled={
																						details?._id
																							? true
																							: false
																					}
																					value={
																						validation
																							.values
																							.country ||
																						""
																					}
																					placeholder="Select country"
																					onChange={(
																						value,
																						action
																					) => {
																						validation.setFieldValue(
																							"country",
																							value
																						);
																						handleCountryChange(
																							value
																						);
																					}}
																					options={
																						countryOptionDropdown
																					}
																					classNamePrefix="select2-selection"
																					onSelectResetsInput={
																						true
																					}
																					closeMenuOnSelect={
																						true
																					}
																					components={
																						animatedComponents
																					}
																					required
																				/>
																				{validation
																					.touched
																					.country &&
																				validation
																					.errors
																					.country ? (
																					<FormFeedback
																						className="error"
																						type="invalid"
																					>
																						{
																							validation
																								.errors
																								.country
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>
																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Select
																					Taxes
																				</Label>
																				<Select
																					name="taxes"
																					placeholder="Select taxes"
																					value={
																						validation
																							.values
																							.taxes ||
																						""
																					}
																					onChange={(
																						value,
																						action
																					) => {
																						validation.setFieldValue(
																							"taxes",
																							value
																						);
																						handleTaxChange(
																							value,
																							action
																						);
																					}}
																					isMulti={
																						true
																					}
																					options={
																						taxOptionDropdown
																					}
																					classNamePrefix="select2-selection"
																					onSelectResetsInput={
																						true
																					}
																					closeMenuOnSelect={
																						true
																					}
																					components={
																						animatedComponents
																					}
																					required
																				/>
																				{validation
																					.touched
																					.taxes &&
																				validation
																					.errors
																					.taxes ? (
																					<FormFeedback
																						className="error"
																						type="invalid"
																					>
																						{
																							validation
																								.errors
																								.taxes
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>

																	{validation.values.taxes?.map(
																		(
																			tax
																		) => (
																			<Row
																				key={`row-field-${tax.value}`}
																				form={
																					"true"
																				}
																			>
																				<Col
																					xs={
																						12
																					}
																				>
																					<div className="mb-3">
																						<Label className="form-label">
																							{`${tax.label} Tax
																								(%)`}
																						</Label>
																						<Input
																							name={`${tax.value}`}
																							type="text"
																							value={
																								validation
																									.values?.[
																									`${tax.value}`
																								] ||
																								""
																							}
																							onChange={(
																								e
																							) => {
																								e.target.value =
																									e.target.value.match(
																										/^([0-9]{1,})?(\.)?([0-9]{1,})?$/
																									)
																										? e.target.value.match(
																												/([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s
																										  )[0]
																										: validation
																												.values?.[
																												`${tax.value}`
																										  ] ||
																										  "";
																								validation.handleChange(
																									e
																								);
																							}}
																							onBlur={
																								validation.handleBlur
																							}
																							invalid={
																								validation
																									.touched[
																									`${tax.value}`
																								] &&
																								validation
																									.errors[
																									`${tax.value}`
																								]
																									? true
																									: false
																							}
																						/>
																						{validation
																							.touched[
																							`${tax.value}`
																						] &&
																						validation
																							.errors[
																							`${tax.value}`
																						] ? (
																							<FormFeedback type="invalid">
																								{
																									validation
																										.errors[
																										`${tax.value}`
																									]
																								}
																							</FormFeedback>
																						) : null}
																					</div>
																				</Col>
																			</Row>
																		)
																	)}

																	<Row>
																		<Col>
																			<div className="text-end mt-3">
																				<button
																					type="submit"
																					className="btn btn-success save-user"
																				>
																					Save
																				</button>
																			</div>
																		</Col>
																	</Row>
																</Form>
															</fieldset>
														</ModalBody>
													</Modal>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default CountryTax;
