import React, {
	useEffect,
	useState,
	useRef,
	useMemo,
	useCallback,
} from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
	Button,
} from "reactstrap";
import { Link } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import isEmpty from "../../utils/isEmpty";
import "react-tooltip/dist/react-tooltip.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import ReactDataTable from "../../common/ReactDataTable";
import { apiUrl } from "../../config";

import {
	create,
	update,
	remove,
	toggle,
	getProducts,
	getProductCategory,
	getProductOption,
	clearResponse,
} from "../../store/product/actions";
import Dropzone from "react-dropzone";
const MAX_UPLOAD_IMAGE = 3;

let editor;

const Product = (props) => {
	const animatedComponents = makeAnimated();
	const refreshTableData = useRef(null);
	const dispatch = useDispatch();
	const { auth, product } = useSelector((state) => ({
		product: state.product,
		auth: state.auth,
	}));
	const [images, setImages] = useState([{ src: "" }]);
	const { response, products, isLoading, categories, options } = product;
	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const noMatch = isLoading ? (
		<CircularProgress size={30} style={{ color: "#3f51b5" }} />
	) : (
		"Sorry, no matching records found."
	);
	useEffect(() => {
		dispatch(getProductCategory());
		dispatch(getProductOption());
		dispatch(getProducts());
	}, []);

	const [details, handleformData] = useState({});

	useEffect(() => {
		if (response && response.status === 200) {
			// dispatch(getProducts());
			refreshTableData.current();
			Swal.close();
			if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		} else if (response && response?.msg) {
			refreshTableData.current();
			Swal.close();
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const getCategoryOptions = useMemo(() => {
		const result = categories?.map((item) => ({
			label: `${item.name}`,
			value: item._id,
			name: item.name,
		}));
		return result ?? [];
	}, [categories]);

	const productOptionDropdown = useMemo(() => {
		const result = options?.map((item) => ({
			label: `${item.name}`,
			value: item._id,
			name: item.name,
		}));
		return result ?? [];
	}, [options]);

	const removeItem = (data) => {
		if (!isEmpty(data?._id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to delete?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					dispatch(remove(data));
				}
			});
		}
	};

	const handleToggle = (data) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to update?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				Swal.fire({
					title: "Updating",
					html: "Please wait...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				dispatch(toggle(data));
			}
		});
	};

	function yupOptionValidation(values) {
		return values.length ? true : false;
	}
	const validationSchema = Yup.object()
		.shape({
			name: Yup.string().required(),
			price: Yup.string().required(),
			category: Yup.object().required(),
			options: Yup.array(
				Yup.object({
					value: Yup.string().required("Field is required"),
				})
			).test(
				"Options field is required ",
				"Options field is required",
				yupOptionValidation
			),
		})
		.when((values, schema) => {
			let validationData = {};
			values.options.forEach(
				(option) =>
					(validationData[`${option.value}`] = Yup.object()
						.shape({
							value: Yup.string().required(
								`${option.label} is required`
							),
						})
						.required(`${option.label} is required`))
			);
			return schema.shape(validationData);
		});

	const initialFormValues = {
		name: details && details.name ? details.name : "",
		price: details && details.price ? details.price : "",
		category: details && details.category ? details.category : "",
		options: details && details.options ? details.options : [],
		images: details && details.images ? details.images : null,
		variant:
			details && details.selectedVarients ? details.selectedVarients : {},

		shortDescription:
			details && details.shortDescription ? details.shortDescription : "",
		volume:
			details && details?.details?.volume ? details.details.volume : "",
		period:
			details && details?.details?.period ? details.details.period : "",
		delayDays:
			details && details?.details?.delayDays
				? details.details.delayDays
				: "",
		maxProd:
			details && details?.details?.maxProd ? details.details.maxProd : "",
		serviceFees:
			details && details?.details?.serviceFees
				? details.details.serviceFees
				: "",
		activationFeesInPercent:
			details && details?.details?.activationFeesInPercent
				? details.details.activationFeesInPercent
				: "",
		nftShare:
			details && details?.details?.nftShare
				? details.details.nftShare
				: "",
		typeId:
			details && details?.details?.typeId ? details.details.typeId : "",
		description:
			details && details?.details?.description
				? details.details.description
				: "",
	};
	Object.entries(details?.selectedOptionValues ?? {}).forEach(
		([key, value]) => {
			initialFormValues[key] = value;
		}
	);
	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: initialFormValues,
		validationSchema: validationSchema,
		onSubmit: (values) => {
			const productDetails = { ...values };
			productDetails.category = productDetails.category?.value;
			productDetails.variants = [];
			Object.entries(productDetails.variant).forEach(([key, value]) => {
				productDetails.variants?.push({
					variant: [
						{
							option: key,
							value: value.value,
						},
					],
				});
			});
			productDetails.uploadedImages = details.existingImages;
			Swal.fire({
				title: "Updating",
				html: "Please wait...",
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			if (details.id) {
				dispatch(update({ ...productDetails, id: details.id }));
			} else {
				dispatch(create(productDetails));
			}
		},
	};
	const validation = useFormik(useFormikOptions);
	const productOptionValueDropdown = useCallback(
		(selectOption) => {
			const selectOptionIndex = options.findIndex(
				(option) => option._id?.toString() === selectOption
			);
			const selectOptionDetails = options[selectOptionIndex];
			const result = selectOptionDetails?.values?.map((item) => ({
				label: `${item.name}`,
				value: item.name,
				name: item.name,
				_id: selectOptionDetails?._id,
			}));
			return (
				[
					{
						label: `Select option`,
						value: "",
					},
					...result,
				] ?? []
			);
		},
		[validation.values.options, options]
	);

	const handleAddEditModal =
		(data = null) =>
		(e) => {
			e.preventDefault();
			validation.resetForm();
			if (!isEmpty(data) && data?._id) {
				let selectedOptions = [];
				let selectedOptionValues = [];
				let selectedVarients = {};
				data.variants?.forEach((option) => {
					if (option.variant) {
						option.variant?.forEach((variantDetails) => {
							const isExist = selectedOptions.find(
								(item) => item.value === variantDetails.option
							);
							selectedVarients[
								variantDetails.option?.toString()
							] = { value: variantDetails.optionValue };

							if (!isExist) {
								selectedOptions.push({
									label: `${variantDetails.optionName}`,
									value: variantDetails.option,
									name: variantDetails.optionName,
								});
								selectedOptionValues[
									`${variantDetails.option}`
								] = {
									label: `${variantDetails.optionValue}`,
									value: variantDetails.optionValue,
									name: variantDetails.optionValue,
									_id: variantDetails.option,
								};
							}
						});
					}
				});
				data.category = {
					label: `${data.category?.name}`,
					value: data.category._id,
					name: data.category?.name,
				};
				data.options = selectedOptions;
				data.selectedOptionValues = selectedOptionValues;
				data.existingImages = data.images;
				data.selectedVarients = selectedVarients;
				data.images = [];
				handleformData({ ...data });
			} else {
				handleformData({});
			}
			toggleAddEditModal(!isOpenAddEditModal);
		};

	const onEditorChange = (data) => {
		validation.setFieldValue("description", data);
	};

	const removeImageFile = (file) => (e) => {
		e.preventDefault();
		const imageDetails = [...validation.values.images];
		imageDetails.splice(imageDetails.indexOf(file), 1);
		validation.setFieldValue("images", imageDetails);
	};

	const removeExistingImageFile = (fileIndex) => (e) => {
		e.preventDefault();
		const imageDetails = [...details.existingImages];
		imageDetails.splice(fileIndex, 1);
		handleformData({ ...details, existingImages: imageDetails });
	};

	const selectedFile = (selectedFiles) => {
		if (isEmpty(selectedFiles)) {
			validation.setFieldValue("images", []);
			return false;
		} else {
			const formData = { ...details };
			const files = selectedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				})
			);
			formData.images = files;
			validation.setFieldValue("images", files);
			// handleformData({
			// 	...formData,
			// 	...validation.values,
			// 	images: files,
			// });
		}
	};

	const columns = () => [
		{
			label: "Image",
			name: "images",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData?.length
						? rowData.map((image, index) => (
								<img
									key={`image-product-${index}`}
									alt=""
									style={{ height: 45, width: 45 }}
									src={image?.link}
									className="m-1"
								/>
						  ))
						: "Not Available";
				},
			},
		},
		{
			label: "Name",
			name: "name",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Price",
			name: "price",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Status",
			name: "status",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (status) => {
					return status === "disable" ? (
						<div className="badge badge-soft-default font-size-12">
							Disable
						</div>
					) : status === "pending" ? (
						<div className="badge badge-soft-warning font-size-12">
							Pending
						</div>
					) : status === "published" ? (
						<div className="badge badge-soft-success font-size-12">
							Published
						</div>
					) : status === "deleted" ? (
						<div className="badge badge-soft-danger font-size-12">
							Deleted
						</div>
					) : (
						<div className="badge  font-size-12">{status}</div>
					);
				},
			},
		},
		// {
		// 	label: "Description",
		// 	name: "description",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 		customBodyRender: (rowData) => {
		// 			return rowData ? (
		// 				<div
		// 					dangerouslySetInnerHTML={{
		// 						__html: `${rowData?.substring(0, 20)}...`,
		// 					}}
		// 				/>
		// 			) : (
		// 				<div className="text-center">-</div>
		// 			);
		// 		},
		// 	},
		// },
		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: hasPermission(
					["partner update", "partner delete", "partner view"],
					auth.currentUserRolePermissions
				),
				viewColumns: hasPermission(
					["partner update", "partner delete", "partner view"],
					auth.currentUserRolePermissions
				),
				customBodyRender: (data) => {
					return (
						<div className="text-center">
							{data.status === "deleted" ? (
								<HasAnyPermission
									permission={["partner delete"]}
								>
									<button
										disabled
										type="button"
										className="btn btn-danger waves-effect waves-light m-1"
									>
										Deleted
									</button>
								</HasAnyPermission>
							) : (
								<>
									<HasAnyPermission
										permission={[
											"partner update",
											"partner view",
										]}
									>
										<button
											onClick={handleAddEditModal({
												...data,
											})}
											type="button"
											className="btn btn-soft-primary waves-effect waves-light m-1"
										>
											<i className="bx bx-edit-alt font-size-16 align-middle"></i>
										</button>
									</HasAnyPermission>
									<HasAnyPermission
										permission={["partner delete"]}
									>
										<button
											onClick={(e) => removeItem(data)}
											type="button"
											className="btn btn-soft-danger waves-effect waves-light m-1"
										>
											<i className="bx bx-trash font-size-16 align-middle"></i>
										</button>
									</HasAnyPermission>
								</>
							)}
						</div>
					);
				},
			},
		},
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				description: item?.details?.description,
				active: item,
				action: item,
			};
		});
	};

	const couldHaveAddUpdatePermission = () => {
		const isUpdatePermission = hasPermission(
			["chain update"],
			auth.currentUserRolePermissions
		);
		const isAddPermission = hasPermission(
			["chain add"],
			auth.currentUserRolePermissions
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details._id)) return true;
		else if (isAddPermission && isEmpty(details._id)) return true;
		else return false;
	};
	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Product | LFI</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Product" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<ReactDataTable
														url={`${apiUrl}/admin/store/products/pagination`}
														columns={columns()}
														resultFormatter={
															resultFormatter
														}
														setRefresh={
															refreshTableData
														}
														disableFilterIcon={true}
														disableSearchIcon={true}
														origin={
															<div className="row">
																<div className="col-auto h4">
																	Product
																	&nbsp;
																	<HasAnyPermission
																		permission={[
																			"partner add",
																		]}
																	>
																		<button
																			onClick={handleAddEditModal(
																				null
																			)}
																			type="button"
																			className="btn btn-primary waves-effect waves-light"
																		>
																			<i className="bx bx-plus-medical font-size-16 align-middle"></i>
																		</button>
																	</HasAnyPermission>
																</div>
															</div>
														}
														rowsPerPage={10}
													/>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
							<Modal
								isOpen={isOpenAddEditModal}
								toggle={handleAddEditModal()}
								size="lg"
								centered={true}
							>
								<ModalHeader
									toggle={handleAddEditModal()}
									tag="h4"
								>
									{details?._id ? "Edit" : "Add"}
								</ModalHeader>
								<ModalBody>
									<fieldset
										disabled={
											!couldHaveAddUpdatePermission()
										}
									>
										<Form
											onSubmit={(e) => {
												e.preventDefault();
												validation.handleSubmit();
												return false;
											}}
										>
											<Row form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															Name
														</Label>
														<Input
															name="name"
															type="text"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.name || ""
															}
															invalid={
																validation
																	.touched
																	.name &&
																validation
																	.errors.name
																	? true
																	: false
															}
														/>
														{validation.touched
															.name &&
														validation.errors
															.name ? (
															<FormFeedback type="invalid">
																{
																	validation
																		.errors
																		.name
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>
											<Row form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															Select Category
														</Label>
														<Select
															name="category"
															value={
																validation
																	.values
																	.category ||
																""
															}
															onChange={(
																value,
																action
															) => {
																validation.setFieldValue(
																	"category",
																	value
																);
															}}
															isMulti={false}
															options={
																getCategoryOptions
															}
															classNamePrefix="select2-selection"
															onSelectResetsInput={
																true
															}
															closeMenuOnSelect={
																true
															}
															components={
																animatedComponents
															}
														/>
														{validation.touched
															.category &&
														validation.errors
															.category ? (
															<FormFeedback
																className="error"
																type="invalid"
															>
																{
																	validation
																		.errors
																		.category
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<Row>
												<Col xs={12}>
													<Label className="form-label">
														Images
													</Label>
													<Card>
														<CardBody>
															<Dropzone
																onDrop={(
																	acceptedFiles
																) => {
																	selectedFile(
																		acceptedFiles
																	);
																}}
																accept="image/bmp, image/jpeg, image/x-png; image/png, image/gif"
																maxFiles={
																	MAX_UPLOAD_IMAGE
																}
															>
																{({
																	getRootProps,
																	getInputProps,
																	fileRejections,
																}) => (
																	<div className="dropzone">
																		{
																			<div
																				className="dz-message needsclick mt-2"
																				{...getRootProps()}
																			>
																				<input
																					name="images"
																					{...getInputProps()}
																				/>
																				<div className="mb-3">
																					<i className="display-4 text-muted bx bxs-cloud-upload" />
																				</div>
																				{fileRejections?.[0]
																					?.errors ? (
																					<h4>
																						{
																							fileRejections?.[0]
																								?.errors?.[0]
																								?.message

																							// JSON.stringify(
																							// 	fileRejections
																							// )
																						}
																					</h4>
																				) : (
																					<h4>
																						click
																						to
																						upload.
																					</h4>
																				)}
																			</div>
																		}
																	</div>
																)}
															</Dropzone>
															<div
																className="dropzone-previews mt-3"
																id="file-previews"
															>
																{validation
																	.values
																	.images
																	?.length
																	? validation.values.images.map(
																			(
																				imageFile,
																				i
																			) => {
																				return (
																					<Card
																						className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
																						key={
																							i +
																							"-file"
																						}
																					>
																						<div className="p-2">
																							<div className="d-flex flex-wrap align-items-center">
																								<Col className="col-auto m-2">
																									<img
																										data-dz-thumbnail=""
																										height="80"
																										className="avatar-sm rounded bg-light"
																										alt={
																											imageFile.name
																										}
																										src={
																											imageFile.preview
																										}
																									/>
																								</Col>
																								<Col>
																									<Link
																										to="#"
																										className="text-muted font-weight-bold"
																									>
																										{
																											imageFile.name
																										}
																									</Link>
																								</Col>

																								<div className="ms-auto">
																									<Button
																										type="button"
																										className="btn btn-danger btn-sm waves-effect waves-light"
																										onClick={removeImageFile(
																											imageFile
																										)}
																									>
																										Remove
																									</Button>
																								</div>
																							</div>
																						</div>
																					</Card>
																				);
																			}
																	  )
																	: null}

																{details
																	?.existingImages
																	?.length
																	? details.existingImages.map(
																			(
																				imageFile,
																				existingImageIndex
																			) => {
																				return (
																					<Card
																						className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
																						key={
																							existingImageIndex +
																							"-file"
																						}
																					>
																						<div className="p-2">
																							<div className="d-flex flex-wrap align-items-center">
																								<Col className="col-auto m-2">
																									<img
																										data-dz-thumbnail=""
																										height="80"
																										className="avatar-sm rounded bg-light"
																										alt={
																											"image"
																										}
																										src={
																											imageFile.link
																										}
																									/>
																								</Col>
																								<Col>
																									<Link
																										to="#"
																										className="text-muted font-weight-bold"
																									>
																										{imageFile.link.slice(
																											imageFile.link.lastIndexOf(
																												"/"
																											) +
																												1
																										)}
																									</Link>
																								</Col>

																								<div className="ms-auto">
																									<Button
																										type="button"
																										disabled
																										className="btn btn-info btn-sm waves-effect waves-light"
																									>
																										Uploaded
																									</Button>
																									&nbsp;
																									<Button
																										type="button"
																										className="btn btn-danger btn-sm waves-effect waves-light"
																										onClick={removeExistingImageFile(
																											existingImageIndex
																										)}
																									>
																										Remove
																									</Button>
																								</div>
																							</div>
																						</div>
																					</Card>
																				);
																			}
																	  )
																	: null}
															</div>
														</CardBody>
													</Card>
												</Col>
											</Row>

											<Row className="mt-2" form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															Select Options
														</Label>
														<Select
															name="options"
															value={
																validation
																	.values
																	.options ||
																""
															}
															onChange={(
																value,
																action
															) => {
																validation.setFieldValue(
																	"options",
																	value
																);
															}}
															isMulti={true}
															options={
																productOptionDropdown
															}
															classNamePrefix="select2-selection"
															onSelectResetsInput={
																true
															}
															closeMenuOnSelect={
																true
															}
															components={
																animatedComponents
															}
															required
														/>
														{validation.touched
															.options &&
														validation.errors
															.options ? (
															<FormFeedback
																className="error"
																type="invalid"
															>
																{
																	validation
																		.errors
																		.options
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											{validation.values.options?.map(
												(option) => (
													<Row
														key={option.value}
														form={"true"}
													>
														<Col xs={12}>
															<div className="mb-3">
																<Label className="form-label">
																	Select
																	{` ${option.name}`}
																</Label>
																<Select
																	key={`select-option-${option.value}`}
																	name={`${option.value}`}
																	value={
																		validation
																			.values?.[
																			`${option.value}`
																		] || ""
																	}
																	onChange={(
																		value,
																		action
																	) => {
																		validation.setFieldValue(
																			`variant`,
																			{
																				...validation
																					.values
																					.variant,
																				[option.value]:
																					value,
																			}
																		);
																		validation.setFieldValue(
																			`${option.value}`,
																			value
																		);
																	}}
																	isMulti={
																		false
																	}
																	options={productOptionValueDropdown(
																		`${option.value}`
																	)}
																	classNamePrefix="select2-selection"
																	onSelectResetsInput={
																		true
																	}
																	closeMenuOnSelect={
																		true
																	}
																	components={
																		animatedComponents
																	}
																/>
																{validation
																	.errors[
																	`${option.value}`
																] ? (
																	<FormFeedback
																		className="error"
																		type="invalid"
																	>
																		{
																			validation
																				.errors?.[
																				`${option.value}`
																			]
																				?.value
																		}
																	</FormFeedback>
																) : null}
															</div>
														</Col>
													</Row>
												)
											)}

											<Row className="" form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															Price
														</Label>
														<Input
															name="price"
															type="text"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.price || ""
															}
															invalid={
																validation
																	.touched
																	.price &&
																validation
																	.errors
																	.price
																	? true
																	: false
															}
														/>
														{validation.touched
															.price &&
														validation.errors
															.price ? (
															<FormFeedback type="invalid">
																{
																	validation
																		.errors
																		.price
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<Row className="" form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															Volume
														</Label>
														<Input
															name="volume"
															type="text"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.volume ||
																""
															}
															invalid={
																validation
																	.touched
																	.volume &&
																validation
																	.errors
																	.volume
																	? true
																	: false
															}
														/>
														{validation.touched
															.volume &&
														validation.errors
															.volume ? (
															<FormFeedback type="invalid">
																{
																	validation
																		.errors
																		.volume
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<Row className="" form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															Period
														</Label>
														<Input
															name="period"
															type="text"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.period ||
																""
															}
															invalid={
																validation
																	.touched
																	.period &&
																validation
																	.errors
																	.period
																	? true
																	: false
															}
														/>
														{validation.touched
															.period &&
														validation.errors
															.period ? (
															<FormFeedback type="invalid">
																{
																	validation
																		.errors
																		.period
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<Row className="" form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															Delay Days
														</Label>
														<Input
															name="delayDays"
															type="text"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.delayDays ||
																""
															}
															invalid={
																validation
																	.touched
																	.delayDays &&
																validation
																	.errors
																	.delayDays
																	? true
																	: false
															}
														/>
														{validation.touched
															.delayDays &&
														validation.errors
															.delayDays ? (
															<FormFeedback type="invalid">
																{
																	validation
																		.errors
																		.delayDays
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<Row className="" form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															maxProd
														</Label>
														<Input
															name="maxProd"
															type="text"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.maxProd ||
																""
															}
															invalid={
																validation
																	.touched
																	.maxProd &&
																validation
																	.errors
																	.maxProd
																	? true
																	: false
															}
														/>
														{validation.touched
															.maxProd &&
														validation.errors
															.maxProd ? (
															<FormFeedback type="invalid">
																{
																	validation
																		.errors
																		.maxProd
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<Row className="" form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															serviceFees
														</Label>
														<Input
															name="serviceFees"
															type="text"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.serviceFees ||
																""
															}
															invalid={
																validation
																	.touched
																	.serviceFees &&
																validation
																	.errors
																	.serviceFees
																	? true
																	: false
															}
														/>
														{validation.touched
															.serviceFees &&
														validation.errors
															.serviceFees ? (
															<FormFeedback type="invalid">
																{
																	validation
																		.errors
																		.serviceFees
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<Row className="" form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															activationFeesInPercent
														</Label>
														<Input
															name="activationFeesInPercent"
															type="text"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.activationFeesInPercent ||
																""
															}
															invalid={
																validation
																	.touched
																	.activationFeesInPercent &&
																validation
																	.errors
																	.activationFeesInPercent
																	? true
																	: false
															}
														/>
														{validation.touched
															.activationFeesInPercent &&
														validation.errors
															.activationFeesInPercent ? (
															<FormFeedback type="invalid">
																{
																	validation
																		.errors
																		.activationFeesInPercent
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<Row className="" form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															nftShare
														</Label>
														<Input
															name="nftShare"
															type="text"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.nftShare ||
																""
															}
															invalid={
																validation
																	.touched
																	.nftShare &&
																validation
																	.errors
																	.nftShare
																	? true
																	: false
															}
														/>
														{validation.touched
															.nftShare &&
														validation.errors
															.nftShare ? (
															<FormFeedback type="invalid">
																{
																	validation
																		.errors
																		.nftShare
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<Row className="" form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															typeId
														</Label>
														<Input
															name="typeId"
															type="text"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.typeId ||
																""
															}
															invalid={
																validation
																	.touched
																	.typeId &&
																validation
																	.errors
																	.typeId
																	? true
																	: false
															}
														/>
														{validation.touched
															.typeId &&
														validation.errors
															.typeId ? (
															<FormFeedback type="invalid">
																{
																	validation
																		.errors
																		.typeId
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<Row form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															Short Description
														</Label>
														<Input
															name="shortDescription"
															type="text"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.shortDescription ||
																""
															}
															invalid={
																validation
																	.touched
																	.shortDescription &&
																validation
																	.errors
																	.shortDescription
																	? true
																	: false
															}
														/>
														{validation.touched
															.shortDescription &&
														validation.errors
															.shortDescription ? (
															<FormFeedback type="invalid">
																{
																	validation
																		.errors
																		.shortDescription
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<div className="row">
												<div className="mb-3">
													<label
														htmlFor="basicpill-firstname-input"
														className="form-label"
													>
														Description
													</label>
													<CKEditor
														editor={DecoupledEditor}
														config={{
															ckfinder: {
																// uploadUrl: `${apiUrl}/launchpad/save_image`,
																link: {
																	decorators:
																		{
																			addTargetToExternalLinks:
																				{
																					mode: "automatic",
																					callback:
																						(
																							url
																						) =>
																							/^(https?:)?\/\//.test(
																								url
																							),
																					attributes:
																						{
																							target: "_blank",
																							rel: "noopener noreferrer",
																						},
																				},
																		},
																},
															},
															// plugins: [ Link ],
														}}
														data={
															validation.values
																.description
														}
														onReady={(editor) => {
															editor.ui
																.getEditableElement()
																.parentElement.insertBefore(
																	editor.ui
																		.view
																		.toolbar
																		.element,
																	editor.ui.getEditableElement()
																);
															editor = editor;
														}}
														onChange={(
															event,
															editor
														) => {
															const data =
																editor.getData();
															onEditorChange(
																data
															);
														}}
													/>
												</div>
											</div>

											<Row>
												<Col>
													<div className="text-end ">
														<button
															type="submit"
															className="btn btn-success save-user"
														>
															Save
														</button>
													</div>
												</Col>
											</Row>
										</Form>
									</fieldset>
								</ModalBody>
							</Modal>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Product;
