import React, { useEffect, useState, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Row,
	Container,
	CardHeader,
	CardTitle,
	Table,
	Input,
	Label,
	Form,
	FormFeedback,
	FormText,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import isEmpty from "../../utils/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import {
	getCountries,
	clearResponse,
	getShippingMethod,
	getShippingWeight,
	getShippingFees,
	updateShippingFee,
} from "../../store/shipping/actions";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const ShippingFee = (props) => {
	const dispatch = useDispatch();
	const animatedComponents = makeAnimated();
	const { shipping, auth } = useSelector((state) => ({
		shipping: state.shipping,
		auth: state.auth,
	}));
	const [selectedCountryFee, setSelectedCountryFee] = useState({});
	const [selectedWeight, setSelectedWeight] = useState({});
	const [selectedCountry, setSelectedCountry] = useState({});
	const {
		response,
		setting,
		countries,
		shippingWeights,
		shippingMethods,
		shippingFees,
	} = shipping;
	useEffect(() => {
		dispatch(getShippingFees());
		dispatch(getCountries());
		dispatch(getShippingMethod());
		dispatch(getShippingWeight());
	}, []);

	useEffect(() => {
		Swal.close();
		if (response && response.code === "200") {
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
					dispatch(getShippingFees());
				},
			});
		} else if (response && response?.msg) {
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const handleCountryChange = (country) => {
		if (country?.value) {
			shippingFees[country.value];
			setSelectedCountryFee(shippingFees[country.value]);
		}

		setSelectedCountry(country);
	};

	const countryOptionDropdown = useMemo(() => {
		const result = countries?.map((item) => ({
			label: `${item.name}`,
			value: item._id,
		}));
		return result ?? [];
	}, [countries]);

	const shippingWeightOptionDropdown = useMemo(() => {
		const result = shippingWeights?.map((item) => ({
			label:
				item.value.min === item.value.max
					? `${item.value.min}`
					: `${item.value.min} - ${item.value.max}`,
			value: item._id,
		}));
		return result ?? [];
	}, [shippingWeights]);

	const getInitialValue = useMemo(() => {
		let initialValues = {
			country: selectedCountry,
			weight: selectedWeight,
			shippingFeeValue: {},
		};
		shippingMethods?.forEach((methodItem) => {
			initialValues[methodItem._id] = "";
			initialValues["shippingFeeValue"][methodItem._id] = 0;
		});
		if (selectedWeight?.value) {
			const selectedWeightDetails =
				selectedCountryFee.shippingWeights.find((shippingWeight) => {
					return (
						shippingWeight?.shippingWeight?._id ===
						selectedWeight.value
					);
				});
			selectedWeightDetails?.shippingMethods?.forEach((methodItem) => {
				initialValues[methodItem.shippingMethod?._id] =
					methodItem.amount ?? "0";
				initialValues.shippingFeeValue[methodItem.shippingMethod?._id] =
					methodItem.amount ?? 0;
			});
		}
		return initialValues;
	}, [selectedCountry, selectedWeight]);

	let validationSchema = {
		country: Yup.object().required("Please select country."),
		weight: Yup.object().required("Please select weight."),
	};

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: getInitialValue,
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => {
			const updateDetails = {
				...values,
				country: values.country?.value,
				weight: values.weight?.value,
			};
			Swal.fire({
				title: "Updating",
				html: "Please wait...",
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			dispatch(updateShippingFee(updateDetails));
		},
	};
	const validation = useFormik(useFormikOptions);
	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Shipping SETTING | LFI</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs
						title="Fee setting                                              "
						breadcrumbItem="ShippingFee"
					/>

					<Row>
						<Card className="border border-default">
							<CardHeader className="align-items-center d-flex bg-transparent border-default">
								<CardTitle className="mb-0 flex-grow-1">
									<h5 className="my-0 text-primary">
										<i className="mdi mdi-bullseye-arrow me-3"></i>
										Shipping Fee setting
									</h5>
								</CardTitle>
							</CardHeader>
							<CardBody>
								<Row form={"true"}>
									<Col md={6}>
										<Form
											onSubmit={(e) => {
												e.preventDefault();
												validation.handleSubmit();
												return false;
											}}
										>
											<Row form={"true"}>
												<FormText
													color="primary"
													className="card-title-desc m-1"
												>
													* To update fee first select
													country and weight
												</FormText>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															Select country
														</Label>
														<Select
															name="country"
															value={
																validation
																	.values
																	.country ||
																""
															}
															placeholder="Select country"
															onChange={(
																value,
																action
															) => {
																validation.setFieldValue(
																	"country",
																	value
																);
																handleCountryChange(
																	value
																);
															}}
															options={
																countryOptionDropdown
															}
															classNamePrefix="select2-selection"
															onSelectResetsInput={
																true
															}
															closeMenuOnSelect={
																true
															}
															components={
																animatedComponents
															}
															required
														/>
														{validation.touched
															.country &&
														validation.errors
															.country ? (
															<FormFeedback
																className="error"
																type="invalid"
															>
																{
																	validation
																		.errors
																		.country
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<Row form={"true"}>
												<Col xs={12}>
													<div className="mb-3">
														<Label className="form-label">
															Select weight
														</Label>
														<Select
															name="weights"
															value={
																validation
																	.values
																	.weight ||
																""
															}
															placeholder="Select weight"
															onChange={(
																value,
																action
															) => {
																validation.setFieldValue(
																	"weight",
																	value
																);
																setSelectedWeight(
																	value
																);
															}}
															options={
																shippingWeightOptionDropdown
															}
															classNamePrefix="select2-selection"
															onSelectResetsInput={
																true
															}
															closeMenuOnSelect={
																true
															}
															components={
																animatedComponents
															}
															required
														/>
														{validation.touched
															.weight &&
														validation.errors
															.weight ? (
															<FormFeedback
																className="error"
																type="invalid"
															>
																{
																	validation
																		.errors
																		.weight
																}
															</FormFeedback>
														) : null}
													</div>
												</Col>
											</Row>

											<>
												{shippingMethods?.length
													? shippingMethods.map(
															(method) => (
																<Row
																	form={
																		"true"
																	}
																	key={`${method._id}`}
																>
																	<Col
																		xs={12}
																	>
																		<div className="mb-3">
																			<Label className="form-label">
																				{
																					method.name
																				}
																			</Label>
																			<Input
																				disabled={
																					selectedWeight?.value
																						? false
																						: true
																				}
																				name={`${method._id}`}
																				type="text"
																				onChange={(
																					e
																				) => {
																					e.target.value =
																						e.target.value.match(
																							/^([0-9]{1,})?(\.)?([0-9]{1,})?$/
																						)
																							? e.target.value.match(
																									/([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s
																							  )[0]
																							: validation
																									.values?.[
																									method
																										._id
																							  ] ||
																							  "";
																					validation.handleChange(
																						e
																					);
																					validation.setFieldValue(
																						"shippingFeeValue",
																						{
																							...validation
																								.values
																								.shippingFeeValue,
																							[method._id]:
																								e
																									.target
																									.value,
																						}
																					);
																				}}
																				onBlur={
																					validation.handleBlur
																				}
																				value={
																					validation
																						.values?.[
																						method
																							._id
																					] ||
																					""
																				}
																				invalid={
																					validation
																						.touched?.[
																						method
																							._id
																					] &&
																					validation
																						.errors?.[
																						method
																							._id
																					]
																						? true
																						: false
																				}
																			/>
																			{validation
																				.touched?.[
																				method
																					._id
																			] &&
																			validation
																				.errors?.[
																				method
																					._id
																			] ? (
																				<FormFeedback type="invalid">
																					{
																						validation
																							.errors?.[
																							method
																								._id
																						]
																					}
																				</FormFeedback>
																			) : null}
																		</div>
																	</Col>
																</Row>
															)
													  )
													: null}
											</>

											<Row>
												<Col>
													<div className="text-end mt-3">
														<button
															type="submit"
															className="btn btn-success save-user"
														>
															Update
														</button>
													</div>
												</Col>
											</Row>
										</Form>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default ShippingFee;
