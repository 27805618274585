import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import auth from "./auth/reducer";
import admin from "./admin/reducer";
import dashboard from "./dashboard/reducer";
import networkInfo from "./networkInfo/reducer";
import user from "./user/reducer";
import meta from "./meta/reducer";
import nft from "./nft/reducer";
import partner from "./partner/reducer";
import softwarePackage from "./softwarePackage/reducer";
import chainSetting from "./chainSetting/reducer";
import cloudUsage from "./cloudUsage/reducer";
import product from "./product/reducer";
import customer from "./customer/reducer";
import order from "./order/reducer";
import voucherCodePackage from "./voucherCodePackage/reducer";
import stakingPool from "./stakingPool/reducer";
import lendingPool from "./lendingPool/reducer";
import productOption from "./productOption/reducer";
import shipping from "./shipping/reducer";
import tax from "./tax/reducer";

const rootReducer = combineReducers({
	Layout,
	auth,
	admin,
	dashboard,
	networkInfo,
	user,
	meta,
	nft,
	partner,
	softwarePackage,
	chainSetting,
	cloudUsage,
	product,
	customer,
	order,
	voucherCodePackage,
	stakingPool,
	lendingPool,
	productOption,
	shipping,
	tax,
});

export default rootReducer;
