import React from "react";
import { Redirect } from "react-router-dom";
import Dashboard from "../pages/Dashboard/index";
import Admin from "../pages/Admin";
import User from "../pages/User";
import Partner from "../pages/Partner";
import UserDetails from "../pages/User/UserDetails";
import NFTDetails from "../pages/User/NFTDetails";
import Role from "../pages/Admin/Role";
import userProfile from "../pages/Authentication/user-profile";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import MacAddress from "../pages/MacAddress";
import MetaSetting from "../pages/Meta/Setting";
import NFTSetting from "../pages/NFTSetting/Setting";
import CloudUsageSetting from "../pages/CloudUsage/Setting";
import NFTSvgSetting from "../pages/NFTSvg";
import SoftwarePackage from "../pages/SoftwarePackage";
import ChainSetting from "../pages/ChainSetting";
import Product from "../pages/Product";
import Customer from "../pages/Customer";
import Order from "../pages/Order";
import OrderDetails from "../pages/Order/OrderDetails";
import VoucherCodePackage from "../pages/VoucherCodePackage";
import StakingPool from "../pages/StakingPool";
import UserStake from "../pages/StakingPool/UserStaking";
import LendingPool from "../pages/LendingPool";
import LendingPoolSetting from "../pages/LendingPool/Setting";
import UserLending from "../pages/LendingPool/UserLending";
import ProductOption from "../pages/ProductOption";
import Setting from "../pages/Shipping/Setting";
import ShippingFee from "../pages/Shipping/ShippingFee";
import Tax from "../pages/Tax";
import CountryTax from "../pages/Tax/CountryTax";

const userRoutes = [
	//dashboard
	{ path: "/dashboard", component: Dashboard },
	//profile
	{
		path: "/profile",
		component: userProfile,
	},
	{
		path: "/admins",
		exact: true,
		component: Admin,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/users",
		exact: true,
		component: User,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/software-package",
		exact: true,
		component: SoftwarePackage,
		permission: ["software package list", "software package view"],
	},
	{
		path: "/setting/partner",
		exact: true,
		component: Partner,
		permission: ["partner add", "partner list"],
	},
	{
		path: "/setting/lfi",
		exact: true,
		component: MetaSetting,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/setting/nft-mint",
		exact: true,
		component: NFTSetting,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/setting/cloud-usage",
		exact: true,
		component: CloudUsageSetting,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/shop/products",
		exact: true,
		component: Product,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/shop/product-option",
		exact: true,
		component: ProductOption,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/shop/customers",
		exact: true,
		component: Customer,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/shop/orders",
		exact: true,
		component: Order,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/shop/voucher-code-package",
		exact: true,
		component: VoucherCodePackage,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/shop/order/:id",
		exact: true,
		component: OrderDetails,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/shop/tax",
		exact: true,
		component: Tax,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/shop/tax/country",
		exact: true,
		component: CountryTax,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/setting/nft-svg",
		exact: true,
		component: NFTSvgSetting,
		permission: ["NFT svg add", "NFT svg list"],
	},
	{
		path: "/mac-address",
		exact: true,
		component: MacAddress,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/user/:_id",
		exact: true,
		component: UserDetails,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/user/:contractId/:walletId/:userId",
		exact: true,
		component: NFTDetails,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/roles",
		exact: true,
		component: Role,
		permission: ["role add", "role list"],
	},
	{
		path: "/chain-setting",
		exact: true,
		component: ChainSetting,
		permission: ["role add", "role list"],
	},
	{
		path: "/staking/pool",
		exact: true,
		component: StakingPool,
		permission: ["role add", "role list"],
	},
	{
		path: "/staking/user/pool",
		exact: true,
		component: UserStake,
		permission: ["role add", "role list"],
	},
	{
		path: "/lending/pool",
		exact: true,
		component: LendingPool,
		permission: ["role add", "role list"],
	},
	{
		path: "/lending/user/pool",
		exact: true,
		component: UserLending,
		permission: ["role add", "role list"],
	},
	{
		path: "/lending-staking-setting",
		exact: true,
		component: LendingPoolSetting,
		permission: ["role add", "role list"],
	},
	{
		path: "/shipping/setting",
		exact: true,
		component: Setting,
		permission: ["role add", "role list"],
	},
	{
		path: "/shipping/fee",
		exact: true,
		component: ShippingFee,
		permission: ["role add", "role list"],
	},
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
	//authencation page
	{ path: "/logout", exact: true, component: Logout },
	{ path: "/login", exact: true, component: Login },
	{
		path: "/forgot-password/:token/:tokenKey",
		exact: true,
		component: ForgotPassword,
	},
	{ path: "/forgot-password", exact: true, component: ForgotPassword },
];

export { userRoutes, authRoutes };
