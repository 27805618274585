export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const REMOVE_PRODUCT_REQUEST = "REMOVE_PRODUCT_REQUEST";
export const REMOVE_PRODUCT_SUCCESS = "REMOVE_PRODUCT_SUCCESS";
export const REMOVE_PRODUCT_FAILURE = "REMOVE_PRODUCT_FAILURE";

export const TOGGLE_PRODUCT_SUCCESS = "TOGGLE_PRODUCT_SUCCESS";
export const TOGGLE_PRODUCT_REQUEST = "TOGGLE_PRODUCT_REQUEST";
export const TOGGLE_PRODUCT_FAILURE = "TOGGLE_PRODUCT_FAILURE";

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";

export const GET_PRODUCT_CATEGORY_SUCCESS = "GET_PRODUCT_CATEGORY_SUCCESS";
export const GET_PRODUCT_CATEGORY_REQUEST = "GET_PRODUCT_CATEGORY_REQUEST";
export const GET_PRODUCT_CATEGORY_FAILURE = "GET_PRODUCT_CATEGORY_FAILURE";

export const GET_PRODUCT_OPTION_SUCCESS = "GET_PRODUCT_OPTION_SUCCESS";
export const GET_PRODUCT_OPTION_REQUEST = "GET_PRODUCT_OPTION_REQUEST";
export const GET_PRODUCT_OPTION_FAILURE = "GET_PRODUCT_OPTION_FAILURE";
