import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import {
	approve,
	clearResponse,
	cancelUserLending,
	getAll,
} from "../../store/lendingPool/actions";
import Swal from "sweetalert2";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import UserLendingApproveModal from "./UserLendingApproveModal";

const UserLending = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { response, lendingPools, isLoading, auth } = useSelector(
		(state) => ({
			response: state.lendingPool.response,
			isLoading: state.lendingPool.isLoading,
			lendingPools: state.lendingPool.lendingPools,
			auth: state.auth,
		})
	);

	const [approveDetails, setApproveDetails] = useState({
		isOpenModal: false,
		details: {},
	});

	useEffect(() => {
		dispatch(getAll());
	}, []);

	const getLendingPoolOptions = useMemo(() => {
		const result = lendingPools?.map((item) => ({
			label: `${item.name}`,
			value: item._id,
			name: item.name,
		}));
		return result ?? [];
	}, [lendingPools]);

	useEffect(() => {
		if (response && response.status === 200) {
			refreshTableData.current();
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
			Swal.close();
			if (approveDetails.isOpenModal) handleApproveDetails();
		} else if (response && response?.msg) {
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
			refreshTableData.current();
			Swal.close();
		}
	}, [dispatch, response]);

	const handleApproveDetails = (details = {}) => {
		setApproveDetails({
			...approveDetails,
			details: details,
			isOpenModal: !approveDetails.isOpenModal,
		});
	};

	const approveValidation = useFormik({
		enableReinitialize: true,
		initialValues: {
			_id: {
				label: approveDetails.details?.lendingPool?.name
					? `${approveDetails.details.lendingPool.name}`
					: "",
				value: approveDetails.details?.lendingPool?._id
					? approveDetails.details.lendingPool._id
					: "",
				name: approveDetails.details?.lendingPool?.name
					? `${approveDetails.details.lendingPool.name}`
					: "",
			},
		},
		validationSchema: Yup.object({
			_id: Yup.object().required("Please select lending pool."),
		}),
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				dispatch(
					approve({
						_id: approveDetails.details?._id,
						poolId: values?._id?.value,
					})
				);
			}
		},
	});
	const handleCancelUserLending = (data) => {
		if (!isEmpty(data?._id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to cancel?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					dispatch(cancelUserLending(data));
				}
			});
		}
	};

	const columns = () => [
		{
			label: "Name",
			name: "name",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Identifier",
			name: "identifier",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Lending Pool Name",
			name: "lendingPoolName",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Amount",
			name: "amount",
			options: {
				filter: false,
				sort: false,
			},
		},
		// {
		// 	label: "Volume (%)",
		// 	name: "volume",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 	},
		// },

		// {
		// 	name: "status",
		// 	label: "Status",
		// 	options: {
		// 		customFilterListOptions: {
		// 			render: (v) =>
		// 				v.map((l) => l.charAt(0).toUpperCase() + l.slice(1)),
		// 		},
		// 		filterType: "custom",
		// 		filterOptions: {
		// 			names: [],
		// 			display: (filterList, onChange, index, column) => {
		// 				const optionValues = [
		// 					{ name: "Active", value: "active" },
		// 					{ name: "Pending", value: "pending" },
		// 					{ name: "Disable", value: "disable" },
		// 					{ name: "Delete", value: "delete" },
		// 				];
		// 				return (
		// 					<div className="auto">
		// 						{/* <label
		// 							htmlFor="currency-transaction"
		// 							className="form-label font-size-13 text-muted"
		// 						></label> */}
		// 						<select
		// 							value={filterList[index][0] || ""}
		// 							onChange={(event) => {
		// 								filterList[index][0] =
		// 									event.target.value;
		// 								onChange(
		// 									filterList[index],
		// 									index,
		// 									column
		// 								);
		// 							}}
		// 							className="form-control"
		// 							name="currency-transaction"
		// 						>
		// 							<option disabled value="">
		// 								Status
		// 							</option>
		// 							{optionValues.map((item) => (
		// 								<option
		// 									key={item.value}
		// 									value={item.value}
		// 								>
		// 									{item.name}
		// 								</option>
		// 							))}
		// 						</select>
		// 					</div>
		// 				);
		// 			},
		// 		},
		// 		sort: false,
		// 		customBodyRender: (status) => {
		// 			return status === "disable" ? (
		// 				<div className="badge badge-soft-default font-size-12">
		// 					Disable
		// 				</div>
		// 			) : status === "pending" ? (
		// 				<div className="badge badge-soft-warning font-size-12">
		// 					Pending
		// 				</div>
		// 			) : status === "active" ? (
		// 				<div className="badge badge-soft-success font-size-12">
		// 					Active
		// 				</div>
		// 			) : status === "confirm" ? (
		// 				<div className="badge badge-soft-primary font-size-12">
		// 					Approve in progress
		// 				</div>
		// 			) : status === "delete" ? (
		// 				<div className="badge badge-soft-danger font-size-12">
		// 					Delete
		// 				</div>
		// 			) : (
		// 				<div className="badge  font-size-12">{status}</div>
		// 			);
		// 		},
		// 	},
		// },

		{
			name: "flag",
			label: "Status",
			options: {
				customFilterListOptions: {
					render: (v) =>
						v.map((l) => l.charAt(0).toUpperCase() + l.slice(1)),
				},
				filterType: "custom",
				filterOptions: {
					names: [],
					display: (filterList, onChange, index, column) => {
						const optionValues = [
							{ name: "Active", value: "active" },
							{ name: "Pending", value: "pending" },
							{ name: "Disable", value: "disable" },
							{ name: "Delete", value: "delete" },
						];
						return (
							<div className="auto">
								{/* <label
									htmlFor="currency-transaction"
									className="form-label font-size-13 text-muted"
								></label> */}
								<select
									value={filterList[index][0] || ""}
									onChange={(event) => {
										filterList[index][0] =
											event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
									className="form-control"
									name="currency-transaction"
								>
									<option disabled value="">
										Status
									</option>
									{optionValues.map((item) => (
										<option
											key={item.value}
											value={item.value}
										>
											{item.name}
										</option>
									))}
								</select>
							</div>
						);
					},
				},
				sort: false,
				customBodyRender: (status) => {
					return status === "disable" ? (
						<div className="badge badge-soft-default font-size-12">
							Disable
						</div>
					) : status === "pending" ? (
						<div className="badge badge-soft-warning font-size-12">
							Pending
						</div>
					) : status === "active" ? (
						<div className="badge badge-soft-success font-size-12">
							Active
						</div>
					) : status === "confirm" ? (
						<div className="badge badge-soft-primary font-size-12">
							Approve in progress
						</div>
					) : status === "delete" ? (
						<div className="badge badge-soft-danger font-size-12">
							Delete
						</div>
					) : (
						<div className="badge  font-size-12">{status}</div>
					);
				},
			},
		},

		{
			label: "Date",
			name: "createdAt",
			options: {
				customBodyRender: (date) => {
					return moment(date).format("LLL");
				},
				filterType: "custom",
				customFilterListOptions: {
					render: (v) => {
						if (v?.[0])
							return `From Date : ${moment(v[0]).format(
								"MM/DD/YYYY"
							)} - To Date : ${moment(v[1]).format(
								"MM/DD/YYYY"
							)}`;
						return [];
					},
					update: (filterList, filterPos, index) => {
						filterList[index] = [];
						return filterList;
					},
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<div className="auto">
								{/* <label
									htmlFor="currency-transaction"
									className="form-label font-size-13 text-muted"
								></label> */}
								<div className="input-group">
									<Flatpickr
										className="form-control d-block"
										placeholder="Date Range"
										options={{
											mode: "range",
											dateFormat: "m/d/Y",
										}}
										value={
											filterList[index] || [
												new Date(),
												new Date(),
											]
										}
										onChange={(date) => {
											filterList[index] = date;
											onChange(
												filterList[index],
												index,
												column
											);
										}}
									/>
								</div>
							</div>
						);
					},
				},
				sort: true,
			},
		},

		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: hasPermission(
					["partner update", "partner delete", "partner view"],
					auth.currentUserRolePermissions
				),
				viewColumns: hasPermission(
					["partner update", "partner delete", "partner view"],
					auth.currentUserRolePermissions
				),
				customBodyRender: (data) => {
					return (
						<div className="text-center">
							{data.flag === "pending" ? (
								<>
									<HasAnyPermission
										permission={[
											"partner update",
											"partner view",
										]}
									>
										<button
											onClick={(e) =>
												handleApproveDetails(data)
											}
											type="button"
											className="btn btn-soft-primary waves-effect waves-light m-1"
										>
											Approve
										</button>
									</HasAnyPermission>

									<HasAnyPermission
										permission={[
											"partner update",
											"partner view",
										]}
									>
										<button
											onClick={(e) => {
												e.preventDefault();
												handleCancelUserLending(data);
											}}
											type="button"
											className="btn btn-soft-danger waves-effect waves-light m-1"
										>
											Cancel
										</button>
									</HasAnyPermission>
								</>
							) : (
								<button
									type="button"
									disabled
									className={`btn btn-${
										data.flag === "active"
											? "success"
											: data.flag === "delete"
											? "danger"
											: data.flag === "confirm"
											? "primary"
											: "default"
									} waves-effect waves-light m-1`}
								>
									{data.flag === "disable"
										? "Disable"
										: data.flag === "active"
										? "Approved"
										: data.flag === "delete"
										? "Cancelled"
										: data.flag === "confirm"
										? "Confirm"
										: data.flag}
								</button>
							)}
						</div>
					);
				},
			},
		},
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				name: item?.user?.name,
				identifier: item?.user?.identifier,
				lendingPoolName: item?.lendingPool?.name,
				email: item?.user?.email,
				volume: item?.volume ? item.volume.toFixed(2) : "-",
				amount: item?.amount ? item.amount.toFixed(2) : "-",
				action: item,
			};
		});
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>User Stake | LFI</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="User Lending" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"partner list",
														]}
													>
														<ReactDataTable
															url={`${apiUrl}/admin/lending/lend/pagination`}
															columns={columns()}
															resultFormatter={
																resultFormatter
															}
															setRefresh={
																refreshTableData
															}
															disableFilterIcon={
																false
															}
															disableSearchIcon={
																false
															}
															origin={
																<div className="row">
																	<div className="col-auto h4">
																		User
																		Lending
																		Pool
																		&nbsp;
																		<HasAnyPermission
																			permission={[
																				"partner add",
																			]}
																		>
																			{/* <button
																				onClick={() => {
																					handleAddEditModal();
																				}}
																				type="button"
																				className="btn btn-primary waves-effect waves-light"
																			>
																				<i className="bx bx-plus-medical font-size-16 align-middle"></i>
																			</button> */}
																		</HasAnyPermission>
																	</div>
																</div>
															}
															rowsPerPage={10}
														/>
													</HasAnyPermission>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>

					<UserLendingApproveModal
						approveDetails={approveDetails}
						handleApproveDetails={handleApproveDetails}
						lendingPools={lendingPools}
						validation={approveValidation}
						getLendingPoolOptions={getLendingPoolOptions}
						isLoading={isLoading}
					></UserLendingApproveModal>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default UserLending;
