import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_SOFTWARE_PACKAGE_REQUEST,
	CREATE_SOFTWARE_PACKAGE_SUCCESS,
	CREATE_SOFTWARE_PACKAGE_FAILURE,
	UPDATE_SOFTWARE_PACKAGE_SUCCESS,
	UPDATE_SOFTWARE_PACKAGE_REQUEST,
	UPDATE_SOFTWARE_PACKAGE_FAILURE,
	REMOVE_SOFTWARE_PACKAGE_FAILURE,
	REMOVE_SOFTWARE_PACKAGE_SUCCESS,
	REMOVE_SOFTWARE_PACKAGE_REQUEST,
	TOGGLE_SOFTWARE_PACKAGE_SUCCESS,
	TOGGLE_SOFTWARE_PACKAGE_REQUEST,
	TOGGLE_SOFTWARE_PACKAGE_FAILURE,
} from "./actionTypes";

import { create, update, remove, toggle } from "./services";

function* createSoftwarePackage({ payload }) {
	try {
		const response = yield call(create, payload);
		if (response.status === 200) {
			yield put({
				type: CREATE_SOFTWARE_PACKAGE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_SOFTWARE_PACKAGE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_SOFTWARE_PACKAGE_FAILURE,
			payload: response.data,
		});
	}
}

function* updateSoftwarePackage({ payload }) {
	try {
		const response = yield call(update, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_SOFTWARE_PACKAGE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_SOFTWARE_PACKAGE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_SOFTWARE_PACKAGE_FAILURE,
			payload: {},
		});
	}
}

function* toggleSoftwarePackage({ payload }) {
	try {
		const response = yield call(toggle, payload);
		if (response.status === 200) {
			yield put({
				type: TOGGLE_SOFTWARE_PACKAGE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: TOGGLE_SOFTWARE_PACKAGE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: TOGGLE_SOFTWARE_PACKAGE_FAILURE,
			payload: response.data,
		});
	}
}

function* removeSoftwarePackage({ payload }) {
	try {
		const response = yield call(remove, payload);
		if (response.status === 200) {
			yield put({
				type: REMOVE_SOFTWARE_PACKAGE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_SOFTWARE_PACKAGE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_SOFTWARE_PACKAGE_FAILURE,
			payload: response.data,
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_SOFTWARE_PACKAGE_REQUEST, createSoftwarePackage);
	yield takeEvery(UPDATE_SOFTWARE_PACKAGE_REQUEST, updateSoftwarePackage);
	yield takeEvery(REMOVE_SOFTWARE_PACKAGE_REQUEST, removeSoftwarePackage);
	yield takeEvery(TOGGLE_SOFTWARE_PACKAGE_REQUEST, toggleSoftwarePackage);
}

export default saga;
