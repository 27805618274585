export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const UPDATE_META_REQUEST = "UPDATE_META_REQUEST";
export const UPDATE_META_SUCCESS = "UPDATE_META_SUCCESS";
export const UPDATE_META_FAILURE = "UPDATE_META_FAILURE";

export const GET_META_REQUEST = "GET_META_REQUEST";
export const GET_META_SUCCESS = "GET_META_SUCCESS";
export const GET_META_FAILURE = "GET_META_FAILURE";
