import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	UPDATE_META_REQUEST,
	UPDATE_META_SUCCESS,
	UPDATE_META_FAILURE,
	GET_META_REQUEST,
	GET_META_SUCCESS,
	GET_META_FAILURE,
} from "./actionTypes";

import { update, getMetaService } from "./services";

function* updateMeta({ payload }) {
	try {
		const response = yield call(update, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_META_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_META_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_META_FAILURE,
			payload: response.data,
		});
	}
}

function* getMeta() {
	try {
		const response = yield call(getMetaService);
		if (response.status === 200) {
			yield put({
				type: GET_META_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_META_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_META_FAILURE,
			payload: response.data,
		});
	}
}

function* saga() {
	yield takeEvery(UPDATE_META_REQUEST, updateMeta);
	yield takeEvery(GET_META_REQUEST, getMeta);
}

export default saga;
