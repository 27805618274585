import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
	Label,
	Input,
	Spinner,
	Table,
	CardText,
	CardTitle,
	Collapse,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";

//redux
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { apiUrl } from "../../config";
import {
	getContractNFT,
	getNodes,
	clearResponse,
} from "../../store/user/actions";
import MUIDataTable from "mui-datatables";
import { CircularProgress } from "@mui/material";
import classnames from "classnames";

const NFTDetails = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { response, contractNFTs, isLoading, nodeDetails } = useSelector(
		(state) => ({
			response: state.user.response,
			contractNFTs: state.user.contractNFTs,
			nodeDetails: state.user.nodeDetails,
			isLoading: state.user.isLoading,
		})
	);
	const [activeAccordion, toggleAccordion] = useState(0);
	const contractId = props.match.params?.contractId;
	const walletId = props.match.params?.walletId;
	const noMatch = isLoading ? (
		<CircularProgress size={30} style={{ color: "#3f51b5" }} />
	) : (
		"Sorry, no matching records found."
	);

	useEffect(() => {
		dispatch(getContractNFT({ contractId, walletId }));
		dispatch(getNodes({ contractID: contractId }));
	}, [contractId, walletId]);

	useEffect(() => {
		if (response && response.code === "200") {
			// refreshTableData.current();
			toast.success(response.msg, {
				onOpen: () => {},
			});
		} else if (response && response?.msg) {
			// refreshTableData.current();
			toast.error(response.msg, {
				onOpen: () => {},
			});
		}
	}, [dispatch, response]);

	const handleTooltipOpen = () => {
		toast.success("Copied to clipboard.");
	};

	const nftColumns = () => [
		{
			label: "Contract No",
			name: "contractNo",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "nftNo",
			name: "nftNo",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "File Uri",
			name: "fileUri",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (fileUri) => {
					return (
						<a href={fileUri} target="_blank">
							{fileUri}
						</a>
					);
				},
			},
		},
		{
			label: "Transaction Hash",
			name: "txhash",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Is Mint",
			name: "minted",
			options: {
				// filter: true,
				sort: false,
				customBodyRender: (minted) => {
					return minted ? (
						<div className="badge badge-soft-primary  font-size-12">
							YES
						</div>
					) : (
						<div className="badge badge-soft-danger  font-size-12">
							NO
						</div>
					);
				},
				// filterType: "custom",
				// customFilterListOptions: {
				// 	render: (v) => {
				// 		const [selectedValue] = v;
				// 		if (selectedValue)
				// 			return selectedValue === "true"
				// 				? "Assignable"
				// 				: "Not Assignable";
				// 		return null;
				// 	},
				// 	update: (filterList, filterPos, index) => {
				// 		filterList[index] = [];
				// 		return filterList;
				// 	},
				// },
				// filterOptions: {
				// 	names: [],
				// 	display: (filterList, onChange, index, column) => {
				// 		const optionValues = ["Assignable", "Not Assignable"];
				// 		return (
				// 			<div className="auto">
				// 				<label
				// 					htmlFor="user-status"
				// 					className="form-label font-size-13 text-muted"
				// 				>
				// 					{" "}
				// 					Status
				// 				</label>
				// 				<select
				// 					value={filterList[index][0] || ""}
				// 					onChange={(event) => {
				// 						filterList[index][0] =
				// 							event.target.value;
				// 						onChange(
				// 							filterList[index],
				// 							index,
				// 							column
				// 						);
				// 					}}
				// 					className="form-control"
				// 					name="user-status"
				// 				>
				// 					<option disabled value="">
				// 						Status
				// 					</option>
				// 					{optionValues.map((item) => (
				// 						<option
				// 							key={item}
				// 							value={
				// 								item === "Assignable"
				// 									? true
				// 									: false
				// 							}
				// 						>
				// 							{item}
				// 						</option>
				// 					))}
				// 				</select>
				// 			</div>
				// 		);
				// 	},
				// },
			},
		},
		{
			label: "Created At",
			name: "created_at",
			options: {
				filter: false,
				sort: false,
				download: false,
				customBodyRender: (createdAt) =>
					moment(createdAt).format("lll"),
			},
		},
		{
			name: "action",
			label: "action",
			options: {
				filter: false,
				display: "excluded",
				viewColumns: false,
				download: false,
			},
		},
	];

	const columns = () => [
		{
			label: "CONTAINER",
			name: "no",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (no) => {
					return `Node#${no}`;
				},
			},
		},
		{
			label: "SERIAL",
			name: "uuid",
			options: {
				filter: false,
				sort: false,
			},
		},
		// {
		// 	label: "File Uri",
		// 	name: "fileUri",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 		customBodyRender: (fileUri) => {
		// 			return (
		// 				<a href={fileUri} target="_blank">
		// 					{fileUri}
		// 				</a>
		// 			);
		// 		},
		// 	},
		// },
		{
			label: "HASH RATE MIN",
			name: "hashRateMin",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "active",
			label: "STATUS",
			options: {
				filter: true,
				sort: false,
				download: false,
				customBodyRender: (status) => {
					return status === 1 ? (
						<div className="badge badge-soft-primary font-size-12">
							Active
						</div>
					) : (
						<div className="badge badge-soft-danger font-size-12">
							Inactive
						</div>
					);
				},
			},
		},

		{
			name: "action",
			label: "action",
			options: {
				filter: false,
				display: "excluded",
				viewColumns: false,
				download: false,
			},
		},
	];

	const resultFormatter = (result) => {
		return result.map((item) => {
			return {
				...item,
				action: item,
			};
		});
	};

	const expandableComponent = (rowData, rowMeta) => {
		const containerDetails = rowData[rowData.length - 1];
		return (
			<tr>
				<td colSpan={6}>
					<Card className="m-2">
						<CardHeader>
							<h4 className="card-title">NFT Details</h4>
						</CardHeader>
						<CardBody>
							<div className="table-rep-plugin">
								<div className="table-responsive">
									<MUIDataTable
										title={"NFT Details"}
										data={containerDetails?.nfts ?? []}
										columns={nftColumns()}
										options={{
											filter: false,
											print: false,
											download: true,
											responsive: "standard",
											viewColumns: false,
											selectableRows: "none",
											textLabels: {
												body: {
													noMatch: noMatch,
												},
											},
											expandableRowsOnClick: false,
											setTableProps: () => ({
												className:
													"mb-0 table-bordered responsiveTable",
												// className: "align-middle table table-bordered table-bordered mb-0",
											}),
										}}
									/>
								</div>
							</div>
						</CardBody>
					</Card>
				</td>
			</tr>
		);
	};

	const toggleActiveAccordion = (accordion) => {
		toggleAccordion(accordion === activeAccordion ? null : accordion);
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					{/* Render Breadcrumb */}
					<Breadcrumbs
						title="NFT details"
						breadcrumbItem="Contaract"
					/>

					<div className="container-fluid">
						<Row>
							<Col xl={12} lg={12}>
								<Card>
									<CardHeader>
										<CardTitle className="h4">
											Contract and machine and NFT details
										</CardTitle>
										<p className="card-title-desc"></p>
									</CardHeader>
									<CardBody>
										<div
											className="accordion accordion-flush"
											id="accordionFlushExample"
										>
											{" "}
											{nodeDetails?.[0]?.machines?.map(
												(machine, index) => (
													<div
														key={machine?._id}
														className="accordion-item"
													>
														<h2
															className="accordion-header"
															id="headingFlushOne"
														>
															<button
																className={classnames(
																	"accordion-button",
																	"fw-medium",
																	{
																		collapsed:
																			activeAccordion ===
																				index ??
																			false,
																	}
																)}
																type="button"
																onClick={() =>
																	toggleActiveAccordion(
																		index
																	)
																}
																style={{
																	cursor: "pointer",
																}}
															>
																Machine{" "}
																{`#${machine.id}`}
															</button>
														</h2>

														<Collapse
															isOpen={
																activeAccordion ===
																	index ??
																false
															}
															className="accordion-collapse"
														>
															<div className="accordion-body">
																<Row className="mt-1">
																	<Col
																		xl={3}
																		sm={6}
																	>
																		<Card>
																			<CardBody className="overflow-hidden position-relative">
																				<h5 className="mt-3">
																					IP
																					Address
																					(IPv6)
																				</h5>
																				<div className="faq-count">
																					<h5 className="text-primary">
																						{`${machine?.ip}`}
																					</h5>
																				</div>
																			</CardBody>
																		</Card>
																	</Col>
																	<Col
																		xl={3}
																		sm={6}
																	>
																		<Card>
																			<CardBody className="overflow-hidden position-relative">
																				<h5 className="mt-3">
																					MAC
																					Address
																				</h5>
																				<div className="faq-count">
																					<h5 className="text-primary">
																						{`${machine?.mac}`}
																					</h5>
																				</div>
																			</CardBody>
																		</Card>
																	</Col>
																	<Col
																		xl={3}
																		sm={6}
																	>
																		<Card>
																			<CardBody className="overflow-hidden position-relative">
																				<h5 className="mt-3">
																					Status
																				</h5>
																				<div className="faq-count">
																					<h5
																						style={{
																							textTransform:
																								"capitalize",
																						}}
																						className="text-primary"
																					>
																						{`${machine.status}`}
																					</h5>
																				</div>
																			</CardBody>
																		</Card>
																	</Col>
																</Row>

																<Row>
																	<Col xl="12">
																		<div className="table-rep-plugin">
																			<div className="table-responsive">
																				<MUIDataTable
																					title={
																						"Containers"
																					}
																					data={resultFormatter(
																						machine?.userContainers ??
																							[]
																					)}
																					columns={columns()}
																					options={{
																						filter: false,
																						print: false,
																						download: true,
																						responsive:
																							"standard",
																						viewColumns: false,
																						selectableRows:
																							"none",
																						textLabels:
																							{
																								body: {
																									noMatch:
																										noMatch,
																								},
																							},
																						expandableRowsOnClick: false,
																						setTableProps:
																							() => ({
																								className:
																									"mb-0 table-bordered responsiveTable",
																								// className: "align-middle table table-bordered table-bordered mb-0",
																							}),
																						expandableRows: true,
																						expandableRowsHeader: false,
																						expandableRowsOnClick: true,
																						renderExpandableRow:
																							expandableComponent,
																						rowsExpanded:
																							[
																								0,
																								1,
																							],
																					}}
																				/>
																			</div>
																		</div>
																	</Col>
																</Row>
															</div>
														</Collapse>
													</div>
												)
											)}
										</div>
									</CardBody>
								</Card>

								{/* <Card>
									<CardBody>
										<Row>
											<div className="col-sm order-2 order-sm-1">
												<div className="d-flex align-items-start mt-3 mt-sm-0">
													<div className="flex-shrink-0"></div>
													<div className="flex-grow-1">
														<div>
															<h5 className="font-size-16 mb-1">
																Contract No:
																{"  "}
																{`${contractNFTs.contractNo}`}
															</h5>

															<h5 className="font-size-16 mb-1">
																Purchase Date:
																{"  "}
																{`${
																	contractNFTs.purchaseDate
																		? moment(
																				contractNFTs.purchaseDate
																		  ).format(
																				"lll"
																		  )
																		: "-"
																}`}
															</h5>

															<h5 className="font-size-16 mb-1">
																Amount:{"  "}
																{`${contractNFTs.amount}`}
															</h5>
															<h5 className="font-size-16 mb-1">
																Payment Days:
																{"  "}
																{`${contractNFTs.paymentDays}`}
															</h5>
														</div>
													</div>
													<div className="flex-grow-1"></div>
												</div>
											</div>
										</Row>
									</CardBody>
								</Card> */}
							</Col>
						</Row>
					</div>
				</Container>
			</div>
		</React.Fragment>
	);
};
export default NFTDetails;
