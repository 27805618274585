import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_STAKING_POOL_SUCCESS,
	CREATE_STAKING_POOL_FAILURE,
	UPDATE_STAKING_POOL_SUCCESS,
	UPDATE_STAKING_POOL_FAILURE,
	REMOVE_STAKING_POOL_FAILURE,
	REMOVE_STAKING_POOL_SUCCESS,
	TOGGLE_STAKING_POOL_SUCCESS,
	TOGGLE_STAKING_POOL_FAILURE,
	APPROVE_STAKING_POOL_SUCCESS,
	APPROVE_STAKING_POOL_FAILURE,
	GET_ALL_STAKING_POOL_SUCCESS,
	GET_ALL_STAKING_POOL_REQUEST,
	CANCEL_USER_STAKING_POOL_REQUEST,
	GET_ALL_STAKING_POOL_FAILURE,
	CANCEL_USER_STAKING_POOL_SUCCESS,
	CANCEL_USER_STAKING_POOL_FAILURE,
	APPROVE_STAKING_POOL_REQUEST,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	isLoading: false,
	lendingPools: [],
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				isLoading: false,
			};
		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};
		case APPROVE_STAKING_POOL_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case CREATE_STAKING_POOL_SUCCESS:
		case CREATE_STAKING_POOL_FAILURE:
		case UPDATE_STAKING_POOL_SUCCESS:
		case UPDATE_STAKING_POOL_FAILURE:
		case REMOVE_STAKING_POOL_FAILURE:
		case REMOVE_STAKING_POOL_SUCCESS:
		case APPROVE_STAKING_POOL_SUCCESS:
		case APPROVE_STAKING_POOL_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: {},
				isLoading: false,
			};

		case GET_ALL_STAKING_POOL_SUCCESS:
			return {
				...state,
				stakingPools: action.payload?.data,
				isLoading: false,
			};
		case GET_ALL_STAKING_POOL_FAILURE:
			return {
				...state,
				stakingPools: [],
				errors: action.payload,
				isLoading: false,
			};

		case CANCEL_USER_STAKING_POOL_SUCCESS:
			return {
				...state,
				errors: {},
				response: action.payload,
				isLoading: false,
			};
		case CANCEL_USER_STAKING_POOL_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: action.payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default reducer;
