import axios from "axios";
import { apiUrl } from "../../config";

export const create = (data) =>
	axios
		.post(`${apiUrl}/admin/cloud-usage/create`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const getCloudUsage = () =>
	axios
		.get(`${apiUrl}/admin/cloud-usage`)
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data) =>
	axios
		.put(`${apiUrl}/admin/cloud-usage/update/${data._id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const toggle = (data) =>
	axios
		.patch(`${apiUrl}/admin/cloud-usage/toggle-status/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const remove = (data) =>
	axios
		.delete(`${apiUrl}/admin/cloud-usage/delete/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);
