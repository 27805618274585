import axios from "axios";
import { apiUrl } from "../../config";

export const create = (data) =>
	axios
		.post(`${apiUrl}/admin/chain-setting/create`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const getChainList = () =>
	axios
		.get(`${apiUrl}/admin/chain-setting`)
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data) =>
	axios
		.post(`${apiUrl}/admin/chain-setting/update`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const toggle = (data) =>
	axios
		.patch(`${apiUrl}/admin/chain-setting/toggle-status/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const remove = (data) =>
	axios
		.delete(`${apiUrl}/admin/chain-setting/delete/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);
