export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_LENDING_POOL_REQUEST = "CREATE_LENDING_POOL_REQUEST";
export const CREATE_LENDING_POOL_SUCCESS = "CREATE_LENDING_POOL_SUCCESS";
export const CREATE_LENDING_POOL_FAILURE = "CREATE_LENDING_POOL_FAILURE";
export const UPDATE_LENDING_POOL_REQUEST = "UPDATE_LENDING_POOL_REQUEST";
export const UPDATE_LENDING_POOL_SUCCESS = "UPDATE_LENDING_POOL_SUCCESS";
export const UPDATE_LENDING_POOL_FAILURE = "UPDATE_LENDING_POOL_FAILURE";
export const REMOVE_LENDING_POOL_REQUEST = "REMOVE_LENDING_POOL_REQUEST";
export const REMOVE_LENDING_POOL_SUCCESS = "REMOVE_LENDING_POOL_SUCCESS";
export const REMOVE_LENDING_POOL_FAILURE = "REMOVE_LENDING_POOL_FAILURE";

export const APPROVE_LENDING_POOL_SUCCESS = "APPROVE_LENDING_POOL_SUCCESS";
export const APPROVE_LENDING_POOL_REQUEST = "APPROVE_LENDING_POOL_REQUEST";
export const APPROVE_LENDING_POOL_FAILURE = "APPROVE_LENDING_POOL_FAILURE";

export const GET_ALL_LENDING_POOL_SUCCESS = "GET_ALL_LENDING_POOL_SUCCESS";
export const GET_ALL_LENDING_POOL_REQUEST = "GET_ALL_LENDING_POOL_REQUEST";
export const GET_ALL_LENDING_POOL_FAILURE = "GET_ALL_LENDING_POOL_FAILURE";

export const CANCEL_USER_LENDING_POOL_SUCCESS =
	"CANCEL_USER_LENDING_POOL_SUCCESS";
export const CANCEL_USER_LENDING_POOL_REQUEST =
	"CANCEL_USER_LENDING_POOL_REQUEST";
export const CANCEL_USER_LENDING_POOL_FAILURE =
	"CANCEL_USER_LENDING_POOL_FAILURE";

export const GET_LENDING_SETTING_SUCCESS = "GET_LENDING_SETTING_SUCCESS";
export const GET_LENDING_SETTING_REQUEST = "GET_LENDING_SETTING_REQUEST";
export const GET_LENDING_SETTING_FAILURE = "GET_LENDING_SETTING_FAILURE";
export const UPDATE_LENDING_SETTING_SUCCESS = "UPDATE_LENDING_SETTING_SUCCESS";
export const UPDATE_LENDING_SETTING_REQUEST = "UPDATE_LENDING_SETTING_REQUEST";
export const UPDATE_LENDING_SETTING_FAILURE = "UPDATE_LENDING_SETTING_FAILURE";
