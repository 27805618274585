import axios from "axios";
import { apiUrl } from "../../config";

export const getMetaService = (data) =>
	axios
		.get(`${apiUrl}/admin/meta/details`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data) =>
	axios
		.post(`${apiUrl}/admin/meta/update`, data)
		.then((response) => response)
		.catch((err) => err.response);
